<template>
	<div class="campaign">
		<div class="head">
			<BackButton :to="{ name: 'campaigns' }">Back to Campaigns</BackButton>

			<slot name="notifications" />
		</div>

		<template v-if="preload">
			<div class="card campaign__head-card blinking-item">
				<CampItemSkeleton :sorting-bar="false" />
				<div class="campaign__values">
					<span
						v-for="i in statValues.length"
						:key="i"
						class="campaign-value campaign-preload-value blinking-item"
					/>
				</div>
			</div>
			<div class="card blinking-item">
				<div class="campaign-preload-nav groups-tabs groups-tabs--card">
					<span
						v-for="i in tabsList"
						:key="i.routeName"
						class="blinking-item"
					/>
				</div>
				<Spinner class="blinking-item" />
			</div>
		</template>

		<template v-else-if="campaign">
			<div class="card campaign__head-card">
				<CampItem
					:campaign="campaign"
					:statistics-loading="statisticsLoading"
					@campaignRemoved="goToCampaigns"
				/>

				<section
					v-if="!isDraft"
					class="campaign__values"
					aria-label="Campaign's leads digits"
				>
					<CampaignValue
						v-for="statItem in statValues"
						:key="statItem.icon"
						:tooltip="statItem.tooltip"
						:stat="statItem"
						:statistics-loading="statisticsLoading"
						:campaign="campaign"
					/>
				</section>
			</div>

			<div class="card campaign__pages">
				<nav aria-label="Campaign navigation">
					<ul class="groups-tabs groups-tabs--card">
						<li
							v-for="tab in tabsList"
							:key="tab.routeName"
						>
							<router-link
								v-tooltip="tab.highlight ? tab.draftTooltip : null"
								:to="routeLink(tab.routeName)"
								class="groups-tabs__button"
								active-class="groups-tabs__button--selected"
								:class="{ 'groups-tabs__button--highlight': tab.highlight }"
								:exact="tab.routeName === 'campaign'"
							>
								{{ tab.name }}
							</router-link>
						</li>
					</ul>
				</nav>
				<transition
					name="fade"
					mode="out-in"
				>
					<router-view
						:campaign="campaign"
						@campaignUpdated="freshUpCampaign"
					/>
				</transition>
			</div>
		</template>
		<NotFound v-else />
	</div>
</template>

<script>
import CampItemSkeleton from "@/js/components/Campaign/CampItemSkeleton";
import CampItem from "@/js/components/Campaign/CampItem";
import CampaignValue from "@/js/components/Campaign/CampaignValue";
import getCampaignsStatistics from "@/js/mixins/getCampaignsStatistics.mixin";
import sequence from "@/js/mixins/sequence.mixin";
import eventBus from "@/event-bus";

export default {
	name: "Campaign",
	components: {
		CampItem,
		CampaignValue,
		CampItemSkeleton,
		NotFound: () => window.compLoader(import("@/js/views/404Component"))
	},
	metaInfo() {
		return { title: this.campaign ? `${this.campaign.name}` : "Campaign" };
	},
	mixins: [getCampaignsStatistics, sequence],
	provide() {
		return {
			campaignInfo: this.campaignInfoToProvide
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.getCampaignsStatistics([this.campaign], undefined, false);
		this.checkForSequenceIsSaved(to, from, next);
	},
	beforeRouteLeave(to, from, next) {
		this.clearCampaign();
		this.checkForSequenceIsSaved(to, from, next);
	},
	data: () => ({
		campaign: null,
		campaignInfoToProvide: {},
		sequence: null,
		preload: true,
		tabsList: [
			{
				name: "Audience",
				routeName: "campaign",
				draftTooltip: "Add some leads right here to get this campaign rolling",
				highlight: false
			},
			{
				name: "Sequence",
				routeName: "campaign-sequence",
				draftTooltip: "Almost there! Complete this sequence to activate your campaign",
				highlight: false
			},
			{
				name: "Statistics",
				routeName: "campaign-statistics",
				highlight: false
			},
			{
				name: "Settings",
				routeName: "campaign-settings",
				highlight: false
			}
		],
		leavingSequencePage: false
	}),
	computed: {
		campaignID() {
			return +this.$route.params.campaignId;
		},
		stat() {
			return this.campaign && this.campaign.statistics;
		},
		isDraft() {
			return this.campaign.status === "DRAFT";
		},
		statValues() {
			const campLeadsUrl = `/campaigns/${this.campaignID}/leads`;
			return [
				{
					icon: "all-leads",
					value: this.stat ? this.stat.totalLeads : 0,
					text: "All leads",
					url: campLeadsUrl,
					tooltip: "Total leads that are already uploaded in the sequence"
				},
				{
					icon: "loading-arrow",
					value: this.stat ? this.stat.inProgressLeads : 0,
					text: "In progress",
					url:
						campLeadsUrl +
						"?leadStatuses=READY_FOR_PROCESSING&blacklisted=false&excludeTypes=WAITING&excludeTypes=LEFT&pageName=In+progress",
					tooltip: "Leads that are currently going through the sequence in the campaign"
				},
				{
					icon: "doublecheck",
					value: this.stat ? this.stat.finishedLeads : 0,
					text: "Finished",
					url: campLeadsUrl + "?includeTypes=FINISHED&pageName=Completed",
					tooltip: "Leads that completed all steps in the sequence"
				},
				{
					icon: "loading",
					value: this.stat ? this.stat.waitingLeads : 0,
					text: "Awaiting",
					url: campLeadsUrl + "?includeTypes=WAITING&pageName=Awaiting",
					tooltip:
						"Leads that are waiting in the sequence until the connection request is accepted / InMail replied"
				},
				{
					icon: "pause",
					value: this.stat ? this.stat.pausedLeads : 0,
					text: "Paused",
					url: campLeadsUrl + "?includeTypes=PAUSED&pageName=Paused",
					tooltip:
						"Leads that replied to your Linkedin message, connection request or InMail are now paused in the sequence"
				},
				{
					icon: "big-cross",
					value: this.stat ? this.stat.failedLeads : 0,
					text: "Failed",
					url: campLeadsUrl + "?includeTypes=FAILED&blacklisted=false&pageName=Failed",
					tooltip: "Leads that have left the sequence due to a failed attempt to perform an action"
				},
				{
					icon: "sequence-icon-blacklisted",
					value: this.stat ? this.stat.blacklistedLeads : 0,
					text: "Blacklisted",
					url: campLeadsUrl + "?includeTypes=BLACKLISTED&pageName=Blacklisted",
					tooltip: "Leads that have been manually blacklisted"
				}
			];
		}
	},
	watch: {
		campaignID() {
			this.getCampaign();
		}
	},
	async created() {
		eventBus.$on("campaignUpdated", this.getCampaign);
		eventBus.$on("sequencePopupUpdated", (val = true) => {
			this.sequencePopupUpdated = val;
		});
		await this.getCampaign();
	},
	beforeDestroy() {
		this.clearCampaign();
	},
	methods: {
		checkForSequenceIsSaved(to, from, next) {
			if (from.name === "campaign-sequence" && this.sequencePopupUpdated) {
				eventBus.$emit("leavingSequencePage", to);
				next(false);
			} else {
				next();
			}
		},
		routeLink(name) {
			return {
				name: name,
				params: {
					campaignId: this.campaign.id
				}
			};
		},
		async freshUpCampaign(campaign) {
			if (campaign) this.campaign = campaign;
			await this.getCampaignsStatistics([this.campaign]);
			this.checkCampaignIsDraft();

			if (this.campaign.zapierError && this.campaign.zapierUrl) {
				this.tabsList.find((tab) => tab.routeName === "campaign-settings").highlight = true;
			}
		},
		async getCampaign() {
			try {
				this.preload = true;
				const res = await this.$store.dispatch("getCampaignById", this.campaignID);
				await this.freshUpCampaign(res);
				Object.assign(this.campaignInfoToProvide, res)
			} finally {
				this.preload = false;
			}
		},
		goToCampaigns() {
			this.$router.push("/campaigns");
		},
		clearCampaign() {
			eventBus.$off("campaignUpdated");
		},
		checkCampaignIsDraft() {
			this.tabsList.forEach((tab) => (tab.highlight = false));

			if (this.isDraft) {
				const haveLeads = this.campaign.statistics.leadsListsSize;
				const { sequenceCompleted } = this.campaign;

				if (!haveLeads) this.tabsList.find((tab) => tab.routeName === "campaign").highlight = true;
				if (!sequenceCompleted)
					this.tabsList.find((tab) => tab.routeName === "campaign-sequence").highlight = true;

				if (haveLeads) {
					const redirectLink = this.routeLink(!sequenceCompleted ? "campaign-sequence" : "");
					this.$router.push(redirectLink);
				}
			}
		}
	}
};
</script>

<style lang="sass">
.campaign
	min-height: calc(100vh - 60px)
	--values-gap: 6px
	.camp-item
		margin: 0
	&__
		&head-card
			flex: none !important
		&values
			display: grid
			grid-template-columns: repeat(7, 1fr)
			gap: var(--values-gap)
			margin-top: var(--values-gap)
			@media screen and (max-width: $netbook)
				overflow: auto
				width: calc(100% + var(--card-offset-x) * 2)
				padding: 0 var(--card-offset-x)
				margin-left: calc(var(--card-offset-x) * -1)
				grid-template-columns: repeat(3, 1fr)
			@media screen and (max-width: $fablet)
				grid-template-columns: repeat(4, 150px)
		&pages
			display: flex
			flex-direction: column



.campaign-preload-value
	height: 104px
	display: flex
	flex-direction: column
	gap: 20px
	&::before
		content: ""
		display: block
		height: 23px
		width: 60%
		background-image: linear-gradient(to right, var(--neutral-100), transparent)
		border-radius: 20px
	&::after
		content: ""
		display: block
		height: 20px
		width: 60px
		border-radius: 20px
		background-color: var(--main-primary-light)
		opacity: 1

.campaign-preload-nav
	display: flex
	align-items: center
	gap: 30px
	padding: 13px
	span
		margin-top: 1px
		width: 54px
		height: 15px
		background-color: var(--neutral-200)
		border-radius: 20px
</style>
