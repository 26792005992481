document.addEventListener("click", (event) => {
	const clickedEl = event.target;
	const rippleClasses = [".btn:not(.btn--square)", ".js-ripple"];
	const neededEl = rippleClasses.some(
		(className) =>
			clickedEl.classList.contains(className.substring(1)) || clickedEl.closest(className)
	);
	const interactiveTags = ["A", "BUTTON"];
	const clickedInteractiveEl = interactiveTags.includes(clickedEl.tagName);

	if (neededEl) {
		const button = clickedInteractiveEl ? clickedEl : clickedEl.closest(rippleClasses.join(","));
		const TRANSITION = (() => {
			let time = getComputedStyle(button).getPropertyValue("--ripple-time");
			return time.substring(0, time.length - 1) * 1000;
		})();

		button.style.setProperty("--ripple-x", setPosition(clickedInteractiveEl, event.offsetX));
		button.style.setProperty("--ripple-y", setPosition(clickedInteractiveEl, event.offsetY));

		button.classList.add("rippled");

		setTimeout(() => {
			button.classList.remove("rippled");
		}, TRANSITION);
	}
});
function setPosition(clickedInteractiveEl, pos) {
	return clickedInteractiveEl && pos ? pos + "px" : "50%";
}
