<template>
	<section
		class="schedule"
	>
		<div class="schedule__head">
			<h2 class="h6">Choose your working hours:</h2>


			<p class="timezone">
				<span
					id="tz-label"
					class="nowrap body_S"
				>Time zone:</span>
				<span class="timezone__select">
					<AppSelect
						id="timezone"
						v-model="zone"
						aria-label="Select timezone"
						:options="timezonesSelectArr"
						:async-function="getTimezonesOptions"
						ph="UTC"
						aria-labelledby="tz-label"
						with-search
					/>
				</span>
			</p>
		</div>

		<Preloader v-if="preload" />

		<div class="schedule__wrap">
			<ul
				class="work-hours"
				aria-label="Work week"
				data-guiding-step="working-hours"
			>
				<li
					v-for="(day, idx) in schedulerItems"
					:key="idx"
					class="day-card"
					:aria-label="day.dayOfWeek"
				>
					<div class="day-card__head">
						<label
							class="caption_S posr"
							:for="'check-' + day.dayOfWeek"
						>
							{{ day.dayOfWeek }}
							<span
								v-if="dayIndexofToday(day.dayOfWeek)"
								class="day-card__today"
							>(today)</span>
						</label>

						<Trigger
							v-model="day.active"
							class="trigger--M"
							:check-name="'check-' + day.dayOfWeek"
						/>
					</div>

					<div v-if="day.active">
						<div class="day-card__timerange">
							<div>
								<div class="body_S neutral-400 day-card__from-to">From:</div>
								<Timepicker
									v-model="day.start"
									format="hh:mm a"
									apm-label="AM/PM"
									hour-label="Hours"
									:hour-range="['12a', ['1a', '10p']]"
									hide-disabled-hours
									hide-clear-button
									advanced-keyboard
									:align-right="idx > 3"
									aria-label="Work from"
									@change="startChangeHandler(day)"
								/>
							</div>
							<div>
								<div class="body_S neutral-400 day-card__from-to">To:</div>
								<Timepicker
									v-model="day.end"
									format="hh:mm a"
									apm-label="AM/PM"
									hour-label="Hours"
									:hour-range="endHourRange(day)"
									advanced-keyboard
									hide-clear-button
									:align-right="idx > 3"
									aria-label="Work to"
									@change="endChangeHandler(day)"
								/>
							</div>
						</div>

						<div class="day-card__to-all">
							<button
								class="tertiary-btn"
								@click="applyToAll(day)"
							>
								Apply to all
							</button>
						</div>
					</div>
					<p
						v-else
						class="tac body_L neutral-400"
					>
						<br>
						Don’t run
						<br>
						<br>
					</p>
				</li>
			</ul>
		</div>

		<div class="schedule__footer">
			<div class="aic">
				<template v-if="dataChanged && !preload">
					<button
						class="btn btn--auto-w"
						@click="cancelChanges"
					>
						Cancel
					</button>
					<AsyncButton
						:async-f="updateSchedule"
						class="btn btn--base btn--auto-w"
					>
						Save
					</AsyncButton>
				</template>
			</div>

			<button
				class="schedule-time-convert"
				aria-haspopup="true"
				@click="amPopup = true"
			>
				Time-converter
				<Icon icon="info" />
			</button>
		</div>

		<transition name="fade">
			<TimeConverTablePopup
				v-if="amPopup"
				@close="amPopup = false"
			/>
		</transition>

		<transition name="fade">
			<SettingsLeavingPopup
				v-if="leavingPopup"
				:saving-function="updateSchedule"
				:leaving-path="leavingPath"
				@close="closeLeavingPopup"
			/>
		</transition>
	</section>
</template>

<script>
import { WEEK_DAYS_NAMES_LIST } from "@/js/helpers/constants/constants";
import checkForSleepMode from "@/js/mixins/checkForSleepingMode.mixin";
import guidingNextStep from "@/js/mixins/guidingNextStep.mixin";
import Timepicker from "@/js/components/Form/Timepicker";
import Trigger from "@/js/components/Form/Trigger";
import TimeFormats from "@/js/helpers/constants/timeFormats";
import settingsLeavingHandler from "@/js/mixins/settingsLeavingHandler.mixin";

export default {
	name: "Scheduler",
	components: {
		Trigger,
		Timepicker,
		TimeConverTablePopup: () =>
			window.compLoader(import("@/js/components/Settings/TimeConverTablePopup"))
	},
	mixins: [checkForSleepMode, guidingNextStep, settingsLeavingHandler],
	data: () => ({
		preload: true,
		amPopup: false,
		zone: "",
		timeFormats: TimeFormats,
		schedulerItems: [
			{
				dayOfWeek: "MONDAY",
				start: {
					hh: "09",
					mm: "00",
					a: "pm"
				},
				end: {
					hh: "05",
					mm: "00",
					a: "pm"
				},
				active: true,
				id: null
			},
			{
				dayOfWeek: "TUESDAY",
				start: {
					hh: "09",
					mm: "00",
					a: "am"
				},
				end: {
					hh: "05",
					mm: "00",
					a: "pm"
				},
				active: true,
				id: null
			},
			{
				dayOfWeek: "WEDNESDAY",
				start: {
					hh: "09",
					mm: "00",
					a: "am"
				},
				end: {
					hh: "05",
					mm: "00",
					a: "pm"
				},
				active: true,
				id: null
			},
			{
				dayOfWeek: "THURSDAY",
				start: {
					hh: "01",
					mm: "00",
					a: "am"
				},
				end: {
					hh: "05",
					mm: "00",
					a: "pm"
				},
				active: true,
				id: null
			},
			{
				dayOfWeek: "FRIDAY",
				start: {
					hh: "03",
					mm: "00",
					a: "am"
				},
				end: {
					hh: "05",
					mm: "00",
					a: "pm"
				},
				active: true,
				id: null
			},
			{
				dayOfWeek: "SATURDAY",
				start: {
					hh: "09",
					mm: "00",
					a: "am"
				},
				end: {
					hh: "05",
					mm: "00",
					a: "pm"
				},
				active: false,
				id: null
			},
			{
				dayOfWeek: "SUNDAY",
				start: {
					hh: "09",
					mm: "00",
					a: "am"
				},
				end: {
					hh: "05",
					mm: "00",
					a: "pm"
				},
				active: false,
				id: null
			}
		],
		restrictedHourRange: [
			"12a",
			"1a",
			"2a",
			"3a",
			"4a",
			"5a",
			"6a",
			"7a",
			"8a",
			"9a",
			"10a",
			"11a",
			"12p",
			"1p",
			"2p",
			"3p",
			"4p",
			"5p",
			"6p",
			"7p",
			"8p",
			"9p",
			"10p",
			"11p"
		],
		comparer: [],
		zoneComparer: "",
		timezonesSelectArr: []
	}),
	computed: {
		dataChanged() {
			const scheduleChanged = JSON.stringify(this.schedulerItems) !== JSON.stringify(this.comparer);
			return scheduleChanged || this.zoneComparer !== this.zone;
		}
	},
	async mounted() {
		try {
			const schedule = await this.$store.dispatch("getSchedule");
			this.setNewData(schedule);
			setTimeout(this.guidingNextStep, 300);
		} finally {
			this.preload = false;
			this.dataLoaded = true;
		}
	},
	methods: {
		cancelChanges() {
			this.schedulerItems = this.comparer.map((item) => Object.assign({}, item));
			this.zone = this.zoneComparer;
		},
		async getTimezonesOptions() {
			const timezones = (await import("@/js/helpers/constants/timezonesList.json")).default;
			this.timezonesSelectArr = Object.keys(timezones).map(this.prettifySelectOption);
		},
		prettifySelectOption(name) {
			return {
				text: `(UTC ${this.$toHoursAndMinutes(this.$getOffset(name))}) ${name === "Europe/Kiev" ? "Europe/Kyiv" : name}`,
				value: name
			};
		},
		dayIndexofToday(dayName) {
			const todayIs = WEEK_DAYS_NAMES_LIST[this.$correctDate().getDay()];
			return dayName === todayIs;
		},
		setNewData(schedule) {
			if (schedule) {
				this.zone = schedule.zone || this.$getTimeZoneName();
				this.timezonesSelectArr = [this.prettifySelectOption(this.zone)];
				this.schedulerItems.forEach((day, index) => {
					day.start.hh = this.$convertTime24to12(schedule.schedulerItems[index].fromHour);
					day.start.a = schedule.schedulerItems[index].fromHour >= 12 ? "pm" : "am";
					day.end.hh = this.$convertTime24to12(schedule.schedulerItems[index].toHour);
					day.end.a = schedule.schedulerItems[index].toHour >= 12 ? "pm" : "am";
					day.active = schedule.schedulerItems[index].active;
					day.id = schedule.schedulerItems[index].id;
				});
				this.saveDataToCompare();
			}
		},
		saveDataToCompare() {
			this.comparer = this.schedulerItems.map((item) => Object.assign({}, item));
			this.zoneComparer = this.zone;
		},
		applyToAll({ start, end }) {
			this.schedulerItems.forEach((item) => {
				item.start = start;
				item.end = end;
			});
		},
		endHourRange(weekday) {
			const start = this.isZeroFirst(weekday.start.hh);
			const letter = this.dayOrNight(weekday.start.a);
			const time = start + letter;
			let index = this.restrictedHourRange.indexOf(time);
			return [[this.restrictedHourRange[(index += 1)], "11p"]];
		},
		startChangeHandler(weekday) {
			if (weekday.start.a === "pm" && weekday.start.hh === "11") {
				weekday.start.hh = "10";
				this.startChangeHandler(weekday);
			} else {
				this.updateDependenciesBetweenHours(weekday);
			}
		},
		endChangeHandler(weekday) {
			this.updateDependenciesBetweenHours(weekday);
		},
		updateDependenciesBetweenHours(weekday) {
			const startTime = this.isZeroFirst(weekday.start.hh) + this.dayOrNight(weekday.start.a);
			const endTime = this.isZeroFirst(weekday.end.hh) + this.dayOrNight(weekday.end.a);
			const startIndex = this.restrictedHourRange.indexOf(startTime);
			const endIndex = this.restrictedHourRange.indexOf(endTime);

			if (endIndex <= startIndex) {
				const str = this.restrictedHourRange[startIndex + 1];
				const endHour = str.substring(0, str.length - 1);
				weekday.end.hh = endHour < 10 ? `0${endHour}` : endHour;
				weekday.end.a = str.substring(str.length - 1, str.length) + "m";
			}
		},
		isZeroFirst(string) {
			return string[0] === "0" ? string.slice(1) : string;
		},
		dayOrNight(daytime) {
			return daytime === "am" ? "a" : "p";
		},
		schedulerItemsNormalizer() {
			return this.schedulerItems.map((day) => {
				const copy = Object.assign({}, day);
				copy.fromHour = this.convertTime12to24(copy.start);
				copy.toHour = this.convertTime12to24(copy.end);
				copy.id = day.id;
				delete copy.start;
				delete copy.end;
				return copy;
			});
		},
		convertTime12to24(time) {
			let hours = time.hh;
			if (hours === "12") hours = 0;
			hours = parseInt(hours, 10);
			if (time.a === "pm") hours += 12;
			return hours;
		},
		async updateSchedule() {
			try {
				this.dataLoaded = false;
				const res = await this.$store.dispatch("updateSchedule", {
					schedulerItems: this.schedulerItemsNormalizer(),
					zone: this.zone
				});
				this.setNewData(res);
				this.$store.commit("setTimezone", this.zone);
				this.checkForSleepMode(res.schedulerItems);
				this.$toast("Your working hours have been successfully updated");
			} finally {
				this.dataLoaded = true;
			}
		}
	}
};
</script>

<style lang="sass">
.schedule
	display: flex
	flex-direction: column
	&__
		&head
			display: flex
			justify-content: space-between
			align-items: center
			flex-wrap: wrap
			gap: 20px
		&wrap
			flex: 1
			min-height: 550px
			overflow: visible auto
			margin: 0 calc(var(--card-offset-x) * -1)
		&footer
			min-height: 44px
			display: flex
			align-items: center
			justify-content: space-between
			gap: 25px 10px
			margin-top: 25px
			@media screen and (max-width: $mobile)
				align-items: flex-start
				flex-direction: column-reverse

.work-hours
	display: inline-flex
	align-items: flex-start
	gap: 6px
	padding: 24px var(--card-offset-x)

.day-card
	$this: &
	border-radius: var(--bdrs)
	border: var(--bd)
	padding: 14px
	min-width: 155px
	flex: 1
	&:hover,
	&:focus-within
		#{$this}__to-all
			height: 39px
			padding-top: 15px
	&__
		&head
			display: flex
			align-items: center
			justify-content: space-between
			gap: 10px
			margin-bottom: 24px
		&timerange
			display: flex
			flex-direction: column
			gap: 10px
		&to-all
			height: 0
			padding: 0
			display: flex
			justify-content: center
			overflow: hidden
			transition: height var(--transition), padding-top var(--transition)
		&today
			position: absolute
			left: 0
			top: calc(100% + 3px)
		&from-to
			margin-bottom: 4px

.schedule-time-convert
	display: flex
	align-items: center
	gap: 10px
	color: var(--neutral-500)
	&:hover,
	&:focus-visible
		color: var(--main-primary)

.timezone
	display: flex
	align-items: center
	gap: 15px
	@media screen and (max-width: $mobile)
		display: block
		width: 100%
	&__
		&select
			min-width: 280px
			max-width: 500px
			width: 100%
			@media screen and (max-width: $mobile)
				margin-top: 15px
				display: block
				max-width: 100%
</style>
