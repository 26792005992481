
export function restrictedLIAccess(status) {
	return (
		status === "UNAUTHORIZED" ||
		status === "LOGIN_RESTRICTED" ||
		status === "FAILED_CAPTCHA" ||
		status === "REGISTER_PHONE_CHALLENGED"
	);
}
export function approvementLIStatus(status) {
	return (
		status.includes("TWO_FA") ||
		status === "PIN_CHALLENGED" ||
		// status === "WRONG_VERIFICATION_CODE" ||   // TODO: Possible fix for https://dripify.atlassian.net/browse/DD-121
		status === "CAPTCHA_IN_PROGRESS"
	);
}

export function failedUserStatus(status) {
	return restrictedLIAccess(status) || approvementLIStatus(status);
}
