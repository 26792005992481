var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox",class:{
		'checkbox--inline': _vm.haveLabel,
		'checkbox--disabled': _vm.disabled
	}},[_c('input',_vm._b({staticClass:"checkbox__input",attrs:{"id":_vm.checkName,"type":"checkbox","name":_vm.checkName,"disabled":_vm.disabled},domProps:{"checked":_vm.checked},on:{"change":function($event){return _vm.$emit('change', $event.target.checked)}}},'input',_vm.$attrs,false)),_c('label',{staticClass:"checkbox__label",class:{ 'checkbox__label--disabled': _vm.disabled },attrs:{"for":_vm.checkName}},[(_vm.haveLabel)?_c('span',{staticClass:"checkbox__text",class:{
				'checkbox__text--disabled': _vm.disabled,
				'checkbox__text--required': _vm.required
			}},[_vm._t("default")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }