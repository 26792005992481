import store from "@/js/store";
import { appendScript } from "@/js/helpers/utils";

export const SUPPORT_CHAT_BUTTON_SELECTOR = ".intercom-app";
const THEME_COLOR = "#7760F9";

export function setSupportChatOptions(userInfo) {
	window.intercomSettings = {
		app_id: process.env.VUE_APP_INTERCOM_APP_ID,
		name: userInfo ? userInfo.displayName : "Guest",
		created_at: `${Date.now()}`,
		background_color: THEME_COLOR,
		action_color: THEME_COLOR
	};
	if (userInfo) {
		window.intercomSettings.email = userInfo.email;
		window.intercomSettings.created_at = `${new Date(userInfo.metadata.creationTime).getTime()}`;
	}
	if (typeof window.Intercom === "function") refreshSupportChat();
}

export function registerSupportChat(callback) {
	setSupportChatOptions(store.getters.userInfo);
	if (typeof window.Intercom === "function") {
		refreshSupportChat();
	} else {
		const i = () => {
			i.c(arguments);
		};
		i.q = [];
		i.c = (args) => {
			i.q.push(args);
		};
		window.Intercom = i;

		appendScript(
			{
				type: "text/javascript",
				async: true,
				src: `https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}`
			},
			callback
		);
	}
}
export function openSupportChat() {
	if (!window.Intercom) {
		registerSupportChat(() => window.Intercom("show"));
	} else {
		window.Intercom("show");
	}
}

function refreshSupportChat() {
	window.Intercom("reattach_activator");
	window.Intercom("update", window.intercomSettings);
}
