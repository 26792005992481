<template>
	<ValidationProvider
		v-slot="{ errors, classes, validate }"
		ref="provider"
		:rules="validation"
		:name="inputName"
		:vid="vid"
		:mode="validationMode"
		:custom-messages="customMessages"
		class="field"
		:class="{
			'field--inline': inline,
			'field--numeric': numeric,
			'field--limit': limit
		}"
		tag="div"
	>
		<label
			v-if="labelText"
			class="field__label"
			:for="inputName"
		>
			{{ labelText }}
			<span
				v-if="isRequired"
				aria-label="Required"
			>&nbsp;*</span>
		</label>

		<div
			class="field__input-wrap"
			:class="{
				'field__input-wrap--invalid': classes.invalid,
				'field__input-wrap--disabled': disabled
			}"
		>
			<input
				:id="inputName"
				:ref="inputName"
				:name="inputName"
				:type="type"
				class="field__input"
				:autocomplete="autocomplete"
				:value="value"
				:disabled="disabled"
				:inputmode="inputmode || computedMode"
				:pattern="pattern"
				@change="$emit('change', $event)"
				@input="onInput($event)"
				@focus="$emit('focus')"
				@blur="$emit('blur')"
				@keydown.enter="validateField($event, validate)"
			>
			<label
				v-if="ph"
				:for="inputName"
				class="field__placeholder"
				:class="{
					'field__placeholder--hidden': value !== '',
				}"
			>
				{{ ph }}
				<span
					v-if="isRequired"
					aria-label="Required"
				>&nbsp;*</span>
			</label>
			<div
				v-if="validation.max && limit"
				class="field__max"
			>
				{{ validation.max - value.length }}
			</div>
		</div>

		<transition name="fade">
			<small
				v-if="errorText || errors[0]"
				class="field__message"
				v-html="errorText || errors[0]"
			/>
		</transition>
	</ValidationProvider>
</template>

<script>
export default {
	name: "TextInput",
	model: {
		prop: "value",
		event: "input"
	},
	props: {
		type: { type: String, default: "text" },
		value: { type: [String, Number], required: true },
		validation: { type: [String, Object], default: "" },
		inputName: { type: String, required: true },
		labelText: { type: String, default: "" },
		vid: { type: String, default: "" },
		ph: { type: String, default: "" },
		inline: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		numeric: { type: Boolean, default: false },
		autocomplete: { type: String, default: "off" },
		pattern: { type: String, required: false },
		focused: { type: Boolean, default: false },
		selected: { type: Boolean, default: false },
		required: { type: Boolean, default: false },
		inputmode: { type: String, required: false },
		enterkeyhint: { type: String, required: false },
		limit: { type: Boolean, default: false },
		validationMode: { type: String, default: "aggressive" },
		errorText: { type: String, default: "" },
		customMessages: { type: Object, default: () => {} }
	},
	data: () => ({
		isValid: false
	}),
	computed: {
		isRequired() {
			return JSON.stringify(this.validation).indexOf("required") !== -1 || this.required;
		},
		computedMode() {
			if (this.type === "search" || this.type === "url" || this.type === "email") {
				return this.type;
			} else if (this.type === "number") {
				return "numeric";
			}
			return null;
		},
		keyHint() {
			return this.type === "search" ? "search" : this.enterkeyhint || "done";
		}
	},
	watch: {
		isValid(newVal) {
			this.$emit("onValidationStateChange", newVal);
		}
	},
	mounted() {
		this.$watch(() => {
			return this.$refs.provider.errors
		}, (errors) => {
			this.isValid = !errors.length;
		})

		const input = this.$refs[this.inputName];
		if (this.focused) input.focus();
		else if (this.selected) input.select();
		input.addEventListener("keypress", (event) => {
			if (event.keyCode === 13) this.onExecutePress(event);
		});
	},
	methods: {
		async onInput(event) {
			this.$emit("input", event.target.value);
		},
		onExecutePress(e) {
			e.preventDefault();
			this.$emit("onExecutePress");
		},
		validateField(event, validate) {
			validate();
			this.$emit("enter", event.target.value)
		},
		async validate() {
			return this.$refs.provider.validate();
		},
	}
};
</script>

<style lang="sass">
.field
	$this: &
	--padding-x: 18px
	--offset-left: var(--padding-left, 18px)
	position: relative
	margin-bottom: 18px
	&--inline
		margin-bottom: 0
	&--numeric
		margin-right: 15px
	&--limit input
		padding-right: 40px
	&__
		&label
			margin-bottom: 18px
			display: inline-block
			&[for]
				cursor: pointer
			a
				text-decoration: underline
				color: var(--main-primary)
				&:hover,
				&:focus
					text-decoration: none
		&input-wrap
			width: 100%
			position: relative
			overflow-x: hidden
			border-radius: var(--bdrs)
			border: var(--bd)
			text-align: left
			&:hover
				&:not(#{$this}__input-wrap--invalid)
					border-color: var(--neutral-300)
				.field__placeholder
					color: var(--neutral-600)
			&:focus-within
				border-color: var(--main-primary) !important
			&--
				&invalid
					border-color: var(--main-red)
				&disabled
					border-color: var(--neutral-200) !important
					background-color: var(--neutral-200) !important
		&eye
			display: flex
			align-items: center
			height: 100%
			width: calc(var(--padding-x) * 2)
			position: absolute
			right: 0
			top: 0
			z-index: 2
			cursor: pointer
			& + #{$this}__input
				padding-right: 35px
			&:hover,
			&:focus
				path
					stroke: var(--main-accent)
		&input
			width: 100%
			height: 44px
			padding: 9px var(--padding-x) 9px var(--offset-left)
			position: relative
			z-index: 1
			color: var(--neutral-900)
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button
				-webkit-appearance: none
				margin: 0
			&[type=number]
				-moz-appearance: textfield
			&:disabled
				color: var(--neutral-400)
				cursor: not-allowed
		&placeholder
			display: flex
			align-items: center
			position: absolute
			inset: 0
			padding: 0 var(--offset-left)
			white-space: nowrap
			overflow: hidden
			color: var(--neutral-400)
			z-index: 0
			transition: opacity .2s, transform .2s
			&--
				&hidden
					opacity: 0
					transform: translateX(30px)
		&message
			position: absolute
			top: calc(100% + 1px)
			left: 0
			padding-left: 18px
			color: var(--main-red)
			font-size: 12px

		&max
			font-size: 15px
			color: var(--neutral-400)
			position: absolute
			bottom: 10px
			right: 10px
			z-index: 3
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
	-webkit-appearance: none
</style>
