<template>
	<section
		class="add-leads"
		aria-label="Campaign leads adding"
	>
		<div class="head">
			<h2 class="h6">Lists of leads</h2>
			<div v-tooltip="leadsAddingDisabledText">
				<button
					v-if="items.length"
					class="btn  btn--auto-w"
					:class="{ 'btn--base': !campaignCreatingPage }"
					aria-haspopup="true"
					:disabled="creatingDisabled"
					@click="openAudiencePopup"
				>
					{{ campaignEdit ? "Add leads" : "Add a new list" }}
				</button>
			</div>
		</div>
		<div v-if="preload">
			<div
				v-if="preloadItems"
				class="add-leads__list"
			>
				<LeadsPackSkeleton
					v-for="i in preloadItems"
					:key="i"
				/>
			</div>
			<Preloader v-else />
		</div>
		<div v-else-if="items.length">
			<span id="skip-leads-lists-up" />
			<a
				v-if="items.length > 1"
				href="#skip-leads-lists-down"
				class="acccessible-skip"
			>Skip leads lists and go down</a>
			<ul
				class="add-leads__list"
				aria-label="Leads lists"
			>
				<li
					v-for="pack in items"
					:key="pack.createdAt"
				>
					<LeadsPack
						:pack="pack"
						:is-draft="isDraft"
						:campaign-creating="campaignCreatingPage"
						:campaign="campaign"
						:statistics-loading="statisticsLoading"
						@removed="removePackage"
					/>
				</li>
			</ul>

			<a
				v-if="items.length > 1"
				href="#skip-leads-lists-up"
				class="acccessible-skip acccessible-skip--bottom"
			>Skip leads lists and go up</a>
			<span id="skip-leads-lists-down" />

			<ListItemsViewManager
				:items-on-page="items.length"
				:pg-count="pageCount"
				class="mt"
				list-name="lists"
				:pg-size="+pageSize"
				:pg="page"
				@onPageSizeChange="pageSizeChangeHandler"
			/>
		</div>
		<div
			v-else
			class="add-leads__empty"
		>
			<ImgComp
				src="add-leads.svg"
				w="150"
				h="200"
				alt="No leads"
				class="add-leads__empty-img mb"
			/>
			<span class="body_L mb"> Add leads from LinkedIn to this campaign </span>
			<div v-tooltip="leadsAddingDisabledText">
				<button
					class="btn btn--base btn--auto-w"
					:disabled="creatingDisabled"
					aria-haspopup="true"
					@click="openAudiencePopup"
				>
					Add leads
				</button>
			</div>
		</div>

		<transition name="fade">
			<LeadsAdding
				v-if="popup"
				:campaign-id="campaign.id"
				@created="addPack"
				@close="closeAudiencePopup"
			/>
		</transition>
	</section>
</template>

<script>
import PaginationMixin from "@/js/mixins/pagination.mixin";
import getLeadPacksStatistics from "@/js/mixins/getLeadPacksStatistics.mixin";
import LeadsPack from "@/js/components/Campaign/Audience/LeadsPack";
import LeadsPackSkeleton from "@/js/components/Campaign/Audience/LeadsPackSkeleton";
import unavailableFunctionalityTooltip from "@/js/mixins/unavailableFunctionalityTooltip.mixin";
import { isCreationDisabledByUserState } from "@/js/helpers/permissionStates";

export default {
	name: "Audience",
	components: {
		LeadsAdding: () =>
			window.compLoader(import("@/js/components/Campaign/Audience/LeadsAdding/LeadsAdding")),
		LeadsPack,
		LeadsPackSkeleton
	},
	mixins: [getLeadPacksStatistics, PaginationMixin, unavailableFunctionalityTooltip],
	props: {
		campaignEdit: { type: Boolean, required: false },
		campaign: { type: Object, required: true }
	},
	data: () => ({
		preload: true,
		popup: false,
		deletePopup: false,
		items: [],
		listsResponse: null,
		isDraft: false
	}),
	computed: {
		campaignCreatingPage() {
			return this.$route.name === "campaign-create";
		},
		preloadItems() {
			const campStat = this.campaign.statistics;
			return campStat ? campStat.leadsListsSize : this.items.length;
		},
		creatingDisabled() {
			return this.campaign.locked || this.leadsAddingDisabled || isCreationDisabledByUserState();
		}
	},
	watch: {
		pageNumber(newVal) {
			this.pageChangeHandler(newVal);
		}
	},
	async mounted() {
		try {
			this.pageSize = localStorage.getItem("listsPageSize") || this.pageSize;
			if (this.campaignEdit) {
				this.isDraft = this.campaign.status === "DRAFT";
			}
			if (this.addLeadsQuery) {
				this.openAudiencePopup();
			}
			await this.pageChangeHandler(this.pageNumber);
		} finally {
			this.preload = false;
		}
	},
	methods: {
		async pageChangeHandler(page = 1) {
			this.listsResponse = await this.getListItems("getSearchLeadsConfigItems", page, {
				campaignId: this.campaign.id,
				sort: "createdAt,desc"
			});
			this.items = this.listsResponse.content;
			if (this.campaignEdit) {
				await this.getLeadPacksStatistics(this.items);
			}
		},
		onPacksChange() {
			this.$emit("packsCountChanged", this.items.length);
		},
		async addPack() {
			await this.pageChangeHandler();
			this.closeAudiencePopup();
			this.onPacksChange();
		},
		closeAudiencePopup() {
			this.popup = false;
		},
		openAudiencePopup() {
			this.popup = true;
		},
		async removePackage() {
			await this.pageChangeHandler();
			this.onPacksChange();
		},
		pageSizeChangeHandler(val) {
			this.pageSize = val;
			localStorage.setItem("listsPageSize", this.pageSize);
			this.pageChangeHandler();
		}
	}
};
</script>

<style lang="sass">
.add-leads
	flex: 1
	position: relative
	display: grid
	grid-template-rows: min-content 1fr
	&__
		&list
			display: grid
			grid-template-columns: 1fr 1fr
			gap: 14px
			@media screen and (max-width: $netbook)
				grid-template-columns: 100%
		&empty
			+flex(center, center, column)
			min-height: 400px
			height: 90%
			text-align: center
		&empty-img
			max-width: 125px

</style>
