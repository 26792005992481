<template>
	<div
		v-click-outside="blurHandler"
		class="pass-input"
		aria-label="Password input"
	>
		<TextInput
			v-model="password"
			:input-name="inputName"
			:ph="ph"
			:label-text="labelText"
			:validation="validationRules"
			:validation-mode="validationMode"
			:type="isVisiblePass ? 'text' : 'password'"
			:vid="vid"
			:enterkeyhint="enterkeyhint"
			:error-text="requirements ? ' ' : ''"
			:disabled="disabled"
			:autocomplete="newPassword ? 'new-password' : 'off'"
			inline
			@input="onInput"
			@focus="focusHandler"
			@onExecutePress="$emit('onExecutePress')"
			@onValidationStateChange="validationChangeHandler"
		/>
		<transition name="fade">
			<button
				v-if="password.length"
				class="pass-input__eye"
				:title="isVisiblePass ? 'Hide password' : 'Show password'"
				@click.prevent="changePassVisibility"
			>
				<Icon :icon="isVisiblePass ? 'eye-close' : 'eye'" />
			</button>
		</transition>

		<transition name="slide">
			<ul
				v-if="showRequirements"
				class="pass-input__requirements"
			>
				<li
					v-for="item in requirementsList"
					:key="item.text"
					class="body_S pass-input__requirement"
					:class="{ 'pass-input__requirement--valid' : item.valid }"
				>
					<Icon :icon="item.valid ? 'circled-check' : 'circled-cross'" />
					{{ item.text }}
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
import eventBus from "@/event-bus";
import ClickOutside from "vue-click-outside";
import { containsUppercase, containsLowercase, containsNumbers } from "@/js/helpers/utils";

export default {
	name: "PasswordInput",
	directives: { ClickOutside },
	model: {
		prop: "value",
		event: "input"
	},
	props: {
		value: { type: String, default: "" },
		inputName: { type: String, default: "password" },
		labelText: { type: String, default: "" },
		ph: { type: String, default: "" },
		interactivePass: { type: Boolean, default: false },
		newPassword: { type: Boolean, default: false },
		vid: { type: String, default: "password" },
		enterkeyhint: { type: String, required: false },
		validationMode: { type: String, default: "eager" },
		requirements: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false }
	},
	data: () => ({
		password: "",
		isVisiblePass: false,
		isValid: true,
		focused: false
	}),
	computed: {
		validationRules() {
			let validation = {
				required: !this.newPassword,
				min: 6
			};
			if (this.requirements || this.newPassword) {
				Object.assign(validation, {
					containsUppercase: 1,
					containsLowercase: 1,
					containsNumbers: 1
				});
			}

			return validation
		},
		requirementsList() {
			return [
				{
					text: "one lowercase character",
					valid: containsLowercase(this.password, this.validationRules.containsLowercase)
				},
				{
					text: "6 character minimum",
					valid: this.password.length >= this.validationRules.min
				},
				{
					text: "one uppercase character",
					valid: containsUppercase(this.password, this.validationRules.containsUppercase)
				},
				{
					text: "one number",
					valid: containsNumbers(this.password, this.validationRules.containsNumbers)
				},
			]
		},
		showRequirements() {
			return this.requirements && (this.focused || !this.isValid)
		}
	},
	watch: {
		value(newVal) {
			this.password = newVal;
		}
	},
	methods: {
		changePassVisibility() {
			const inputEl = this.$el.querySelector("input");
			this.isVisiblePass = !this.isVisiblePass;
			inputEl.focus();
		},
		onInput(val) {
			this.$emit("input", val);
		},
		focusHandler() {
			this.focused = true;
			if (this.interactivePass) {
				eventBus.$emit("passwordFocused");
			}
		},
		blurHandler() {
			this.focused = false;
			if (this.interactivePass) {
				eventBus.$emit("passwordBlured");
			}
		},
		validationChangeHandler(val) {
			this.isValid = val;
			this.$emit("onValidationStateChange", this.isValid);
		}
	}
};
</script>

<style lang="sass">
.pass-input
	position: relative
	margin-bottom: 18px
	input
		width: calc(100% - var(--padding-x) * 2)
	&__
		&eye
			display: flex
			align-items: center
			height: 46px
			width: calc(var(--padding-x) * 2)
			position: absolute
			right: 10px
			top: 0
			z-index: 2
			cursor: pointer
			&:hover,
			&:focus
				path
					stroke: var(--main-primary)
		&requirements
			display: grid
			grid-template-columns: 1fr 1fr
			gap: 4px 70px
			padding-top: 4px
			overflow: hidden
			max-height: 56px
		&requirement
			display: flex
			align-items: center
			gap: 8px
			transition: color var(--transition)
			&--
				&valid
					color: var(--main-green)
</style>
