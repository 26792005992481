import { getCookieByName } from "@/js/helpers/cookieManager";
import store from "@/js/store";
import { PLANS_LIST } from "@/js/helpers/constants/constants";

export const getSessionId = () => {
	const COOKIE_NAME = process.env.VUE_APP_GA_COOKIE_NAME;
	const COOKIE_STRING = getCookieByName(COOKIE_NAME);

	return COOKIE_STRING ? COOKIE_STRING.split(".")[2] : "";
};

export const getClientId = () => {
	const COOKIE_STRING = getCookieByName("_ga");
	if (!COOKIE_STRING) return "";

	if (!window.ga) return "";
	const TRACKERS_LIST = window.ga.getAll();


	if (TRACKERS_LIST.length < 2) {
		const COOKIE_STRING = getCookieByName("_ga");
		const match = COOKIE_STRING.match(/(\d+\.\d+)$/);

		return match ? match[1] : "";
	}
	return TRACKERS_LIST[1].get("clientId");
};

export const getGaPayload = () => ({
	clientId: getClientId(),
	sessionId: getSessionId(),
});

export function sendGoogleAnalytics(eventType, payload = {}){
	const CURRENT_USER_ID = store.getters.userInfo.uid

	pushAnalyticsAction({
		user_id: CURRENT_USER_ID,
		event: eventType,
		...payload
	});
}

function pushAnalyticsAction(data) {
	const isLocal = window.location.host.includes("localhost")
	if (isLocal) return;
	const dataLayer = window.dataLayer;

	if (!dataLayer) {
		console.error("Google Analytics Integrator: 'dataLayer' is not found")
	} else {
		dataLayer.push(data)
	}
}

export function sendEcommerceAnalytics(eventType, data) {
	pushAnalyticsAction({ ecommerce: null });		// Clear the previous ecommerce object

	const payload = {
		event: eventType,
		ecommerce: {}
	}

	if (eventType === "view_item_list") {
		payload.ecommerce.items = createViewProductsPagePayload(data)
	} else if (eventType === "add_to_cart" || eventType === "begin_checkout") {
		payload.ecommerce = createProductInfoPayload(data)
	}

	pushAnalyticsAction(payload)
}

export function createViewProductsPagePayload(products) {
	return products.map(product => createProductDetailsForPayload(product.id, product.value))
}

export function createProductInfoPayload(data) {
	const PLAN_PRICE = data.productPrice;

	return {
		currency: "USD",
		value: PLAN_PRICE,
		items: [createProductDetailsForPayload(data.productID, PLAN_PRICE)]
	};
}

export function createProductDetailsForPayload(productID, productPrice) {
	const PLAN_PAYMENT_PERIOD = productID.split("-")[1];									// TODO: change when planDetails.js is refactored
	const IS_DRIPIFY_PLAN = PLANS_LIST.includes(productID);			// TODO: change when planDetails.js is refactored
	const BRAND_NAME = IS_DRIPIFY_PLAN ? "dripify" : "email_finder";

	return {
		item_id: productID,
		item_name: productID,
		item_brand: BRAND_NAME,
		item_category: PLAN_PAYMENT_PERIOD,
		price: productPrice,
		quantity: 1
	}
}
