import { formattingParams } from "@/js/helpers/paramsFormatter";

export default {
	actions: {
		async getLeads(store, params) {
			try {
				const res = await window.request("GET", "/leads", params);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async getLeadById(store, id) {
			try {
				const res = await window.request("GET", `/leads/${id}`);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async updateLeadById(_, { payload, id }) {
			const res = await window.request("PATCH", `/leads/${id}`, payload);

			return res.data;
		},
		async updateLeadsInBulk(store, payload) {
			try {
				const res = await window.request("PATCH", "/leads/", payload);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async createLeadNote(store, { note, id }) {
			try {
				const res = await window.request("POST", `/leads/${id}/notes`, {
					content: note
				});

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async updateLeadNote(store, { note, noteId }) {
			try {
				const res = await window.request("PUT", `/notes/${noteId}`, {
					content: note,
					id: noteId
				});

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async deleteLeadNote(store, noteId) {
			try {
				await window.request("DELETE", `/notes/${noteId}`);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async deleteLead(store, id) {
			try {
				await window.request("DELETE", `/leads/${id}`);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async deleteLeadsInBulk(store, payload) {
			try {
				await window.request("DELETE", `/leads/?${formattingParams(payload)}`);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async deleteLeadsFromCampaign(store, { campaignId, payload }) {
			try {
				await window.request("DELETE", `/leads/campaign/${campaignId}/?${formattingParams(payload)}`);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async exportLeads(store, payload) {
			try {
				const res = await window.request("GET", "/leads/export", payload);

				return res;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async getLeadsCount(store, payload) {
			try {
				const res = await window.request("GET", "/leads/count", payload);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async findEmail(store, id) {
			try {
				const res = await window.request("POST", `/leads/${id}/findemail`);

				return res.data;
			} catch (err) {
				const data = err.response.data;
				const emailWasNotReached = data.status === 422 && data.message === "EMAIL_PROVIDER_WAS_NOT_REACHED";
				const outOfCredits = data.message === "OUT_OF_VERIFY_EMAIL_CREDITS";

				if (emailWasNotReached) {
					store.commit("setRequestErrorMessage", "Email provider was not reached, please, try again");
				} else if (outOfCredits) {
					store.commit("setRequestErrorMessage", "You're out of email finder credits");
				} else {
					store.commit("setRequestErrorMessage", data);
				}

				throw err;
			}
		},
		async batchInvite(store, payload) {
			try {
				await window.request("POST", `/leads/batch-invite`, payload);
			} catch (err) {
				const error = err.response.data;
				store.commit("setRequestErrorMessage", `${error.message}<br><b>Status:</b> ${error.status}`);
				throw err;
			}
		},
		async batchEmailInvite(store, payload) {
			try {
				await window.request("POST", `/leads/batch-email-invite`, payload);
			} catch (err) {
				const error = err.response.data;
				store.commit("setRequestErrorMessage", `${error.message}<br><b>Status:</b> ${error.status}`);
				throw err;
			}
		},
	}
};
