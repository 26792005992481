import adminAccountController from "@/js/helpers/adminAccountController";
import store from "@/js/store/index";
import { isOldUserForNewUI } from "@/js/services/ApiVersionService";
import { failedUserStatus } from "@/js/helpers/failedUserStatus";
import { isObject } from "@/js/helpers/utils";

export function canEmailLeadsExport() {
	return adminAccountController.isAdmin() && store.getters.sideAccountId;
}

export function canSeeNewUIGuide() {
	const hasNewUiGuide = store.getters.remindersArr.some(reminder => (reminder.reminderType === "NEW_UI_GUIDE" && reminder.active));

	return isOldUserForNewUI() && hasNewUiGuide && !store.getters.freeTrialEnds;
}

export async function checkAndSetNewUiTour() {
	await store.dispatch("getReminders");
	const newUiGuideReminder = findNewUiGuideReminder();

	if (isOldUserForNewUI() && !newUiGuideReminder) {
		await store.dispatch("changeReminderStatus", { reminderType: "NEW_UI_GUIDE", active: true });
	}
}

function findNewUiGuideReminder() {
	return store.getters.remindersArr.find(reminder => (reminder.reminderType === "NEW_UI_GUIDE"))
}

export function inviteByEmailFeatureIsEnabled() {
	return store.getters.currentUser.inviteByEmailEnabled;
}

export function userHasAccessToDashboard(user) {
	return isObject(user) && (!failedUserStatus(user.status) || user.onboardingCompleted);
}
