<template>
	<div
		:data-min="min"
		:data-max="max"
		class="range"
		:class="{'range--disabled': disabled}"
		@focus="focusHandler"
		@blur="blurHandler"
	>
		<div :style="customCSSVariables">
			<div class="range__pseudo-rail" />

			<vue-slider
				ref="slider"
				v-model="val"
				:dot-size="22"
				:max="maxLimit"
				tooltip="always"
				:height="4"
				:use-keyboard="true"
				:disabled="disabled"
				@change="onChange"
			>
				<template #tooltip="{ value }">
					<div
						:style="{ opacity: tooltipOpacity }"
						class="range__tooltip"
					>
						<span class="range__tooltip-val body_S">
							{{ value }}
						</span>


						<div
							v-if="stopOnLimit"
							class="range__lock-icon "
						>
							<Icon
								icon="lock-cross"
								size="20"
							/>
							<div class="range__upgrade-label body_XS">
								<router-link
									:to="{ name: 'plans' }"
									class="tertiary-btn"
								>
									Upgrade your plan
								</router-link>
								to increase your limits
							</div>
						</div>
					</div>
					<template v-if="val && !disabled">
						<div
							class="range__range-tooltip body_XS"
							:class="{'range__range-tooltip--other-side' : moreThanHalf}"
						>
							Range {{ minRangeVal }} - {{ maxRangeVal }}
						</div>
					</template>
				</template>
			</vue-slider>

			<div
				id="fake-rail"
				class="fake-rail"
				:class="{'fake-rail--disabled' : disabled}"
				:style="{'width': railWidth}"
				@click="fakeRailClickHandler"
			>
				<transition name="hide-left">
					<div
						v-if="val"
						class="fake-rail__range"
						:class="{'fake-rail__range--disabled' : disabled}"
						@click.stop
					/>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
	name: "Range",
	components: {
		VueSlider
	},
	model: {
		prop: "positionValue",
		event: "change"
	},
	props: {
		min: { type: Number, default: 0 },
		max: { type: Number, default: 100 },
		positionValue: { type: Number, default: 0 },
		step: { type: Number, default: 1 },
		range: { type: Number, default: 3 },
		stopOn: { type: Number, required: false },
		disabled: { type: Boolean, default: false }
	},
	data: () => ({
		val: 0,
		showRangeEars: false
	}),
	computed: {
		stopOnLimit() {
			return this.stopOn && this.val >= this.stopOn;
		},
		railWidth() {
			return this.stopOn ? `${(this.stopOn / this.max) * 100}%` : "100%";
		},
		minRangeVal() {
			const res = this.val - this.range;
			return this.val !== 0 ? (res <= 1 ? 1 : res) : 0;
		},
		maxRangeVal() {
			return this.maxRangeFinished ? this.maxLimit : this.val + this.range;
		},
		maxRangeFinished() {
			return this.val + this.range >= this.maxLimit;
		},
		maxLimit() {
			return this.stopOn || this.max;
		},
		percentage() {
			return this.val / this.maxLimit * 100;
		},
		moreThanHalf() {
			return this.percentage > 50;
		},
		pseudoRailWidth() {
			if (!this.showRangeEars) return 0;
			else {
				const el = this.$el.querySelector("#fake-rail");
				return el.getBoundingClientRect().width;
			}
		},
		rengePercentage() {
			return 100 * this.range / this.maxLimit
		},
		calculateDefaultRangeWidth() {
			return ((this.rengePercentage * this.pseudoRailWidth / 100) * 2) + "px";
		},
		customCSSVariables() {
			return {
				"width": this.railWidth,
				"--range-width": this.calculateDefaultRangeWidth,
				"--dot-position": this.percentage + "%"
			}
		},
		tooltipOpacity() {
			return this.val && (this.val !== this.maxLimit || this.stopOnLimit) ? 1 : 0
		}
	},
	watch: {
		positionValue(newVal) {
			this.val = newVal;
		},
		disabled(newVal) {
			setTimeout(() => {
				if (!newVal) this.showRangeEars = true;
			}, 300);

		}
	},
	created() {
		this.val = this.positionValue;
	},
	methods: {
		fakeRailClickHandler(event) {
			const w = event.target.getBoundingClientRect().width;
			const offsetX = event.offsetX;
			const cleckedOnPercent = offsetX / w * 100;
			this.val = Math.round(this.maxLimit * cleckedOnPercent / 100);
			this.onChange();
		},
		onChange() {
			this.emitValue(this.val);
		},
		emitValue(val) {
			this.$emit("change", val);
		},
		focusHandler() {
			if (!this.disabled) this.$refs.slider.focus();
		},
		blurHandler() {
			this.$refs.slider.blur();
		},
	}
};
</script>

<style lang="sass">
.fake-rail
	height: 4px
	width: 100%
	position: absolute
	top: 50%
	left: 0
	transform: translateY(-50%)
	overflow: hidden
	z-index: 1
	border-radius: 8px
	&--
		&disabled
			pointer-events: none
			cursor: not-allowed
	&__
		&range
			height: 4px
			border-radius: inherit
			width: var(--range-width)
			background-color: var(--main-accent)
			position: absolute
			top: 0
			left: var(--dot-position)
			transform: translateX(-50%)
			transition: 0.5s ease
			&--
				&disabled
					width: 0

.hide-left-enter-active, .hide-left-leave-active
	transition: all var(--transition)
.hide-left-enter, .hide-left-leave-to
	opacity: 0
	transform: translateX(-100%)


.range
	position: relative
	padding: 20px 0
	border-radius: 10px8px
	transition: filter var(--transition)
	&:hover
		.range__range-tooltip,
		.range__range-tooltip-ear,
			display: block
	&--
		&disabled
			filter: grayscale(100%)
	&__
		&range-tooltip
			display: none
			position: absolute
			top: 47px
			border: var(--bd)
			box-shadow: 0px 16px 24px -8px rgba(0, 0, 0, 0.05)
			border-radius: var(--bdrs)
			background-color: var(--neutral-0)
			padding: 2px 8px
			left: 30px
			white-space: nowrap
			z-index: 1
			&--
				&other-side
					left: auto
					right: 30px

		&tooltip
			display: flex
			align-items: center
			justify-content: center
			gap: 6px
			border: var(--bd)
			box-shadow: 0px 16px 24px -8px rgba(0, 0, 0, 0.05)
			border-radius: var(--bdrs)
			padding: 0 12px
			background-color: var(--neutral-0)

		&tooltip-val
			position: relative
			z-index: 1
		&upgrade-label
			position: absolute
			left: 95%
			bottom: 60%
			padding: 5px 10px
			white-space: nowrap
			background-color: var(--neutral-0)
			border-radius: var(--bdrs)
			border: var(--bd)
			box-shadow: 0px 16px 24px -8px rgba(0, 0, 0, 0.05)
			display: none
			&::after
				content: ''
				position: absolute
				top: calc(100% - 5px)
				left: 0
				width: 100%
				height: 15px
		&lock-icon
			position: relative
			height: 20px
			&:hover
				svg *
					stroke: var(--main-primary)
				.range__upgrade-label
					display: block
		&pseudo-rail
			height: 4px
			border-radius: inherit
			width: 100%
			background-color: var(--neutral-100)
			position: absolute
			top: 50%
			transform: translateY(-50%)
			left: 0
	&::before,
	&::after
		position: absolute
		top: 7px
		transition: opacity var(--transition)
		color: var(--neutral-400)
		font-size: 14px
		line-height: 1.71
		@media screen and (max-width: $fablet)
			top: 90%
	&::before
		content: attr(data-min)
		left: 0
	&::after
		content: attr(data-max)
		right: 0
	&:focus
		.vue-slider-dot
			box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.2)

.vue-slider-dot
	position: absolute
	transition: all 0s
	z-index: 2 !important
	border-radius: 50%
	&:focus-visible
		.range__range-tooltip,
		.range__range-tooltip-ear,
			display: block



.vue-slider-dot-tooltip
	position: absolute
	visibility: hidden

.vue-slider-dot-hover:hover .vue-slider-dot-tooltip, .vue-slider-dot-tooltip-show
	visibility: visible

.vue-slider-dot-tooltip-top
	top: -5px
	left: 50%
	transform: translate(-50%, -100%)

.vue-slider-dot-tooltip-bottom
	bottom: -10px
	left: 50%
	transform: translate(-50%, 100%)

.vue-slider-dot-tooltip-left
	left: -10px
	top: 50%
	transform: translate(-100%, -50%)

.vue-slider-dot-tooltip-right
	right: -10px
	top: 50%
	transform: translate(100%, -50%)

.vue-slider-marks
	position: relative
	width: 100%
	height: 100%

.vue-slider-mark
	position: absolute
	z-index: 1

.vue-slider-ltr .vue-slider-mark, .vue-slider-rtl .vue-slider-mark
	width: 0
	height: 100%
	top: 50%

.vue-slider-ltr .vue-slider-mark-step, .vue-slider-rtl .vue-slider-mark-step
	top: 0

.vue-slider-ltr .vue-slider-mark-label, .vue-slider-rtl .vue-slider-mark-label
	top: 100%
	margin-top: 10px

.vue-slider-ltr .vue-slider-mark
	transform: translate(-50%, -50%)

.vue-slider-ltr .vue-slider-mark-step
	left: 0

.vue-slider-ltr .vue-slider-mark-label
	left: 50%
	transform: translateX(-50%)

.vue-slider-rtl .vue-slider-mark
	transform: translate(50%, -50%)

.vue-slider-rtl .vue-slider-mark-step
	right: 0

.vue-slider-rtl .vue-slider-mark-label
	right: 50%
	transform: translateX(50%)

.vue-slider-btt .vue-slider-mark, .vue-slider-ttb .vue-slider-mark
	width: 100%
	height: 0
	left: 50%

.vue-slider-btt .vue-slider-mark-step, .vue-slider-ttb .vue-slider-mark-step
	left: 0

.vue-slider-btt .vue-slider-mark-label, .vue-slider-ttb .vue-slider-mark-label
	left: 100%
	margin-left: 10px

.vue-slider-btt .vue-slider-mark
	transform: translate(-50%, 50%)

.vue-slider-btt .vue-slider-mark-step
	top: 0

.vue-slider-btt .vue-slider-mark-label
	top: 50%
	transform: translateY(-50%)

.vue-slider-ttb .vue-slider-mark
	transform: translate(-50%, -50%)

.vue-slider-ttb .vue-slider-mark-step
	bottom: 0

.vue-slider-ttb .vue-slider-mark-label
	bottom: 50%
	transform: translateY(50%)

.vue-slider-mark-label, .vue-slider-mark-step
	position: absolute

.vue-slider
	position: relative
	user-select: none
	display: block
	transition: var(--transition)

.vue-slider-rail
	position: relative
	width: 100%
	height: 100%
	transition-property: width,height,left,right,top,bottom
	background: var(--neutral-200)
	border-radius: 15px

.vue-slider-process
	position: absolute
	z-index: 1
	background-color: var(--main-primary)
	border-radius: 15px

.vue-slider-disabled
	cursor: not-allowed

.vue-slider-mark
	z-index: 4

.vue-slider-mark:first-child .vue-slider-mark-step,
.vue-slider-mark:last-child .vue-slider-mark-step
	display: none

.vue-slider-mark-step
	width: 100%
	height: 100%
	border-radius: 50%
	background-color: rgba(0, 0, 0, 0.16)

.vue-slider-mark-label
	white-space: nowrap

/* dot style
.vue-slider-dot-handle
	cursor: grab
	width: 100%
	height: 100%
	border-radius: 50%
	background-color: var(--light-color)
	border: 2px solid var(--main-primary)
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08)
	transition: all var(--transition)
	&:active
		cursor: grabbing
		background-color: var(--main-accent)

.vue-slider-dot-handle-disabled
	cursor: not-allowed

.vue-slider-dot-tooltip-inner
	font-size: 14px
	white-space: nowrap
	text-align: center
	color: var(--neutral-900)
	min-width: 55px
	@media screen and (max-width: $fablet)
		background: none
		font-size: 12px

.vue-slider-dot-tooltip-inner-bottom::after
	bottom: 100%
	left: 50%
	transform: translate(-50%, 0)
	height: 0
	width: 0
	border-color: transparent
	border-style: solid
	border-width: 5px
	border-bottom-color: inherit

.vue-slider-dot-tooltip-inner-left::after
	left: 100%
	top: 50%
	transform: translate(0, -50%)
	height: 0
	width: 0
	border-color: transparent
	border-style: solid
	border-width: 5px
	border-left-color: inherit

.vue-slider-dot-tooltip-inner-right::after
	right: 100%
	top: 50%
	transform: translate(0, -50%)
	height: 0
	width: 0
	border-color: transparent
	border-style: solid
	border-width: 5px
	border-right-color: inherit

.vue-slider-dot-tooltip-wrapper
	opacity: 0
	transition: all 0.3s

.vue-slider-dot-tooltip-wrapper-show
	opacity: 1
</style>
