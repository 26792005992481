export default {
	components: {
		RemovingPopup: () =>
			window.compLoader(import("@/js/components/RemovingPopup")),
	},
	data: () => ({
		removingPopup: false
	}),
	methods: {
		openRemovingPopup() {
			this.removingPopup = true;
		},
		closeRemovingPopup() {
			this.removingPopup = false;
		}
	}
};
