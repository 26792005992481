<template>
	<div
		class="campains-list-skeleton"
		:class="{'campains-list-skeleton--full': full}"
	>
		<div
			v-if="header"
			class="campaigns__header"
		>
			<div class="skeleton-item blinking-item" />
			<span />
			<div class="skeleton-item skeleton-item--filled blinking-item" />
		</div>

		<div
			v-if="sortingBar"
			class="sorting-grid sorting-grid--campaigns campaigns-ph-sorting"
		>
			<div class="blinking-item" />
			<div class="blinking-item" />
			<div class="blinking-item" />
			<div class="blinking-item" />
		</div>

		<div
			v-for="i in +count"
			:key="i"
			class="camp-skeleton blinking-item"
		>
			<div class="camp-skeleton__m" />
			<span />
			<span />
			<div class="camp-skeleton__t" />
		</div>
	</div>
</template>

<script>
export default {
	name: "CampItemSkeleton",
	props: {
		sortingBar: { type: Boolean, default: true },
		header: { type: Boolean, default: false },
		full: { type: Boolean, default: false },
		count: { type: [Number, String], default: 1 },
	}
}
</script>

<style lang="sass">
.campains-list-skeleton
	&--
		&full
			position: absolute
			inset: var(--card-offset-y) var(--card-offset-x) 0
			overflow: hidden

.camp-skeleton
	display: grid
	gap: 30px
	grid-template-columns: 3fr 2fr 2fr 90px
	border-top: var(--bd)
	min-height: 120px
	align-items: center
	padding: 27px 0
	&:last-child:not(:first-child)
		margin-bottom: -27px
	@media screen and (max-width: $tablet)
			--gapSize: 7px
			grid-template-columns: 1fr 90px
	span
		display: flex
		flex-direction: column
		justify-content: center
		gap: 20px
		&::before,
		&::after
			content: ""
			display: block
			width: 85px
			height: 10px
			border-radius: var(--bdrs)
			background-color: var(--neutral-100)
	&__
		&m
			&:before
				content: ""
				display: block
				width: 80%
				height: 17px
				border-radius: var(--bdrs)
				background: linear-gradient(to right, var(--neutral-200), transparent)
				margin-bottom: 15px
			&:after
				content: ""
				display: block
				width: 100%
				height: 4px
				border-radius: var(--bdrs)
				background-color: var(--neutral-100)

		&t
			&::before
				content: ""
				width: 40px
				height: 20px
				display: block
				border-radius: 20px
				background-color: var(--neutral-200)
				margin-bottom: 15px
			&::after
				content: ""
				display: block
				width: 100%
				height: 10px
				border-radius: var(--bdrs)
				background-color: var(--neutral-50)

.campaign .camp-skeleton
	border: var(--bd)
	border-radius: var(--bdrs)
	padding: 14px
	min-height: 109px

.campaigns-ph-sorting
	height: 42px
	align-items: center
	div
		display: block
		width: 55px
		height: 17px
		background-color: var(--neutral-100)
		border-radius: 20px
</style>
