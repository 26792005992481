export default {
	state: {
		tourName: null,
	},
	mutations: {
		setGuidingTour(state, tourName) {
			state.tourName = tourName;
		},
	},
	actions: {
		updateGuidingTour({ commit }, tourName) {
			commit("setGuidingTour", tourName);
		},
	},
	getters: {
		currentGuidingTour: (state) => state.tourName,
		isNewUiGuidingTour: (state) => state.tourName === "NEW_UI_GUIDE",
	},
};
