<template>
	<li
		v-click-outside="closeChipEditor"
		v-tooltip="chip.tooltip"
		class="chip"
		:class="{
			'chip--edit': chipEditor,
			'chip--inert' : chip.disabled
		}"
	>
		<button
			v-if="chipEditor"
			class="chip__circle-btn"
			title="Save"
			@click="updateChip()"
		>
			<Icon icon="circled-check" />
		</button>

		<button
			v-else-if="!chip.disabled"
			class="chip__circle-btn"
			title="Remove"
			@click="removeChip"
		>
			<Icon icon="circled-cross" />
		</button>

		<input
			v-if="chipEditor"
			v-model.trim="inputText"
			type="text"
			enterkeyhint="done"
			:style="{ width: `${inputText.length}ch` }"
			class="chip__input"
			@keydown.enter="updateChip()"
		>
		<component
			:is="!chip.disabled ? 'button' : 'span'"
			v-else
			aria-label="Edit chip"
			:title="chip.text"
			class="chip__content-button"
			@click="!chip.disabled ? openChipEditor() : null"
		>
			{{ chip.text }}
		</component>
	</li>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
	name: "Chip",
	directives: { ClickOutside },
	model: {
		prop: "chipsArr",
		event: "input"
	},
	props: {
		chip: { type: Object, required: true },
	},
	data: () => ({
		chipEditor: false,
		inputText: ""
	}),
	methods: {
		openChipEditor() {
			setTimeout(() => {
				this.inputText = this.chip.text;
				this.chipEditor = true;
				setTimeout(() => {
					const input = this.$el.querySelector(".chip__input");
					if (input) input.focus();
				}, 50);
			}, 50);
		},
		closeChipEditor() {
			if (this.chipEditor) {
				this.chipEditor = false;
				this.inputText = "";
			}
		},
		updateChip() {
			if (this.inputText) {
				this.$emit("updateChip", this.inputText);
				this.closeChipEditor();
			} else {
				this.removeChip();
			}
		},
		removeChip() {
			this.$emit("removeChip");
		}
	}
};
</script>

<style lang="sass">
.chip
	--chip-offset-x: 8px
	--chip-offset-y: 4px
	height: 32px
	display: flex
	flex-direction: row-reverse
	align-items: center
	gap: 10px
	background-color: var(--neutral-100)
	border: 1px solid var(--neutral-100)
	padding-right: var(--chip-offset-x)
	border-radius: var(--bdrs-S)
	position: relative
	max-width: 300px
	&--
		&edit
			border-color: var(--main-primary)
		&inert
			background-color: var(--neutral-200)
			border-color:  var(--neutral-200)
	button.chip__content-button:hover
		color: var(--main-primary)
	&:focus-within
		border-color: var(--main-primary)
	&__
		&content-button
			padding: var(--chip-offset-y) 0 var(--chip-offset-y) var(--chip-offset-x)
			white-space: nowrap
			overflow: hidden
			text-overflow: ellipsis
			max-width: 100%
			color: var(--neutral-900)
			&:focus-visible
				box-shadow: none
		&input
			padding: var(--chip-offset-y) 0 var(--chip-offset-y) var(--chip-offset-x)
			max-width: 250px
			min-width: 40px
		&circle-btn
			height: 16px
			width: 16px
			border-radius: 50%
			display: flex
			align-items: center
			justify-content: center
			flex-shrink: 0
			svg
				cursor: pointer
</style>
