import eventBus from "@/event-bus";

export default {
	components: {
		ListItemsViewManager: () =>
			window.compLoader(import("@/js/components/ListsComponents/ListItemsViewManager")),
		BulkActionManager: () => window.compLoader(import("@/js/components/ListsComponents/BulkActionManager"))
	},
	data: () => ({
		page: 1,
		pageSize: 10,
		pageCount: 1,
		selectAll: false,
		haveItemsInList: false
	}),
	computed: {
		selectedArr() {
			return this.items.filter((item) => item.selected);
		},
		selectedItemsLength() {
			return this.selectedArr.length;
		},
		pageNumber() {
			const { page } = this.$route.query;
			return page ? +page : 1;
		}
	},
	methods: {
		async getListItems(action, page, payload = {}) {
			try {
				eventBus.$emit("paginationSpinnerStatusChange", true);
				this.selectAll = false;
				this.page = page;
				const options = {
					page: page - 1,
					size: this.pageSize
				};
				const res = await this.$store.dispatch(action, { ...options, ...payload });
				this.pageCount = res.totalPages;
				return res;
			} finally {
				eventBus.$emit("paginationSpinnerStatusChange", false);
			}
		},
		itemCheck() {
			this.selectAll = this.items.every((item) => item.selected === true);
		},
		clearSelections() {
			this.items.forEach((item) => {
				item.selected = false;
			});
			this.selectAll = false;
		}
	},
	watch: {
		selectAll(newVal) {
			const allCheckboxSelected = this.items.every((item) => item.selected === true);
			if (newVal || (!newVal && allCheckboxSelected)) {
				this.items.forEach((item) => {
					item.selected = newVal;
				});
			}
		}
	}
};
