export default {
	state: {
		errMessage: null
	},
	getters: {
		errMessage: (s) => s.errMessage
	},
	mutations: {
		setRequestErrorMessage(state, msg) {
			if (msg === "CAPTCHA_IN_PROGRESS") {
				state.errMessage = "Please hold on a few minutes, we're verifying your account!";
			} else {
				state.errMessage = msg;
			}
		}
	}
};
