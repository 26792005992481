<template>
	<div class="trigger">
		<input
			:id="checkName"
			class="trigger__input"
			type="checkbox"
			:name="checkName"
			:checked="checked"
			:disabled="disabled"
			aria-label="Trigger"
			@change="$emit('change', $event.target.checked)"
		>
		<label
			class="trigger__action"
			:for="checkName"
		>
			<span
				v-if="label"
				class="trigger__label"
			>
				{{ label }}
			</span>
		</label>
	</div>
</template>

<script>
export default {
	name: "Trigger",
	model: {
		prop: "checked",
		event: "change"
	},
	props: {
		value: { type: [String, Boolean], default: `trigger${Date.now()}` },
		checkName: { type: String, required: true },
		label: { type: String, default: "" },
		checked: { type: Boolean, required: false },
		disabled: { type: Boolean, default: false },
	}
};
</script>

<style lang="sass">
.trigger
	$this: &
	padding-bottom: calc(var(--size) + (var(--trigger-offset-y) * 2))
	width: var(--w)
	position: relative
	--size: 24px
	--trigger-offset-y: 4px
	--w: 64px
	&--
		&disabled
			cursor: not-allowed
			#{$this}__action
				cursor: not-allowed
		&M
			--size: 14px
			--trigger-offset-y: 3px
			--w: 40px
	&__
		&input
			position: absolute
			z-index: -999
			width: 1px
			height: 1px
			overflow: hidden
			left: calc(var(--w) / 2)
			top: 12px
			&:checked + #{$this}__action
				--bg: var(--main-green)
				&::after
					transform: translateX(calc(var(--w) - var(--size) - var(--trigger-offset-y) * 2))
			&:focus-visible + #{$this}__action::before
				+focus-visible()
			&:disabled  + #{$this}__action
				--bg: var(--neutral-200)
				cursor: not-allowed
				&::after
					background-color: rgba(var(--light-color--rgb), .4)
					box-shadow: none
		&action
			--bg: var(--neutral-300)
			cursor: pointer
			user-select: none
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			&::before
				content: ''
				background-color: var(--bg)
				width: var(--w)
				display: block
				height: calc(var(--size) + var(--trigger-offset-y) * 2)
				border-radius: 32px
				position: absolute
				bottom: 0
				left: 0
				transition: background-color var(--transition)
			&::after
				content: ''
				position: absolute
				width: var(--size)
				height: var(--size)
				left: var(--trigger-offset-y)
				bottom: var(--trigger-offset-y)
				background: var(--light-color)
				border-radius: 50%
				box-shadow: 0px 1px 5px -2px rgba(var(--dark-color--rgb), .9)
				transition: transform var(--transition), background-color var(--transition)
</style>
