var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time-picker",style:(_vm.inputWidthStyle),attrs:{"aria-label":"Timepicker"}},[_c('input',{ref:"input",staticClass:"display-time",class:[
			_vm.inputClass,
			{
				'is-empty': _vm.inputIsEmpty,
				invalid: _vm.hasInvalidInput,
				'all-selected': _vm.allValueSelected,
				disabled: _vm.disabled,
				focused: _vm.isFocusing
			}
		],style:(_vm.inputWidthStyle),attrs:{"id":_vm.id,"type":"text","name":_vm.name,"placeholder":_vm.placeholder ? _vm.placeholder : _vm.formatString,"tabindex":_vm.disabled ? -1 : _vm.tabindex,"disabled":_vm.disabled,"readonly":!_vm.manualInput,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.inputIsEmpty ? null : _vm.customDisplayTime},on:{"focus":_vm.onFocus,"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.openDatePicker.apply(null, arguments)},"change":_vm.onChange,"blur":function($event){_vm.debounceBlur();
			_vm.blurEvent();},"mousedown":_vm.onMouseDown,"keydown":[_vm.keyDownHandler,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.escBlur.apply(null, arguments)}],"compositionstart":_vm.onCompostionStart,"compositionend":_vm.onCompostionEnd,"paste":_vm.pasteHandler}}),(_vm.showClearBtn || _vm.opts.hideDropdown)?_c('div',{staticClass:"controls"},[(!_vm.isActive && _vm.showClearBtn)?_c('span',{staticClass:"clear-btn",on:{"click":_vm.clearTime}},[_vm._v("×")]):_vm._e(),(_vm.opts.hideDropdown && _vm.isActive && !_vm.showDropdown)?_c('span',{staticClass:"show-dropdown-btn",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.setDropdownState(true, true)},"mousedown":_vm.keepFocusing}},[_vm._v("▾")]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDropdown),expression:"showDropdown"}],staticClass:"dropdown",class:{ 'dropdown--right-align': _vm.alignRight },style:(_vm.inputWidthStyle),on:{"mouseup":_vm.keepFocusing,"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"dropdown__head"},[_c('p',{staticClass:"dropdown__title"},[_vm._v("Choose time")]),_c('ul',{staticClass:"apms"},[_vm._l((_vm.apms),function(a,aIndex){return [(
							!_vm.opts.hideDisabledHours || (_vm.opts.hideDisabledHours && !_vm.isDisabled('apm', a))
						)?_c('li',{key:aIndex,class:{ active: _vm.apm === a },attrs:{"tabindex":_vm.isDisabled('apm', a) ? -1 : _vm.tabindex,"data-key":a,"disabled":_vm.isDisabled('apm', a)},domProps:{"textContent":_vm._s(_vm.apmDisplayText(a))},on:{"click":function($event){return _vm.select('apm', a)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.select('apm', a)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.select('apm', a)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;$event.preventDefault();return _vm.prevItem('apm', a)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;$event.preventDefault();return _vm.nextItem('apm', a)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.debounceBlur.apply(null, arguments)}],"blur":_vm.debounceBlur,"focus":_vm.keepFocusing}}):_vm._e()]})],2)]),_c('div',{staticClass:"select-list",style:(_vm.inputWidthStyle)},[(_vm.advancedKeyboard)?[_vm._l((_vm.columnsSequence),function(column){return [(column === 'hour')?_c('ul',{key:column,staticClass:"hours",on:{"scroll":_vm.keepFocusing}},[_vm._l((_vm.hours),function(hr,hIndex){return [(
									!_vm.opts.hideDisabledHours || (_vm.opts.hideDisabledHours && !_vm.isDisabled('hour', hr))
								)?_c('li',{key:hIndex,class:{ active: _vm.hour === hr },attrs:{"tabindex":_vm.isDisabled('hour', hr) ? -1 : _vm.tabindex,"data-key":hr,"disabled":_vm.isDisabled('hour', hr)},domProps:{"textContent":_vm._s(hr)},on:{"click":function($event){return _vm.select('hour', hr)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.select('hour', hr)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.select('hour', hr)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;$event.preventDefault();return _vm.prevItem('hour', hr)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;$event.preventDefault();return _vm.nextItem('hour', hr)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.debounceBlur.apply(null, arguments)}],"blur":_vm.debounceBlur,"focus":_vm.keepFocusing}}):_vm._e()]})],2):_vm._e()]})]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }