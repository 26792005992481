<template>
	<div>
		<WellDone v-if="done" />

		<div
			v-else
			class="card card--no-offset camp-create"
		>
			<Preloader v-if="preloader" />
			<ol
				aria-label="Campaign creation steps"
				class="camp-create__steps"
				:style="`--progress: ${step / steps.length * 100}%`"
			>
				<li
					v-for="(stp, index) in steps"
					:key="index"
					class="body_S"
					:class="{'base': step >= index + 1}"
				>
					{{ stp }}
				</li>
			</ol>
			<transition
				:name="transitionMode"
				mode="out-in"
			>
				<keep-alive>
					<component
						:is="'Step' + step"
						v-if="loadingComplete && draft"
						:campaign="draft"
						@change="addChangesInDraft"
						@changeStep="onStepChange"
						@packsCountChanged="(val) => (havePacks = !!val)"
						@onSequenceChange="(val) => (draftSequence = val)"
						@save="onSave"
					/>
				</keep-alive>
			</transition>



			<transition name="fade">
				<Popup
					v-if="stopPopup"
					aria-label="Save campaign before leave (Popup)"
					:closeble="!failedUserStatus($store.getters.userStatus)"
					@close="closePopup"
				>
					<template #header>
						<span>Save changes</span>
					</template>
					<div class="modal__head">
						<div class="alert-icon alert-icon--accent">
							<icon icon="info" />
						</div>
						<h4 class="h6">Save changes</h4>
					</div>
					<p class="body_M">
						Would you like to save this campaign as
						{{ campaignHasAllRequirements ? "non-active" : "a draft" }}?
					</p>

					<div class="modal__footer">
						<AsyncButton
							:async-f="deletaDraftAndLeavePage"
							class="btn"
						>
							No
						</AsyncButton>
						<AsyncButton
							:async-f="openSavingPopup"
							class="btn btn--base"
						>
							Save
						</AsyncButton>
					</div>
				</Popup>
			</transition>

			<transition name="fade">
				<Popup
					v-if="savingPopup"
					aria-label="Save draft (Popup)"
					@close="closePopup"
				>
					<div class="modal__head">
						<div class="alert-icon">
							<icon icon="info" />
						</div>
						<h4 class="h6">Give your {{ campaignHasAllRequirements ? "campaign" : "draft" }} a name</h4>
					</div>

					<ValidationObserver v-slot="{ passes, invalid }">
						<form @submit.prevent="passes(updateDraft)">
							<TextInput
								v-model="draftName"
								input-name="draftName"
								validation="required"
								selected
							/>
							<footer class="modal__footer">
								<button
									class="btn btn--base btn--full-w"
									type="submit"
									:disabled="invalid || loading"
								>
									<Spinner v-if="loading" />
									<span v-else>Save & continue</span>
								</button>
							</footer>
						</form>
					</ValidationObserver>
				</Popup>
			</transition>
		</div>
	</div>
</template>

<script>
import Step1 from "@/js/views/Campaigns/CampaignCreating/Step1";
import adminAccountController from "@/js/helpers/adminAccountController";
import checkUsersCampaigns from "@/js/mixins/checkUsersCampaigns.mixin";
import unavailableFunctionalityTooltip from "@/js/mixins/unavailableFunctionalityTooltip.mixin";
import { failedUserStatus } from "@/js/helpers/failedUserStatus";

export default {
	name: "CampaingCreate",
	metaInfo: {
		title: "Create a campaign"
	},
	components: {
		Step1,
		Step2: () => window.compLoader(import("@/js/views/Campaigns/CampaignCreating/Step2")),
		Step3: () => window.compLoader(import("@/js/views/Campaigns/CampaignCreating/Step3")),
		WellDone: () => window.compLoader(import("@/js/components/Campaign/WellDone"))
	},
	mixins: [checkUsersCampaigns, unavailableFunctionalityTooltip],
	async beforeRouteLeave(to, _, next) {
		if (!this.saving && !this.campaignCreatingDisable) {
			try {
				this.preloader = true;
				const savedDraftName = this.draft.name;
				await this.$store.dispatch("saveSequence", {
					campaignId: this.draft.id,
					sequenceItemRequest: this.draftSequence
				});
				this.draft = await this.$store.dispatch("getCampaignById", this.draft.id);
				this.draft.name = savedDraftName;
				this.saving = true;
				this.leavingPath = to.path;
				this.stopPopup = true;
			} finally {
				this.preloader = false;
			}
			next(false);
			return;
		}
		next();
	},
	data: () => ({
		step: 1,
		stopPopup: false,
		savingPopup: false,
		saving: false,
		transitionMode: "next",
		leavingPath: null,
		loading: false,
		done: false,
		steps: ["Add Leads", "Create a Sequence", "Settings"],
		loadingComplete: false,
		draft: null,
		draftName: "",
		preloader: false,
		havePacks: false,
		draftSequence: null
	}),
	computed: {
		campaignId() {
			return this.campaign ? this.campaign.id : null;
		},
		campaignHasAllRequirements() {
			return this.havePacks && this.draft.sequenceCompleted;
		}
	},
	async created() {
		try {
			await this.checkUsersCampaigns();
			if (this.campaignCreatingDisable) {
				this.$toast(this.textForCampaignCreation, "danger");
				this.$router.push(this.$store.getters.basicPlanDeprecateCreatingCampaigns ? "/upgrade" : "/dashboard");
			} else {
				await this.createDraft();
				window.addEventListener("beforeunload", this.tabLeavingHandler);
			}
		} finally {
			this.loadingComplete = true;
		}
	},
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.tabLeavingHandler);
	},
	methods: {
		failedUserStatus,
		async createDraft() {
			this.draft = await this.$store.dispatch("createCampaign", {
				name: this.createCampaignNameBasedTime(),
				type: "CUSTOM_CAMPAIGN",
				status: "DRAFT",
				zapierUrl: "",
				skipLeadConditions: ["CAMPAIGN_DUPLICATE"]
			});
			this.draft.name = "";
			this.checkUsersCampaigns();
		},
		addChangesInDraft(options) {
			Object.keys(options).forEach((key) => {
				if (options[key] || key === "name") this.draft[key] = options[key];
			});
		},
		onStepChange(stepDirection) {
			this.transitionMode = stepDirection;
			if (stepDirection === "next") this.step += 1;
			else this.step -= 1;
		},
		createCampaignNameBasedTime() {
			return this.$dd_mm_yyyy() + " " + this.$hh_mm();
		},
		openSavingPopup() {
			if (this.campaignHasAllRequirements && this.draft.name) {
				this.updateDraft();
			} else {
				this.draftName = this.draft.name || this.createCampaignNameBasedTime();
				this.stopPopup = false;
				this.savingPopup = true;
			}
		},
		async updateDraft() {
			try {
				this.loading = true;
				this.draft.status = this.campaignHasAllRequirements ? "NOT_ACTIVE" : "DRAFT";
				if (this.draftName) this.draft.name = this.draftName;
				await this.$store.dispatch("updateCampaign", this.draft);
				this.$toast(`Your campaign has been successfully saved ${!this.campaignHasAllRequirements ? "as draft" : ""}`);
				this.leavePage();
			} finally {
				this.loading = false;
			}
		},
		closePopup() {
			this.stopPopup = false;
			this.savingPopup = false;
			this.saving = false;
		},
		async deletaDraftAndLeavePage() {
			await this.$store.dispatch("deleteCampaign", this.draft.id);
			this.leavePage();
		},
		leavePage() {
			this.stopPopup = false;
			this.savingPopup = false;
			setTimeout(() => {
				this.$router.push(this.leavingPath);
			}, 300);
		},
		tabLeavingHandler(event) {
			if (!adminAccountController.devMode()) {
				event.preventDefault();
				return (event.returnValue = "Would you like to save this campaign before leaving?");
			}
		},
		onSave() {
			this.saving = true;
			this.done = true;
		}
	}
};
</script>

<style lang="sass">
.camp-create
	min-height: 100%
	display: grid
	grid-template-rows: min-content 1fr min-content
	gap: 24px
	&__
		&steps
			display: grid
			grid-template-columns: repeat(3, 1fr)
			margin-top: calc(var(--card-offset-y) * -1)
			margin-left: calc(var(--card-offset-x) * -1)
			width: calc(100% + var(--card-offset-x) * 2)
			padding: 25px var(--card-offset-x) 14px
			position: relative
			border-bottom: var(--bd)
			&::before
				content: ""
				position: absolute
				top: 100%
				left: 0
				width: var(--progress, 0)
				height: 1px
				background-color: var(--main-primary)
				transition: width var(--transition)
		&view
			display: flex
			flex-direction: column
</style>
