export default {
	state: {
		notifications: null
	},
	getters: {
		notifications: (s) => (s.notifications ? s.notifications.content : [])
	},
	mutations: {
		setNotifications(state, payload) {
			state.notifications = payload;
			// state.notifications = {
			// 	"content": [
			// 		{
			// 			"type": "SEARCH_SESSION_IS_TAKEN",
			// 			"id": 1231231,
			// 			"properties": {
			// 				"campaign": {
			// 					"id": 1,
			// 					"name": "CAMP"
			// 				},
			// 				"search": {
			// 					"id": 2,
			// 					"name": "SEARCH"
			// 				}
			// 			}
			// 		},
			// 		{
			// 			"type": "SEARCH_MULTIPLE_RECRUITER_SESSIONS",
			// 			"id": 12333333,
			// 			"properties": {
			// 				"campaign": {
			// 					"id": 1,
			// 					"name": "CAMP"
			// 				},
			// 				"search": {
			// 					"id": 2,
			// 					"name": "SEARCH"
			// 				}
			// 			}
			// 		},
			// 		{
			// 			"type": "SEARCH_WRONG_CONTRACT",
			// 			"id": 44,
			// 			"properties": {
			// 				"campaign": {
			// 					"id": 1,
			// 					"name": "CAMP"
			// 				},
			// 				"search": {
			// 					"id": 2,
			// 					"name": "SEARCH"
			// 				}
			// 			}
			// 		},
			// 	]
			// };
		},
		removeNotificationFormList(state, index) {
			state.notifications.content.splice(index, 1);
		}
	},
	actions: {
		async getNotifications(store) {
			try {
				const res = await window.request("GET", "/v1/shared/notifications/current");

				store.commit("setNotifications", res.data);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async closeNotification(store, id) {
			try {
				await window.request("PATCH", `/v1/shared/notifications/${id}`, {
					closed: true
				});
				store.commit("removeNotificationFormList", store.getters.notifications.findIndex(n => n.id === id))
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async changeSequenceRestriction(store, { id, payload }) {
			try {
				await window.request("PATCH", `v1/sequencerestrictions/${id}`, payload);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		}
	}
};
