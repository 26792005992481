<template>
	<div
		class="preloader"
		:class="{
			'preloader--bg': bg,
			'preloader--static': staticPosition
		}"
		:style="{ 'background-color': bgc }"
	>
		<lottie-player
			ref="lottie"
			class="preloader__animation"
			background="transparent"
			autoplay
			loop
		/>
	</div>
</template>

<script>
import "@lottiefiles/lottie-player";
import animationData from "~/assets/preloader.json";

export default {
	name: "Preloader",
	props: {
		bg: { type: [Boolean, String], default: false },
		staticPosition: { type: Boolean, default: false }
	},

	computed: {
		bgc() {
			return typeof this.bg === "string" ? this.bg : null;
		}
	},

	mounted() {
		this.$nextTick(
			() => {
				this.$refs.lottie.load(animationData);
			}
		);
	}
};
</script>

<style lang="sass">
.preloader
	position: absolute
	z-index: 2
	height: 100%
	width: 100%
	overflow: hidden
	top: 0
	left: 0
	display: flex
	align-items: center
	justify-content: center
	&--
		&bg
			background-color: rgba(var(--neutral-400--rgb), .5)
			@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)
				-webkit-backdrop-filter: blur(2px)
				backdrop-filter: blur(2px)
		&static
			position: static
			height: 300px
	&__
		&animation
			width: 300px
</style>
