export function getCookieByName(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(";").shift();
}

export function setCookie(name, value, days, path="/") {
	let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		cookieString += `; expires=${date.toUTCString()}`;
	}

	if (path) {
		cookieString += `; path=${path}`;
	}

	cookieString += `; domain=${document.location.host.includes("localhost") ? "localhost" : ".dripify.io"}`;

	document.cookie = cookieString;
}

export function deleteCookie(name) {
	document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
