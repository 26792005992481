
export default {
	actions: {
		async updateCurrentUserMarketingInfo(store, payload) {
			try {
				await window.request("PUT", "/v1/shared/marketing/contacts/current", payload);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		}
	}
};
