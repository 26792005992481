import { WEEK_DAYS_NAMES_LIST } from "@/js/helpers/constants/constants";
export default {
	methods: {
		checkForSleepMode(schedule) {
			const date = this.$correctDate();
			const dayName = WEEK_DAYS_NAMES_LIST[date.getDay()];
			const today = schedule.find((item) => item.dayOfWeek === dayName);
			const time = date.getHours();
			const isSleeping = today.active ? time < today.fromHour || time >= today.toHour : true;
			this.$store.commit("changeSleepMode", isSleeping);
		}
	}
};
