<template>
	<section>
		<div class="head">
			<h3 class="">
				{{ newTemplate ? "Create a sequence template" : "Edit your sequence template:" }}
			</h3>
		</div>

		<div class="template-edit">
			<div
				v-if="preload"
				class="template-edit__preload"
			>
				<Spinner />
			</div>
			<template v-else>
				<label
					for="template-name"
					class="field__label"
				>Give your sequence template a name</label>

				<div class="template-edit__head">
					<TextInput
						v-model="templateName"
						input-name="template-name"
						ph="Enter the name here"
						validation="required"
						inline
						focused
					/>

					<TemplateTeamCheckbox
						v-if="canSaveTemplateInTeamList"
						v-model="saveForTeam"
					/>
				</div>

				<div class="card__hr" />
				<Flowchart
					:sequence="sequenceObject"
					template-editing
					@changeObject="onSequenceChange"
					@sequencePopupOpened="(val) => (sequencePopupOpened = val)"
				/>

				<div class="card__hr" />

				<div class="flex-center default-gap">
					<button
						class="tertiary-btn"
						@click="cancelClickHandler"
					>
						Cancel
					</button>

					<AsyncButton
						id="updateTemplateBtn"
						:async-f="sendTemplate"
						:loading="spinner"
						:disabled="sequencePopupOpened || !templateName || spinner || !updatedSequence"
						class="btn btn--base"
					>
						{{ newTemplate ? "Save this template" : "Update template" }}
					</AsyncButton>
				</div>
			</template>
		</div>
		<transition name="fade">
			<SettingsLeavingPopup
				v-if="leavingPopup"
				:saving-function="sendTemplate"
				:leaving-path="leavingPath"
				@close="beforeClosingLeavingPopup"
			/>
		</transition>
	</section>
</template>

<script>
import Sequence from "@/js/mixins/sequence.mixin";
import roleInTeam from "@/js/mixins/roleInTeam.mixin";
import savedSequenceTemplates from "@/js/mixins/savedSequenceTemplates.mixin";
import settingsLeavingHandler from "@/js/mixins/settingsLeavingHandler.mixin";

function deepEqual(obj1, obj2) {
	// Check if both arguments are objects and not null
	if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
		// Get the keys of both objects
		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		// Check if the number of keys is the same
		if (keys1.length !== keys2.length) {
			return false;
		}

		// Check if all keys and values are equal
		for (let key of keys1) {
			if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
				return false;
			}
		}

		return true;
	} else {
		// For primitive types, compare directly
		return obj1 === obj2;
	}
}

export default {
	name: "TemplateEditComponent",
	components: {
		Flowchart: () => window.compLoader(import("@/js/components/Campaign/Flowchart/Flowchart")),
		TemplateTeamCheckbox: () =>
			window.compLoader(import("@/js/components/Campaign/SequenceTemplates/TemplateTeamCheckbox"))
	},
	mixins: [Sequence, roleInTeam, savedSequenceTemplates, settingsLeavingHandler],
	provide() {
		return {
			campaignInfo: {}
		}
	},
	props: {
		id: { type: [Number, Boolean], required: false }
	},
	data: () => ({
		sequenceObject: null,
		templateName: "",
		saveForTeam: false,
		sequencePopupOpened: false,
		preload: true,
		updatedSequence: null,
		spinner: false,
		cancelClicked: false
	}),

	computed: {
		routeId() {
			return this.$route.params.templateId;
		},
		templatePage() {
			return this.$route.name === "template"
		},
		templateId() {
			return this.templatePage ? this.routeId : this.id;
		},
		creatorIsCurrentUser() {
			return this.sequenceObject ? this.sequenceObject.userId === this.$cu().id : true;
		},
		canSaveTemplateInTeamList() {
			return (
				this.creatorIsCurrentUser && this.$store.getters.userInTeam && this.roleInTeam !== "USER"
			);
		},
		newTemplate() {
			return this.routeId === "new" || this.templateId === true;
		},
		treeObjectChanged() {
			let updatedTree = this.updatedSequence ? this.prepareSequenceItem(this.updatedSequence) : null;
			let initialTree = this.sequenceObject ? this.prepareSequenceItem(this.sequenceObject) : null;

			return !deepEqual(updatedTree, initialTree)
		},
		teamTemplate() {
			return this.sequenceObject?.templateType === "GROUP";
		},
		dataChanged() {
			return !this.cancelClicked && ((this.sequenceObject === null && this.treeObjectChanged) || (this.treeObjectChanged
				|| this.templateName !== (this.sequenceObject?.templateName || '')
				|| this.teamTemplate !== this.saveForTeam));
		}
	},
	watch: {
		templateChanged(newVal) {
			this.$emit("templateChanged", newVal)
		}
	},
	async created() {

		try {
			if (this.newTemplate) {
				this.sequenceObject = null;
			} else {
				this.sequenceObject = await this.getSequenceTemplateById(this.templateId);
				this.templateName = this.sequenceObject.templateName;
				this.saveForTeam = this.teamTemplate;
			}
			this.updatedSequence = JSON.parse(JSON.stringify(this.sequenceObject));
		} catch (err) {
			this.goToTemplates();
			throw err;
		} finally {
			this.preload = false;
			this.dataLoaded = true;
		}
	},
	methods: {
		async sendTemplate() {
			try {
				this.spinner = true;
				this.dataLoaded = false;
				const payload = {
					...this.prepareSequenceItem(this.updatedSequence, true),
					templateName: this.templateName,
					templateType: this.saveForTeam ? "GROUP" : "INDIVIDUAL"
				};

				if (this.newTemplate) {
					await this.createTemplate(payload);
				} else {
					await this.updateTemplate(payload);
				}
				this.goToTemplates();
			} catch (err) {
				const error = err.response;
				if (error.status === 422) {
					this.sequenceObject = JSON.parse(JSON.stringify(error.data));
					const actionsWithErrors = this.getErroredSequenceActions(error.data);
					this.collectErrorsToToast(actionsWithErrors, true);
				} else {
					this.$toast(`Error status: ${error.status}`, "danger");
				}
				throw new Error();
			} finally {
				this.spinner = false;
				this.dataLoaded = true;
				this.closeLeavingPopup();
			}
		},
		async createTemplate(payload) {
			const res = await this.$store.dispatch("createSequenceTemplate", payload);
			this.$toast("The new sequence template has been successfully saved");
			return res;
		},
		async updateTemplate(payload) {
			const res = await this.$store.dispatch("updateTemplate", {
				id: this.templateId,
				payload
			});
			this.$toast("Your sequence template has been successfully updated");
			return res;
		},
		cancelClickHandler() {
			this.cancelClicked = true;
			this.goToTemplates();
		},
		beforeClosingLeavingPopup() {
			this.cancelClicked = false;
			this.closeLeavingPopup();
		},
		goToTemplates() {
			if (this.templatePage) {
				this.$router.push({ name: "templates" });
			} else {
				this.$emit("close");
			}
		},
		onSequenceChange(val) {
			this.updatedSequence = val;
		}
	}
};
</script>

<style lang="sass">
.template-edit
	flex: 1
	display: flex
	flex-direction: column
	&__
		&preload
			min-height: 350px
			position: relative
		&head
			display: grid
			grid-template-columns: 1fr 1fr
			gap: 20px
			align-items: center
			margin-bottom: 24px
			@media screen and (max-width: $fablet)
				grid-template-columns: 100%
</style>
