<template>
	<button
		class="async-btn"
		:class="{ 'async-btn--requesting': requesting }"
		:disabled="requesting || disabled"
		@click="clickHandler"
	>
		<slot />
		<component
			:is="inlineSpinner ? 'InlineSpinner' : 'Spinner'"
			v-if="requesting"
			:size="spinnerSize"
			class="async-btn__spinner"
		/>
	</button>
</template>

<script>
export default {
	name: "AsyncButton",
	props: {
		asyncF: { type: Function, required: false },
		disabled: { type: Boolean, default: false },
		inlineSpinner: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		spinnerSize: { type: [String, Number], required: false }
	},
	data: () => ({
		spinner: false
	}),
	computed: {
		requesting() {
			return this.spinner || this.loading;
		}
	},
	watch: {
		spinner(newVal) {
			this.$emit("btnStateChange", newVal);
		}
	},
	methods: {
		async clickHandler() {
			if (this.asyncF) {
				try {
					this.spinner = true;
					await this.asyncF();
				} finally {
					this.spinner = false;
				}
			}
		}
	}
};
</script>

<style lang="sass">
.async-btn.btn
	position: relative
	&.async-btn--requesting
		color: rgba(0, 0, 0, 0) !important
		& > *:not(.async-btn__spinner)
			opacity: 0 !important
</style>
