import store from "./store";
import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import { verifyEmail } from "@/js/helpers/verifyEmail";
import { emailRecovery } from "@/js/helpers/emailRecovery";
import { formattingParams } from "@/js/helpers/paramsFormatter";
import adminAccountController from "@/js/helpers/adminAccountController";
// Because we need this components to have access to in-component router-guards - https://medium.com/bauer-kirch/how-to-make-vue-router-play-nice-with-loading-states-3f2ff6bfd633#ba0b
import CampaignCreate from "./views/Campaigns/CampaignCreating/CampaignCreate.vue";
import Campaign from "./views/Campaigns/Campaign.vue";
import Limits from "./views/Settings/Limits.vue";
import TemplateEditComponent from "./components/Campaign/SequenceTemplates/TemplateEditComponent.vue";
import Scheduler from "./views/Settings/Scheduler.vue";
import Scrubber from "./views/Settings/Scrubber.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "login",
			component: () =>
				window.compLoader(import(/* webpackPrefetch: true */ "./views/Auth/Login.vue"), true),
			meta: { layout: "auth" }
		},
		{
			path: "/signup",
			name: "signup",
			component: () => window.compLoader(import("./views/Auth/SignUp.vue"), true),
			meta: { layout: "auth" }
		},
		{
			path: "/forgot-password",
			name: "forgot-password",
			component: () => window.compLoader(import("./views/Auth/ForgetPassword.vue"), true),
			meta: { layout: "auth" }
		},
		{
			path: "/password-reset",
			name: "password-reset",
			component: () => window.compLoader(import("./views/Auth/PasswordReset.vue"), true),
			meta: { layout: "auth" },
			beforeEnter: (to, from, next) => {
				if (!to.query.oobCode && to.query.mode !== "resetPassword") next("/");
				else next();
			}
		},
		{
			path: "/dashboard",
			name: "dashboard",
			component: () =>
				window.compLoader(import(/* webpackPrefetch: true */ "./views/Dashboard.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/dashboard/statistics",
			name: "statistics",
			component: () => window.compLoader(import("./views/AccountStatistics.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/quick-guide",
			name: "onboarding",
			component: () => window.compLoader(import("./views/OnboardingPage.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/campaigns",
			name: "campaigns",
			component: () => window.compLoader(import("./views/Campaigns/Campaigns.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/new-campaign",
			name: "campaign-create",
			component: CampaignCreate,
			meta: { layout: "default" },
		},
		{
			path: "/campaigns/:campaignId",
			component: Campaign,
			meta: { layout: "default" },
			children: [
				{
					path: "/campaigns/:campaignId/",
					name: "campaign",
					component: () =>
						window.compLoader(import("./views/Campaigns/CampaignTabs/CampaignAudience.vue"), true),
					meta: { layout: "default" }
				},
				{
					path: "/campaigns/:campaignId/sequence",
					name: "campaign-sequence",
					component: () =>
						window.compLoader(
							import("./views/Campaigns/CampaignTabs/SequenceInCampaign.vue"),
							true
						),
					meta: { layout: "default" }
				},
				{
					path: "/campaigns/:campaignId/statistics",
					name: "campaign-statistics",
					component: () =>
						window.compLoader(
							import("./views/Campaigns/CampaignTabs/CampaignStatistics.vue"),
							true
						),
					meta: { layout: "default" }
				},
				{
					path: "/campaigns/:campaignId/settings",
					name: "campaign-settings",
					component: () =>
						window.compLoader(
							import("./views/Campaigns/CampaignTabs/SettingsInCampaign.vue"),
							true
						),
					meta: { layout: "default" }
				}
			]
		},
		{
			path: "/campaigns/:campaignId/leads",
			name: "leads-in-campaign",
			component: () => window.compLoader(import("./views/Campaigns/LeadsInCampaign.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/inbox",
			name: "inbox",
			component: () => window.compLoader(import("./views/Inbox.vue"), true),
			meta: { layout: "default" },
			children: [
				{
					path: "/inbox/:conversationId",
					name: "conversation",
					component: () => window.compLoader(import("./components/Inbox/Conversation.vue"), true),
					meta: { layout: "default" }
				}
			],
		},
		{
			path: "/leads",
			name: "leads",
			component: () => window.compLoader(import("./views/Leads/Leads.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/leads/:leadId",
			name: "lead",
			component: () => window.compLoader(import("./views/Leads/Lead.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/team",
			name: "team",
			component: () => window.compLoader(import("./views/Team/Team.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/team/invitations",
			name: "invitations",
			component: () => window.compLoader(import("./views/Team/Invitations.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/team/:userId",
			name: "teammate",
			component: () => window.compLoader(import("./views/Team/Teammate.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/upgrade",
			name: "plans",
			component: () => window.compLoader(import("./views/Upgrade/Upgrade.vue"), true),
			meta: { layout: "default" },
			children: [
				{
					path: "/upgrade/email-finder-pricing",
					name: "email-finder-pricing",
					meta: { layout: "default" }
				}
			]
		},
		{
			path: "/settings",
			component: () => window.compLoader(import("./views/Settings/Settings.vue"), true),
			meta: { layout: "default" },
			children: [
				{
					path: "/",
					name: "limits",
					component: Limits,
					meta: { layout: "default" }
				},
				{
					path: "/settings/working-hours",
					name: "scheduler",
					component: Scheduler,
					meta: { layout: "default" }
				},
				{
					path: "/settings/sequence-templates",
					name: "templates",
					component: () => window.compLoader(import("./views/Settings/SequenceTemplates.vue"), true),
					meta: { layout: "default" }
				},
				{
					path: "/settings/sequence-templates/:templateId",
					name: "template",
					component: TemplateEditComponent,
					meta: { layout: "default" }
				},
				{
					path: "/settings/data-scrubber",
					name: "data-scrubber",
					component: Scrubber,
					meta: { layout: "default" }
				}
			]
		},
		{
			path: "/account",
			component: () => window.compLoader(import("./views/AccountAndBilling/AccountAndBilling.vue"), true),
			meta: { layout: "default" },
			children: [
				{
					path: "/",
					name: "account",
					component: () => window.compLoader(import("./views/AccountAndBilling/Account.vue"), true),
					meta: { layout: "default" }
				},

				{
					path: "/account/subscriptions",
					name: "subscriptions",
					component: () => window.compLoader(import("./views/AccountAndBilling/Subscriptions.vue"), true),
					meta: { layout: "default" }
				},
				{
					path: "/account/transactions",
					name: "transactions",
					component: () => window.compLoader(import("./views/AccountAndBilling/Transactions.vue"), true),
					meta: { layout: "default" }
				},
			]
		},
		{
			path: "/enter-linkedin",
			name: "enter-linkedin",
			component: () => window.compLoader(import("./views/Auth/EnterLinkedin.vue"), true),
			meta: { layout: "auth" }
		},
		{
			path: "/*/callback",
			name: "email-provider-callback",
			component: () =>
				window.compLoader(import("./components/Settings/EmailIntegration/OAuthCallbackProcess.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "/admin",
			name: "admin",
			component: () => window.compLoader(import("./views/Admin.vue"), true),
			meta: { layout: "default" }
		},
		{
			path: "*",
			name: "404",
			component: () => window.compLoader(import("./views/404Component.vue"), true),
			meta: { layout: "empty" }
		}
	]
});

router.beforeEach(async (to, from, next) => {
	const user = firebase.auth().currentUser;
	// const authLayout = to.meta.layout === "auth";
	const defaultLayout = to.meta.layout === "default";
	const { freeTrialEnds, currentUser, signedInToTeammate } = store.getters;

	if (to.query.mode === "verifyEmail") {
		// Email verification handler.
		await verifyEmail(to.query.oobCode);
	} else if (to.query.mode === "resetPassword" && to.name !== "password-reset") {
		// Display reset password handler and UI.
		next(`/password-reset?${formattingParams(to.query)}`);
	} else if (to.query.mode === "recoverEmail") {
		// Email recovery handler.
		await emailRecovery(to.query.oobCode);
	} else if (defaultLayout && !user) {
		// Redirect to auth-layout from default-layout
		next("/");
	} else if (freeTrialEnds && signedInToTeammate && to.name !== "dashboard") {
		// Staying at dashboard page if teammates account ends of subscription
		next("/dashboard");
	} else if (
		freeTrialEnds &&
		!adminAccountController.isAdmin() &&
		!signedInToTeammate &&
		defaultLayout &&
		currentUser &&
		!isOpenPage(to.name)
	) {
		// Redirect to subscription renew page
		next("/upgrade");
	} else {
		// Default
		next();
	}
});

const openPages = [
	"account",
	"subscriptions",
	"transactions",
	"email-finder-pricing",
	"plans"
];
function isOpenPage(pageName) {
	return openPages.includes(pageName)
}
export default router;
