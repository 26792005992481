<template>
	<div
		class="spinner"
		:class="{ 'spinner--bg': bg }"
	>
		<Icon
			icon="loading"
			:h="size"
			:w="size"
			:color="color"
			style="animation: spin 1s infinite linear"
		/>
	</div>
</template>

<script>
export default {
	name: "Spinner",
	props: {
		bg: { type: Boolean, default: false },
		color: { type: String, required: false },
		size: { type: [String, Number], default: 25 }
	}
};
</script>

<style lang="sass">
.spinner
	position: absolute
	z-index: 2
	inset: 0
	overflow: visible
	display: flex
	align-items: center
	justify-content: center
	cursor: progress
	&--bg
		background-color: var(--neutral-400--rgb, .2)

</style>
