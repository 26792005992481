<template>
	<transition name="fade">
		<Popup
			v-if="componentErrorPopup"
			aria-label="New Update (Popup)"
			@close="closeComponentErrorPopup"
		>
			<div class="component-err">
				<div class="h5 mb">
					Yoo-Hoo!<br>
				</div>

				<p class="body_M">Dripify has been just updated to a newer version. Click the button below to refresh the page</p>

				<footer class="modal__footer modal__footer--full">
					<button
						class="btn btn--auto-w btn--base"
						@click="refreshPage"
					>
						Refresh
					</button>
				</footer>
				<br>
				<p class="body_S">
					P.S. Some user interface elements might not be displayed properly until you refresh the
					page
				</p>
			</div>
		</Popup>
	</transition>
</template>

<script>
export default {
	name: "ComponentError",
	data: () => ({
		componentErrorPopup: true
	}),
	methods: {
		closeComponentErrorPopup() {
			this.componentErrorPopup = false;
		},
		refreshPage() {
			window.multitabChannel.postMessage("refresh"); // multireload (see in App.vue)
			document.location.reload(true);
		}
	}
};
</script>

<style lang="sass">
.component-err
	max-width: 470px
	text-align: center
	color: var(--neutral-900)
</style>
