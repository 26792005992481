import { isCreationDisabledByUserState } from "@/js/helpers/permissionStates";

export default {
	computed: {
		userAccountStatus() {
			return this.$store.getters.userStatus;
		},
		disabledFunctionality() {
			const isLocal = process.env.NODE_ENV === "development" && !window.location.host.includes("staging");

			return this.userAccountStatus !== 'VERIFIED' && !isLocal
		},
		unavailableFunctionalityTooltip() {
			switch (this.userAccountStatus) {
			case "LOGIN_RESTRICTED":
			case "UNAUTHORIZED":
				return "This functionality is not available because you're not connected to LinkedIn";
			case "PIN_CHALLENGED":
			case "TWO_FA_SMS_CHALLENGED":
			case "TWO_FA_AUTHENTICATOR_CHALLENGED":
				return "This functionality is not available because you are not logged in to LinkedIn";
			case "FAILED_CAPTCHA":
			case "CAPTCHA_IN_PROGRESS":
				return "This functionality is not available due to an authentication error on LinkedIn";
			case "REGISTER_PHONE_CHALLENGED":
				return "The functionality is not available due to an authorization error on LinkedIn";
			default:
				return null;
			}
		},
		campaignCreatingDisable() {
			return this.leadsAddingDisabled
				|| this.$store.getters.basicPlanDeprecateCreatingCampaigns
				|| isCreationDisabledByUserState();
		},
		textForCampaignCreation() {
			if (this.$store.getters.basicPlanDeprecateCreatingCampaigns) {
				return "Upgrade to a higher plan to create more than one campaign"
			} else {
				return this.leadsAddingDisabledText
			}
		},
		leadsAddingDisabled() {
			return this.disabledFunctionality || this.$store.getters.subscriptionPaused;
		},
		leadsAddingDisabledText() {
			if (this.$store.getters.subscriptionPaused) {
				return "This functionality is not available because your subscription is paused";
			} else if (this.disabledFunctionality) {
				return this.unavailableFunctionalityTooltip;
			}
			return null;
		},
	}
};
