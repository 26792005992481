<template>
	<section
		class="scrubber"
	>
		<div
			data-guiding-step="scrubber"
			class="scrubber__wrap"
		>
			<Preloader v-if="preloader" />
			<div class="between">
				<h2 class="h6">Data scrubber</h2>
				<a
					href="https://help.dripify.io/en/articles/8193545-remove-unwanted-data-from-leads-profile-names"
					target="_blank"
					class="aic primary link link--rev"
				>

					Learn more&nbsp;
					<Icon
						icon="arrow-link"
						color="var(--main-primary)"
					/>
				</a>
			</div>
			<br>
			<div class="mb">
				<label
					class="body_S neutral-500"
					for="scrubber"
				>
					Add words you would like Dripify to remove from leads' names:
				</label>
			</div>
			<ChipsField
				v-model="scrubberArr"
				label-text="Add words you would like Dripify to remove from leads' names:"
				name="scrubber"
				:regexp-validation="validator"
				:symbols-limit="$options.MAX_SYMBOLS_LIMIT"
			/>
		</div>
		<div
			v-if="dataChanged"
			class="aic mt"
		>
			<button
				class="btn btn--auto-w "
				@click="createChipsArray"
			>
				Cancel
			</button>

			<AsyncButton
				:async-f="saveScrubber"
				class="btn btn--auto-w btn--base"
			>
				Save
			</AsyncButton>
		</div>

		<transition name="fade">
			<SettingsLeavingPopup
				v-if="leavingPopup"
				:saving-function="saveScrubber"
				:leaving-path="leavingPath"
				@close="closeLeavingPopup"
			/>
		</transition>
	</section>
</template>

<script>
import ChipsField from "@/js/components/Form/ChipsField";
import guidingNextStep from "@/js/mixins/guidingNextStep.mixin";
import settingsLeavingHandler from "@/js/mixins/settingsLeavingHandler.mixin";

export default {
	name: "Scrubber",
	components: { ChipsField },
	mixins: [guidingNextStep, settingsLeavingHandler],
	data: () => ({
		preloader: true,
		scrubberContent: {
			personNameWords: [],
			defaultNameWords: []
		},
		scrubberArr: [],
		arrToCompare: []
	}),
	MAX_SYMBOLS_LIMIT: 1024,
	specialSigns: [
		{
			text: "🙂💪",
			tooltip: "Еmojis are always removed from leads’ names",
			disabled: true
		},
		{
			text: ".,?!",
			tooltip: "Symbols are always removed from leads’ names",
			disabled: true
		},
	],
	computed: {
		dataChanged() {
			return JSON.stringify(this.scrubberArr) !== JSON.stringify(this.arrToCompare);
		}
	},
	async created() {
		await this.getScrubberWords();
		this.guidingNextStep();
	},
	methods: {
		async getScrubberWords() {
			try {
				this.preloader = true;
				this.scrubberContent = await this.$store.dispatch("getUserUndesirableWords");
				this.createChipsArray()
			} finally {
				this.preloader = false;
				this.dataLoaded = true;
			}
		},
		createChipsArray() {
			this.scrubberArr = [
				...this.$options.specialSigns,
				...this.scrubberContent.defaultNameWords.map(chip => ({
					text: chip,
					tooltip: "These words are preset and cannot be deleted",
					disabled: true
				})),
				...this.scrubberContent.personNameWords.map(chip => ({ text: chip }))
			];
			this.writeChipsToCompare();
		},
		async saveScrubber() {
			try {
				this.dataLoaded = false;
				const arrayToSave = this.scrubberArr.filter(item => !item.disabled).map(chip => chip.text);
				this.scrubberContent = await this.$store.dispatch("updateUndesirableWords", arrayToSave);
				this.$toast("Data scrubber has been successfully updated");
				this.createChipsArray();
			} finally {
				this.dataLoaded = true;
			}
		},
		writeChipsToCompare() {
			this.arrToCompare = JSON.parse(JSON.stringify(this.scrubberArr));
		},
		validator(val) {
			const VALIDATION_REG_EXP = /^[a-zA-Z0-9-\s]+$/;
			const wordIsValid = VALIDATION_REG_EXP.test(val);

			if (!wordIsValid) this.$toast("The word must contain only letters, numbers, spaces, or a dash");

			return wordIsValid;
		}
	}
};
</script>

<style lang="sass">
.scrubber
	position: relative
	display: flex
	flex-direction: column
	justify-content: space-between
	&__
		&wrap
			margin: -24px calc(var(--card-offset-x) * -1)
			padding: 24px var(--card-offset-x)

</style>
