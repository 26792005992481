var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{ref:"provider",staticClass:"field",class:{
		'field--inline': _vm.inline,
		'field--numeric': _vm.numeric,
		'field--limit': _vm.limit
	},attrs:{"rules":_vm.validation,"name":_vm.inputName,"vid":_vm.vid,"mode":_vm.validationMode,"custom-messages":_vm.customMessages,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, classes, validate }){return [(_vm.labelText)?_c('label',{staticClass:"field__label",attrs:{"for":_vm.inputName}},[_vm._v(" "+_vm._s(_vm.labelText)+" "),(_vm.isRequired)?_c('span',{attrs:{"aria-label":"Required"}},[_vm._v(" *")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"field__input-wrap",class:{
			'field__input-wrap--invalid': classes.invalid,
			'field__input-wrap--disabled': _vm.disabled
		}},[_c('input',{ref:_vm.inputName,staticClass:"field__input",attrs:{"id":_vm.inputName,"name":_vm.inputName,"type":_vm.type,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"inputmode":_vm.inputmode || _vm.computedMode,"pattern":_vm.pattern},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.$emit('change', $event)},"input":function($event){return _vm.onInput($event)},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validateField($event, validate)}}}),(_vm.ph)?_c('label',{staticClass:"field__placeholder",class:{
				'field__placeholder--hidden': _vm.value !== '',
			},attrs:{"for":_vm.inputName}},[_vm._v(" "+_vm._s(_vm.ph)+" "),(_vm.isRequired)?_c('span',{attrs:{"aria-label":"Required"}},[_vm._v(" *")]):_vm._e()]):_vm._e(),(_vm.validation.max && _vm.limit)?_c('div',{staticClass:"field__max"},[_vm._v(" "+_vm._s(_vm.validation.max - _vm.value.length)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.errorText || errors[0])?_c('small',{staticClass:"field__message",domProps:{"innerHTML":_vm._s(_vm.errorText || errors[0])}}):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }