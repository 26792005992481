<template>
	<article
		class="camp-item"
		:class="{
			'camp-item--locked': campaign.locked,
			'camp-item--campaign-page': campaignPage,
		}"
		:aria-label="'Campaign: ' + campaign.name"
	>
		<div
			class="camp-item__grid"
			:class="{
				'camp-item__grid--small': teammatePage,
			}"
		>
			<div
				style="grid-area: main"
				class="camp-item__block"
			>
				<div class="camp-item__main">
					<component
						:is="campaignPage ? 'h1' : clickable ? 'router-link' : 'div'"
						class="camp-item__name body_M"
						:to="url"
						:title="campaign.name"
					>
						{{ campaign.name }}
						<Icon
							v-if="!campaignPage && !teammatePage"
							icon="small-arrow--right"
						/>
					</component>

					<InfoTip
						v-if="tipOptions.visible"
						:stroke="tipOptions.color"
						:tip="tipOptions.text"
					/>
				</div>

				<span
					v-if="isDraft"
					class="camp-item__draft body_S"
				>
					<Icon
						icon="edit-pen-check"
						w="20"
						h="20"
					/>
					Draft
				</span>
				<LeadsIndicators
					v-else
					:statistics="stat"
					:statistics-loading="statisticsLoading"
					:campaign="campaign"
					:disabled="!clickable"
				/>
			</div>

			<div
				v-if="!campaignPage"
				class="camp-item__block camp-item__block--gap"
				style="grid-area: leads"
			>
				<h5 class="caption_S camp-item__block-caption">Leads</h5>
				<CampaignStatisticsDisplaying
					style="grid-area: total"
					:campaign="campaign"
					:clickable="clickable"
					:statistics-loading="statisticsLoading"
					field="totalLeads"
				>
					All leads
				</CampaignStatisticsDisplaying>

				<CampaignStatisticsDisplaying
					:campaign="campaign"
					:clickable="clickable"
					:statistics-loading="statisticsLoading"
					field="leadsListsSize"
				>
					Lists of leads
				</CampaignStatisticsDisplaying>
			</div>

			<div
				style="grid-area: linkedin"
				class="camp-item__block camp-item__block--gap"
			>
				<h5 class="caption_S camp-item__block-caption">LinkedIn</h5>

				<CampaignStatisticsDisplaying
					:campaign="campaign"
					:clickable="clickable"
					:statistics-loading="statisticsLoading"
					field="acceptanceRate"
					count-field="acceptanceRateLeads"
					filter="INVITE_ACCEPTED"
				>
					Acceptance rate
				</CampaignStatisticsDisplaying>

				<CampaignStatisticsDisplaying
					:campaign="campaign"
					:clickable="clickable"
					:statistics-loading="statisticsLoading"
					field="replyRate"
					count-field="replyRateLeads"
					filter="MESSAGE_RESPONDED"
				>
					Response rate
					<InfoTip
						size="16"
						stroke="var(--neutral-400)"
						tip="The number of leads that responded divided by the total number of leads reached via InMail, messages, and connection requests"
					/>
				</CampaignStatisticsDisplaying>
			</div>

			<!-- <div
				style="grid-area: email"
				class="camp-item__block camp-item__block--gap"
			>
				<h5 class="caption_S camp-item__block-caption">Email</h5>

				<CampaignStatisticsDisplaying
					:campaign="campaign"
					:clickable="clickable"
					:statistics-loading="statisticsLoading"
					field="replyRate"
					count-field="acceptanceRateLeads"
					filter="INVITE_ACCEPTED"
				>
					Open rate
				</CampaignStatisticsDisplaying>

				<CampaignStatisticsDisplaying
					:campaign="campaign"
					:clickable="clickable"
					:statistics-loading="statisticsLoading"
					field="replyRate"
					count-field="replyRateLeads"
					filter="MESSAGE_RESPONDED"
				>
					Response rate
				</CampaignStatisticsDisplaying>
			</div> -->


			<div
				style="grid-area: status"
				class="camp-item__block"
			>
				<div class="between">
					<CampaignStatus
						:campaign="campaign"
						:disabled="!enableToChangeStatus"
						:class="{'blinking-item': statisticsLoading}"
					/>

					<button
						class="camp-item__remove"
						title="Remove campaign"
						:disabled="!clickable"
						@click="openRemovingPopup"
					>
						<Icon
							icon="trash"
							stroke="var(--neutral-400)"
						/>
					</button>
				</div>

				<time class="body_S camp-item__time">
					{{ $mmm_dd_yyyy(campaign.createdAt) }}
				</time>
			</div>
		</div>

		<transition name="fade">
			<RemovingPopup
				v-if="removingPopup"
				button-text="Delete campaign"
				:remove-function="removeCampaign"
				@close="closeRemovingPopup"
			>
				Do you really want to remove campaign <b>{{ campaign.name }}</b>?
			</RemovingPopup>
		</transition>
	</article>
</template>

<script>
import defaultRemoving from "@/js/mixins/defaultRemoving.mixin";
import checkUsersCampaigns from "@/js/mixins/checkUsersCampaigns.mixin";
import CampaignStatus from "@/js/components/Campaign/CampaignStatus";
import LeadsIndicators from "@/js/components/Campaign/LeadsIndicators/LeadsIndicators";
import CampaignStatisticsDisplaying from "@/js/components/Campaign/CampaignStatisticsDisplaying";
import { isCreationDisabledByUserState } from "@/js/helpers/permissionStates";

export default {
	name: "CampItem",
	components: { LeadsIndicators, CampaignStatus, CampaignStatisticsDisplaying },
	mixins: [defaultRemoving, checkUsersCampaigns],
	props: {
		campaign: { type: Object, required: true },
		teammatePage: { type: Boolean, default: false },
		clickable: { type: Boolean, default: true },
		lastInList: { type: Boolean, default: false },
		statisticsLoading: { type: Boolean, default: false }
	},
	data: () => ({
		contextSpinner: false
	}),
	computed: {
		campaignPage() {
			return !!this.$route.params.campaignId;
		},
		stat() {
			return this.campaign.statistics;
		},
		url() {
			return !this.campaignPage && this.clickable ? "/campaigns/" + this.campaign.id : null;
		},
		enableToChangeStatus() {
			return (
				!this.campaign.locked &&
				this.clickable &&
				(!this.isDraft ||
					(this.campaign.sequenceCompleted && this.stat && this.stat.leadsListsSize)) &&
				!this.$store.getters.subscriptionPaused
				&& !isCreationDisabledByUserState()
			);
		},
		campStatus() {
			return this.campaign.status;
		},
		isDraft() {
			return this.campaign.status === "DRAFT";
		},
		tipStatus() {
			if (this.stat) {
				const thirtyPercent = Math.floor((this.stat.totalLeads / 100) * 30);
				const gettingReadyLeads = this.stat.gettingReadyLeads;
				const showOrangeTip =
					(gettingReadyLeads < thirtyPercent && gettingReadyLeads < 300) || gettingReadyLeads < 50;
				return !gettingReadyLeads ? "no-leads" : showOrangeTip ? "few-leads" : false;
			} else {
				return false;
			}
		},
		tipOptions() {
			return {
				visible:
					this.stat &&
					!this.stat.searchActive &&
					!this.isDraft &&
					this.campStatus !== "NOT_ACTIVE" &&
					(this.tipStatus === "no-leads" || this.tipStatus === "few-leads"),
				color:
					this.tipStatus === "no-leads" ? "var(--main-red)" : "var(--main-accent)",
				text:
					this.tipStatus === "no-leads"
						? "All leads have entered the sequence. Add more to maintain consistent outreach"
						: "Most leads have entered the sequence. Add more now to keep your campaign running smoothly"
			};
		}
	},
	methods: {
		async removeCampaign() {
			const notLocked = !this.campaign.locked;
			await this.$store.dispatch("deleteCampaign", this.campaign.id);
			if (notLocked) await this.checkUsersCampaigns();
			this.closeRemovingPopup();
			this.$emit("campaignRemoved");
			this.$toast(`Your campaign has been successfully removed`);
			await this.$store.dispatch("getCurrentUserData");
		}
	}
};
</script>

<style lang="sass">
.camp-item
	$this: &
	--gapSize: 40px
	--camp-padding-y: 27px
	padding: var(--camp-padding-y) 0
	position: relative
	border-top: var(--bd)
	@media screen and (max-width: $tablet)
		--camp-padding-y: 17px
	&:last-of-type
		margin-bottom: calc(var(--camp-padding-y) * -1)
	&--
		&locked
			#{$this}__name
				color: var(--neutral-400)
				svg
					display: none
		&campaign-page
			padding: 0
			border-top: none
			#{$this}__grid
				gap: var(--values-gap)
				grid-template-columns: repeat(7, 1fr)
				grid-template-areas: "main main main linkedin linkedin linkedin status"
				@media screen and (max-width: $tablet)
					grid-template-columns: 1fr 1fr 1fr 120px
					grid-template-areas: "main main main status" "linkedin linkedin linkedin linkedin"
				@media screen and (max-width: $mobile)
					grid-template-columns: auto 120px
					grid-template-areas: "main status" "linkedin linkedin"
			#{$this}__block
				border: var(--bd)
				padding: 14px
				&--
					&gap
						gap: 4px
				&:first-child,
				&:last-child
					display: flex
					flex-direction: column
					justify-content: center
			#{$this}__block-caption
				display: block
			.camp-stat-display
				color: var(--neutral-900)

	&__
		&grid
			display: grid
			gap: var(--gapSize)
			grid-template-columns: 6fr 5fr 5fr 90px
			grid-template-areas: "main leads linkedin status"
			@media screen and (max-width: $tablet)
				--gapSize: 7px
				grid-template-columns: 1fr 1fr 1fr  90px
				grid-template-areas: "main main main status" "leads leads leads leads" "linkedin linkedin linkedin linkedin"
			@media screen and (max-width: $mobile)
				grid-template-columns: 1fr 90px
				grid-template-areas: "main status" "leads leads" "linkedin linkedin"

		&main
			width: 100%
			margin-bottom: 10px
			display: grid
			grid-template-columns: auto 22px
			gap: 5px
		&name
			color: var(--neutral-900)
			display: inline-block
			overflow: hidden
			text-overflow: ellipsis
			white-space: nowrap
			svg
				vertical-align: top
			&[href]
				&:hover,
				&:focus
					color: var(--main-primary)
					[stroke]
						stroke: var(--main-primary)
		&draft
			display: flex
			align-items: center
			gap: 5px
			color: var(--neutral-400)
			[stroke]
				stroke: var(--neutral-400)
		&block
			border-radius: var(--bdrs)
			&--
				&gap
					display: flex
					flex-direction: column
					gap: 12px
					@media screen and (max-width: $tablet)
						border: var(--bd)
						padding: 10px
						gap: 4px
		&block-caption
			color: var(--neutral-400)
			margin-bottom: 4px
			display: none
			@media screen and (max-width: $tablet)
				display: block
		&remove
			height: var(--icon-size)
			border-radius: var(--bdrs)
			&:hover:not(:disabled) [stroke]
				stroke: var(--main-red)
			&:disabled
				cursor: not-allowed
		&time
			margin-top: 14px
			color: var(--neutral-400)
			display: block
</style>
