export default {
	state: {
		selectedTab: null
	},
	getters: {
		getSelectedTab: state => state.selectedTab
	},
	mutations: {
		setSelectedTab: (state, payload) => {
			state.selectedTab = payload
		}
	},
	actions: {
		async getSequenceTemplatesList(store, payload) {
			try {
				const res = await window.request(
					"GET",
					"/v1/shared/sequenceitemtemplates/searches",
					payload
				);
				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async getSequenceTemplateById(store, id) {
			try {
				const res = await window.request("GET", `/v1/shared/sequenceitemtemplates/${id}`);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async createSequenceTemplate(_, payload) {
			const res = await window.request("POST", `/v1/shared/sequenceitemtemplates`, payload);

			return res.data;
		},
		async updateTemplate(_, { id, payload }) {
			const res = await window.request("PUT", `/v1/shared/sequenceitemtemplates/${id}`, payload);

			return res.data;
		},
		async deleteSequenceTemplate(store, id) {
			try {
				await window.request("DELETE", `/v1/shared/sequenceitemtemplates/${id}`);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async uploadTemplateAttachments(store, { payload, id }) {
			try {
				const res = await window.request(
					"PATCH",
					`/v1/shared/sequenceitemtemplates/${id}`,
					payload
				);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async deleteAttachment(store, { templateId, attachmentId }) {
			try {
				const url = `/v1/shared/sequenceitemtemplates/${templateId}/attachments/${attachmentId}`;
				const res = await window.request("DELETE", url);
				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		}
	}
};
