import searchItems from "./searchItems";
import sequence from "./sequence";
import { formattingParams } from "@/js/helpers/paramsFormatter";
import { canSeeNewUIGuide } from "@/js/services/UserService";

export default {
	modules: {
		searchItems,
		sequence
	},
	state: {
		sleepModeEnabled: false,
		isSleepModeMockedForGuide: false,
		campaignsInfo: {
			content: [],
			totalElements: 0
		}
	},
	getters: {
		sleepModeEnabled: (s) => s.sleepModeEnabled || (s.isSleepModeMockedForGuide && canSeeNewUIGuide()),
		campaignsInfo: (s) => s.campaignsInfo,
		campaignExist: (s) => !!s.campaignsInfo.content.length,
		basicPlanDeprecateCreatingCampaigns(_, g) {
			return (
				g.campaignsInfo.content.length >= 1 &&
				g.campaignsInfo.content.find((camp) => !camp.locked) &&
				g.myPlanId.includes("basic")
			);
		}
	},
	mutations: {
		setCampaignsInfo(state, obj) {
			state.campaignsInfo = obj;
		},
		changeSleepMode(state, val) {
			state.sleepModeEnabled = val;
		},
		toggleSleepModeForGuide(state) {
			state.isSleepModeMockedForGuide = !state.isSleepModeMockedForGuide;
		}
	},
	actions: {
		async getCampaigns(store, payload) {
			try {
				const config = {};
				if (payload.userId) config.headers = { "X-User-Id": payload.userId };
				const res = await window.request("GET", "/campaigns", formattingParams(payload), config);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async getCampaignById(_, id) {
			const res = await window.request("GET", `/campaigns/${id}`);

			return res.data;
		},
		async createCampaign(store, payload) {
			try {
				const res = await window.request("POST", "/campaigns", payload);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async updateCampaign(store, payload) {
			try {
				const res = await window.request("PUT", `/campaigns/${payload.id}`, payload);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async changeCampaignStatus(store, { status, id }) {
			try {
				const res = await window.request("PATCH", `/campaigns/${id}`, { status });

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async deleteCampaign(store, id) {
			try {
				await window.request("DELETE", `/campaigns/${id}`);
			} catch (err) {
				if (err.response.data.status !== 409) {
					store.commit("setRequestErrorMessage", err.response.data);
					throw err;
				}
			}
		},
		async getCampaignStatistics(_, { campaignsIds, userId }) {
			const config = { headers: { "X-User-Id": userId } };
			const res = await window.request("GET", `/v1/campaign/statistics/${campaignsIds}`, null, config);

			return res.data;
		},
		async getLeadsIdsFile(store, { campaignId, searchLeadConfigItemId }) {
			try {
				const url = `/campaigns/campaign/${campaignId}/search/${searchLeadConfigItemId}/leadids`;
				const res = await window.request("GET", url, {
					responseType: "blob"
				});

				return res;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async checkWebhook(_, webhookString) {
			const res = await window.request("POST", "/zapier/test", {
				zapierUrl: webhookString
			});

			return res.data;
		},
		async lockCampaigns(store, id) {
			try {
				await window.request("PATCH", `/campaigns/${id}/unlock`);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		}
	}
};
