import { isObject } from "@/js/helpers/utils";
import sequenceWalking from "@/js/mixins/sequenceWalking.mixin";

export default {
	mixins: [sequenceWalking],
	data: () => ({
		errorsTexts: {
			SEQUENCE_WITHOUT_END: "Make sure all sequence branches are closed",
			MESSAGE_IS_EMPTY: "Make sure all <b>‘Message’</b>, <b>’Email’</b> and <b>‘InMail’</b> actions contain text",
			SUBJECT_IS_EMPTY: "Make sure all <b>‘Email‘</b> actions contain a subject",
			VIOLATION_WITHDRAW_DELAY_THRESHOLD:
				"New connection requests can only be sent 21 days after the previous request has been withdrawn",
			WRONG_SEQUENCE_COMPOSITION: "Sequence composition is incorrect",
			CONNECT_BY_EMAIL_NOT_ALLOWED: "Make sure the <b>‘Invite by email‘</b> action is removed from your sequence", // CONNECT_BY_EMAIL_NOT_ALLOWED - artificially generated key on frontend side
			SEQUENCE_IS_EMPTY: "Sequence is empty",
			ATTACHMENTS_EXPIRED: "Make sure all files are attached before you move to the next step"
		},
	}),
	methods: {
		isObject,
		prepareSequenceItem(obj, templateCreating) {
			const sequence = {};
			const isConnect = obj.type === "CONNECT";
			const isMessage = obj.type === "MESSAGE";
			const isInMail = obj.type === "INMAIL";
			const isEmail = obj.type === "EMAIL";
			const hasSubject = isInMail || isEmail;
			const attachmentable = isMessage || isInMail || isEmail;
			const messagable = isConnect || isMessage || isInMail || isEmail;

			sequence.type = obj.type;
			sequence.errors = obj.errors;
			sequence.yesDelay = Object.assign({}, obj.yesDelay);
			sequence.noDelay = obj.noDelay ? Object.assign({}, obj.noDelay) : null;
			sequence.message = this.setMessageField(obj.message, isConnect, isEmail);
			sequence.subject = hasSubject ? obj.subject : null;
			sequence.sequenceEndMode = obj.sequenceEndMode || null;
			sequence.triggerType = obj.triggerType || null;
			if (isConnect) {
				sequence.inviteByEmailEnabled = obj.inviteByEmailEnabled;
			}
			if (!templateCreating) {
				sequence.id = obj.id || null;
			}
			sequence.alternativeMessage = messagable ? obj.alternativeMessage || "" : null;
			sequence.alternativeSubject = hasSubject ? obj.alternativeSubject : null;
			if (attachmentable) {
				if (templateCreating) {
					sequence.attachments = {};
					if (obj.attachesForSaving) {
						Object.assign(sequence.attachments, {
							...obj.attachments,
							[this.$cu().id]: obj.attachesForSaving.length ? obj.attachesForSaving : []
						});
					} else if (this.isObject(obj.attachments) && Object.keys(obj.attachments).length) {
						Object.assign(sequence.attachments, obj.attachments);
					} else if (Array.isArray(obj.attachments) && obj.attachments.length) {
						Object.assign(sequence.attachments, {
							[this.$cu().id]: obj.attachments
						});
					}
				} else {
					if (obj.attachesForSaving) {
						sequence.attachments = [...obj.attachesForSaving];
					} else {
						sequence.attachments = this.isObject(obj.attachments)
							? obj.attachments[this.$cu().id]
							: Array.isArray(obj.attachments)
								? obj.attachments
								: [];
					}
				}
			} else {
				sequence.attachments = null;
			}

			sequence.yes = obj.yes ? this.prepareSequenceItem(obj.yes, templateCreating) : null;
			sequence.no = obj.no ? this.prepareSequenceItem(obj.no, templateCreating) : null;

			return sequence;
		},
		setMessageField(msg, isConnect, isEmail) {
			if (isEmail) {
				const restrictedMessages = ["<div><br></div>", "<br>", "&nbsp;"];

				return restrictedMessages.includes(msg) ? null : msg;
			} else {
				return msg || (isConnect ? "" : null);
			}
		},
		collectSequence(val, templateCreating) {
			return val ? this.prepareSequenceItem(val, templateCreating) : null;
		},
		invalidAttachmentsInSequience() {
			const invalidActionsList = [...document.querySelectorAll(".action-face__invalid-attach")];
			invalidActionsList.forEach((item) => {
				item.classList.add("action-face__invalid-attach--accent");
			});
			return !!invalidActionsList.length;
		},
		getErroredSequenceActions(action) {
			const arr = [];
			if (action.errors) arr.push(action);
			if (action.yes) arr.push(...this.getErroredSequenceActions(action.yes));
			if (action.no) arr.push(...this.getErroredSequenceActions(action.no));
			return arr;
		},
		collectErrorsToToast(actionsList, isTemplate = false) {
			const allErrors = [];
			actionsList.forEach((item) => {
				const errorsOfAction = item.errors;
				errorsOfAction.forEach(err => {
					if (err.error === "WRONG_SEQUENCE_COMPOSITION" && err.message.includes("CONNECT_BY_EMAIL")) {
						allErrors.push("CONNECT_BY_EMAIL_NOT_ALLOWED")
					} else {
						allErrors.push(err.error)
					}
				});
			});

			const uniqueErrors = this.collectUniqueErrors(allErrors)
			const invalidAttachments = this.invalidAttachmentsInSequience();

			if (uniqueErrors.length === 1 && !invalidAttachments) {
				this.$toast(this.errorsTexts[uniqueErrors[0]], "danger");
			} else {
				this.$toast(this.createErrorsList(uniqueErrors, isTemplate), "danger");
			}
		},
		createErrorsList(uniqueErrors, isTemplate) {
			let toastContent = `<b>${isTemplate ? "Template" : "Sequence"} can't be saved:</b>`;
			uniqueErrors.forEach((item) => {
				if (this.errorsTexts[item])
					toastContent += this.errorFormatter(this.errorsTexts[item]);
			});
			return toastContent;
		},
		errorFormatter(text) {
			return `<br> • ${text}`;
		},
		collectUniqueErrors(allErrors) {
			const errs = [...new Set(allErrors)];
			const emailErrorsToReplace = ["EMAIL_MESSAGE_IS_EMPTY", "EMAIL_SUBJECT_IS_EMPTY"];
			const errorsCount = errs.length;
			const listWithoutEmailErrors = errs.filter(err => !emailErrorsToReplace.includes(err));
			if (errorsCount > listWithoutEmailErrors.length) {
				listWithoutEmailErrors.push("EMPTY_EMAIL_FIELD")
			}
			return listWithoutEmailErrors
		}
	}
};
