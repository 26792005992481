<template>
	<section
		class="limits"
	>
		<div class="limits__main">
			<Preloader v-if="preload" />
			<div
				style="grid-area: limits"
			>
				<div
					class="head"
				>
					<h2 class="h6">Maximum number of actions per day:</h2>

					<div
						class="limits__range"
						data-guiding-step="limits-range"
					>
						<span>
							Range
							<InfoTip tip="Choose the preferred range for daily processing limits to have Dripify perform a different number of actions every day for a more natural behaviour on LinkedIn" />
						</span>
						<AppSelect
							id="limits-range"
							v-model="range"
							:options="$options.rangesList"
							:disabled="activityControlEnabled || updating"
						/>
					</div>
				</div>

				<ul
					class="limits__grid"
					data-guiding-step="limits"
					aria-label="Limits"
				>
					<li
						v-for="limit in computedLimits"
						:key="limit.key"
						class="limits__grid-row"
						:aria-label="'Limit - ' + limit.text"
					>
						<p class="body_M">{{ limit.text }}</p>

						<Range
							v-model="limit.value"
							:stop-on="$store.getters.reducedBasicLimits ? stopOnValue(limit.key) : null"
							:disabled="disableRange"
							:max="limit.totalMaxLimit"
							:range="range"
							@change="limitChanged($event, limit)"
						/>
					</li>
				</ul>
			</div>

			<hr
				class="hr hr--v hide-on-netbook"
				style="grid-area: hr"
			>

			<div
				class="activity"
				aria-label="Activity control"
				style="grid-area: side"
				data-guiding-step="activity-control"
			>
				<template v-if="$isAdvancedPlan()">
					<ImgComp
						src="activity-control.svg"
						alt="LinkedIn account is protected by a force field"
						w="160"
						h="186"
						class="activity__img"
					/>
					<h3 class="body_L-SB">Activity control</h3>
					<br>
					<p class="body_XS neutral-500 mb">
						Advanced safety feature that ensures gradual growth of your performance and may adjust your daily connecting, messaging, and email limits to prevent both your LinkedIn and email accounts from being flagged for excessive activity.
					</p>

					<label
						class="activity-status"
						for="activity"
					>
						<span class="body_M-SB">
							{{ activityControlEnabled ? "Activated" : "Deactivated" }}
						</span>

						<Trigger
							v-model="activityControlEnabled"
							check-name="activity"
							class="trigger--M"
						/>
					</label>
				</template>

				<template v-else>
					<div class="body_L-SB">Stay safe on LinkedIn</div>
					<br>
					<p class="body_S mb">Upgrade to Advanced to unlock <b>Activity Control</b></p>
					<router-link
						class="btn btn--base btn--full-w"
						to="/upgrade"
					>
						Upgrade
					</router-link>

					<div class="activity-status mt">
						<div class="body_M-SB">Deactivated</div>

						<Trigger
							:checked="false"
							check-name="activity-dsbld"
							class="trigger--M"
							disabled
						/>
					</div>
				</template>
			</div>
		</div>
		<div
			v-if="dataChanged && !preload"
			class="mt flex"
		>
			<button
				class="btn btn--auto-w"
				@click="cancelChanges"
			>
				Cancel
			</button>
			<AsyncButton
				:async-f="updateLimits"
				class="btn btn--auto-w btn--base"
			>
				Save
			</AsyncButton>
		</div>
		<transition name="fade">
			<SettingsLeavingPopup
				v-if="leavingPopup"
				:saving-function="updateLimits"
				:leaving-path="leavingPath"
				@close="closeLeavingPopup"
			/>
		</transition>
	</section>
</template>

<script>
import guidingNextStep from "@/js/mixins/guidingNextStep.mixin";
import sequenceActionIcon from "@/js/mixins/sequenceActionIcon.mixin";
import settingsLeavingHandler from "@/js/mixins/settingsLeavingHandler.mixin";
import Range from "@/js/components/Form/Range";
import { inviteByEmailFeatureIsEnabled } from "@/js/services/UserService";

export default {
	name: "Limits",
	components: {
		Trigger: () => window.compLoader(import("@/js/components/Form/Trigger")),
		Range
	},
	mixins: [guidingNextStep, sequenceActionIcon, settingsLeavingHandler],
	rangesList: [1, 3, 5, 7, 10],
	data: () => ({
		preload: true,
		updating: false,
		activityControlEnabled: true,
		range: 3,
		limits: [
			{
				text: "Connection requests",
				key: "CONNECT",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				text: "Connection requests by email",
				key: "CONNECT_BY_EMAIL",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				text: "Messages",
				key: "MESSAGE",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				text: "InMails",
				key: "INMAIL",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				text: "Profile views",
				key: "VISIT",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				text: "Endorsements",
				key: "ENDORSE",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				text: "Likes",
				key: "LIKE",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				text: "Followings",
				key: "FOLLOW",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				text: "Emails",
				key: "EMAIL",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100,
			},
			{
				key: "WITHDRAW",
				value: 3000,
				maxLimit: 3000,
				totalMaxLimit: 3000,
			},
			{
				key: "IF_CONNECTED",
				value: 3000,
				maxLimit: 3000,
				totalMaxLimit: 3000,
			},
			{
				key: "IF_EMAIL_AVAILABLE",
				value: 3000,
				maxLimit: 3000,
				totalMaxLimit: 3000,
			},
			{
				key: "IF_MESSAGE_VIEWED",
				value: 3000,
				maxLimit: 3000,
				totalMaxLimit: 3000,
			},
			{
				key: "IF_OPEN_PROFILE",
				value: 3000,
				maxLimit: 3000,
				totalMaxLimit: 3000,
			},
			{
				key: "END",
				value: 0,
				maxLimit: 100,
				totalMaxLimit: 100
			}
		],
		comparer: [],
		activityControlComparer: false,
		rangeComparer: 0,
		ignoringKeys: [
			"END",
			"FIND_EMAIL",
			"WITHDRAW",
			"IF_CONNECTED",
			"IF_EMAIL_AVAILABLE",
			"IF_MESSAGE_VIEWED",
			"IF_OPEN_PROFILE"
		]
	}),
	computed: {
		computedLimits() {
			return this.limits.filter((limit) => !this.ignoringKeys.includes(limit.key));
		},
		dataChanged() {
			const limitsChanged = JSON.stringify(this.computedLimits) !== JSON.stringify(this.comparer);
			const actitivtyControlChanged = this.activityControlComparer !== this.activityControlEnabled;
			const rangeChanged = this.rangeComparer !== this.range;

			return limitsChanged || actitivtyControlChanged || rangeChanged;
		},
		disableRange() {
			return this.activityControlEnabled || this.updating;
		}
	},
	async created() {
		try {
			const lims = await this.$store.dispatch("getLimits");

			if (!inviteByEmailFeatureIsEnabled()) {
				this.ignoringKeys.push("CONNECT_BY_EMAIL")
			}

			this.setNewLimits(lims);
			this.guidingNextStep();
		} finally {
			this.preload = false;
			this.dataLoaded = true;
		}
	},
	methods: {
		cancelChanges() {
			const oldValues = {
				processingLimits: this.comparer.map((item) => {
					return {
						limit: item.value,
						sequenceItemType: item.key,
						maxLimit: item.totalMaxLimit
					};
				}),
				activityControlEnabled: this.activityControlComparer,
				processingLimitRange: this.rangeComparer
			};

			this.setNewLimits(oldValues)
		},
		stopOnValue(key) {
			const BASIC_LIMITS = this.$cu().basicProcessingLimits;

			return BASIC_LIMITS.find((basicLimit) => basicLimit.sequenceItemType === key)
				.limit;
		},
		saveDataToCompare() {
			this.comparer = this.computedLimits.map((item) => Object.assign({}, item));
			this.activityControlComparer = this.activityControlEnabled;
			this.rangeComparer = this.range;
		},
		setNewLimits(lims) {
			this.limits.forEach((limit) => {
				const neededLimitObj = lims.processingLimits.find(
					(lim) => lim.sequenceItemType === limit.key
				);
				if (neededLimitObj) {
					limit.value = neededLimitObj.limit;
					limit.maxLimit = neededLimitObj.maxLimit;
					limit.totalMaxLimit = neededLimitObj.totalMaxLimit || neededLimitObj.maxLimit;
				}
			});
			this.activityControlEnabled = lims.activityControlEnabled;
			this.range = lims.processingLimitRange

			this.saveDataToCompare();
		},
		limitChanged(val, limit) {
			limit.value = val;
		},
		async updateLimits() {
			try {
				this.updating = true;
				this.dataLoaded = false;
				const limitsObj = [];
				this.limits.forEach((item) => {
					limitsObj.push({
						limit: item.value,
						sequenceItemType: item.key
					});
				});
				const lims = await this.$store.dispatch("updateLimits", {
					activityControlEnabled: this.activityControlEnabled && this.$isAdvancedPlan(),
					processingLimits: limitsObj,
					processingLimitRange: this.range,
					priceId: this.$myPlanId()
				});
				const activityControlActivated =
					!this.activityControlComparer && this.activityControlEnabled;
				this.$toast(
					activityControlActivated
						? "Great job! The Activity Control is now handling your daily limits automatically"
						: "Your daily limits have been successfully updated"
				);
				this.setNewLimits(lims);
			} catch (err) {
				this.$store.commit("setRequestErrorMessage", "We couldn't save your changes this time. Try again later");
			} finally {
				this.updating = false;
				this.dataLoaded = true;
			}
		}
	}
};
</script>

<style lang="sass">
.limits
	display: flex
	flex-direction: column
	justify-content: space-between
	--section-gap: 24px
	&__
		&range
			display: flex
			align-items: center
			gap: 5px 18px
			max-width: 300px
			white-space: nowrap
			padding-left: 10px
			@media screen and (max-width: $netbook)
				flex-direction: column
			span
				display: flex
				align-items: center
				gap: 12px
				color: var(--neutral-500)
		&main
			position: relative
			display: grid
			grid-template-columns: auto 1px minmax(205px, 20%)
			grid-template-areas: "limits hr side"
			gap: var(--section-gap)
			@media screen and (max-width: $netbook)
				grid-template-columns: 100%
				grid-template-areas: "side" "limits"
		&grid
			position: relative
			padding: 0 var(--section-gap) 0 var(--card-offset-x)
			margin: 0 calc(var(--section-gap) * -1) 0 calc(var(--card-offset-x) * -1)
		&grid-row
			display: grid
			gap: 5px 15px
			grid-template-columns: minmax(225px, 20%) auto
			align-items: center
			@media screen and (max-width: $netbook)
				grid-template-columns: 1fr
				align-items: unset
				margin-bottom: 15px

.activity-status
	padding: 12px 24px
	max-width: 480px
	border-radius: var(--bdrs)
	border: var(--bd)
	display: flex
	align-items: center
	justify-content: space-between
	color: var(--neutral-500)
	transition: color var(--transition), background-color var(--transition), border-color var(--transition)
	&:has(input[type="checkbox"]:checked)
		color: var(--main-green)
		background-color: var(--main-green-light)
		border-color: var(--main-green-light)
	&:has(input[type="checkbox"]:disabled)
		background-color: var(--neutral-50)
		border-color: var(--neutral-50)
		cursor: not-allowed

.activity
	&__
		&img
			max-width: 180px
			width: 60%
			min-width: 160px
			margin: 0 auto 35px
			img
				width: 100%

</style>
