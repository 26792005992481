<template>
	<div class="camp-stat-display">
		<span class="body_S"><slot /></span>
		<div
			class="camp-stat-display__digit"
			:class="{ 'camp-stat-display__digit--blink': statisticsLoading }"
		>
			<transition name="to-top">
				<Icon
					v-if="showSearchArrows"
					v-tooltip="
						`There are leads that are currently being uploaded ${pack ? '' : 'to this campaign'}`
					"
					class="animate"
					size="15"
					icon="uploading-arrows"
				/>
			</transition>

			<component
				:is="isLink && !countField ? 'router-link' : 'span'"
				:to="isLink && !countField ? link : null"
				class="body_M"
			>
				<span> <animate-number :to="value" /><template v-if="showPercent">%</template> </span>
			</component>

			<component
				:is="isLink ? 'router-link' : 'span'"
				v-if="count"
				:to="isLink ? link : null"
			>
				<animate-number
					:to="count"
					class="body_S"
				/>
			</component>
		</div>
	</div>
</template>

<script>
export default {
	name: "CampaignStatisticsDisplaying",
	props: {
		campaign: { type: Object, required: true },
		pack: { type: Object, required: false },
		clickable: { type: Boolean, default: true },
		statisticsLoading: { type: Boolean, default: true },
		field: { type: String, required: true },
		countField: { type: String, required: false },
		filter: { type: String, required: false }
	},
	computed: {
		stat() {
			return this.pack ? this.pack.statistics : this.campaign.statistics;
		},
		isDraft() {
			return this.campaign.status === "DRAFT";
		},
		value() {
			return this.isDraft || !this.stat ? 0 : this.stat[this.field] || 0;
		},
		count() {
			return this.isDraft || !this.stat ? 0 : this.stat[this.countField] || 0;
		},
		isLink() {
			return (
				this.clickable && !this.statisticsLoading && !this.isDraft && this.value
			);
		},
		link() {
			let baseUrl = `/campaigns/${this.campaign.id}`;
			if (this.pack || this.filter || this.totalLeads) baseUrl += "/leads?";
			if (this.pack) baseUrl += `leadsList=${this.pack.id}&`;
			if (this.filter) baseUrl += `includeTypes=${this.filter}`;
			return baseUrl;
		},
		showPercent() {
			return this.field.includes("Rate");
		},
		totalLeads() {
			return this.field === "totalLeads" || this.field === "totalLeadsCount";
		},
		showSearchArrows() {
			return (
				this.totalLeads &&
				this.stat &&
				this.stat.searchActive &&
				this.campaign.status === "ACTIVE" &&
				!this.campaign.locked &&
				!this.$store.getters.sleepModeEnabled
			);
		}
	}
};
</script>

<style lang="sass">
.camp-stat-display
	color: var(--neutral-400)
	display: flex
	align-items: center
	justify-content: space-between
	gap: 5px
	[href]
		color: var(--main-primary)
	&__
		&digit
			display: inline-flex
			align-items: center
			gap: 8px
			color: var(--neutral-900)
			&--blink
				animation: blinking 1s infinite
</style>
