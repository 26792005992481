export default {
	actions: {
		async getSequence(store, sequenceItemId) {
			try {
				const res = await window.request("GET", `/v1/sequenceitems/${sequenceItemId}`);

				return res.data;
			} catch (err) {
				const errData = err.response.data;

				if (errData.yesDelay) {
					return errData;
				} else {
					store.commit("setRequestErrorMessage", errData);
					throw err;
				}
			}
		},
		async saveSequence(store, { campaignId, sequenceItemRequest }) {
			try {
				const url = `/v1/sequenceitems?campaignId=${campaignId}`;
				const config = {
					headers: { "Content-Type": "application/json" }
				};
				const res = await window.request("POST", url, sequenceItemRequest, config);

				return res.data;
			} catch (err) {
				const error = err.response.data;
				if (error && (error.status === 409 || (error.message && error.message.includes("409")))) {
					store.commit("setRequestErrorMessage", "Sequence wasn't saved: Validation error");
				} else {
					return error;
				}
			}
		},
		async findAvailableItemsForSequence(store, payload) {
			try {
				const res = await window.request("POST", "/v1/shared/sequenceitems/available", payload);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		}
	}
};
