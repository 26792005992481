export default {
	methods: {
		isSavedTemplate(id) {
			return Number.isInteger(+id);
		},
		async getSequenceTemplateById(id) {
			return await this.$store.dispatch("getSequenceTemplateById", id);
		}
	}
};
