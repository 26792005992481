import { approvementLIStatus, restrictedLIAccess } from "@/js/helpers/failedUserStatus";
import { isObject } from "@/js/helpers/utils";

export default {
	state: {
		currentUser: null,
		userStatus: "",
		reminders: []
	},
	getters: {
		currentUser: (s) => s.currentUser,
		userStatus: (s) => (s.currentUser ? s.currentUser.status : s.userStatus),
		restrictedLIAccess: (_, g) => restrictedLIAccess(g.userStatus),
		approvementLI: (_, g) => approvementLIStatus(g.userStatus),
		remindersArr: (s) => s.reminders
	},
	mutations: {
		setCurrentUserField(state, payload) {
			Object.keys(payload).forEach((key) => {
				state.currentUser[key] = payload[key];
			});
		},
		setCurrentUser(state, obj) {
			state.currentUser = obj;
		},
		clearCurrentUser(state) {
			state.currentUser = null;
		},
		setActivityControlStatus(state, val) {
			state.currentUser.activityControlEnabled = val;
		},
		setTimezone(state, val) {
			state.currentUser.zone = val;
		},
		changeCurrentUserStatus(state, val) {
			if (state.currentUser) {
				state.currentUser.status = val;
			}
			state.userStatus = val;
		},
		setReminders(state, val) {
			state.reminders = val;
		},
		setUpdatedReminder(state, val) {
			const reminderToChangeIndex = state.reminders.findIndex(
				(r) => r.reminderType === val.reminderType
			);
			if (reminderToChangeIndex === -1) {
				state.reminders.push(val);
			} else {
				state.reminders.splice(reminderToChangeIndex, 1, val);
			}
		}
	},
	actions: {
		async getCurrentUserData(store) {
			if (!store.getters.userInfo) store.commit("setUser");
			const user = await window.request("GET", "/userdetails/current");
			store.commit("setCurrentUser", user.data);
		},
		async editCurrentUserData(store, payload) {
			const user = await window.request("PATCH", "/userdetails/current", payload);
			store.commit("setCurrentUser", user.data);
		},
		async sendCurrentUserData(store, payload) {
			if (!store.getters.userInfo) store.commit("setUser");
			const config = { headers: { "X-User-Id": store.getters.userInfo.uid } };
			const user = await window.request("POST", "/userdetails", payload, config);
			store.commit("setCurrentUser", user.data);
		},
		async sendLIVerify(store, payload) {
			try {
				const config = { headers: { "X-User-Id": payload.userId } };
				const user = await window.request("POST", "/userdetails/verify", payload, config);
				setTimeout(() => {
					store.commit("setCurrentUser", user.data);
				}, 200);
			} catch (err) {
				const error = err.response.data;

				if (error.message === "WRONG_VERIFICATION_CODE") {
					store.commit("setRequestErrorMessage", "Wrong verification code");
				} else if (!restrictedLIAccess(error.message)) {
					store.commit("setRequestErrorMessage", error);
				} else {
					store.commit("setRequestErrorMessage", "An attempt to log in to LinkedIn failed. Please try again later");
				}
				throw err;
			}
		},
		async sendVerificationCode(store) {
			try {
				await window.request("POST", "/userdetails/verification/code");
			} catch (err) {
				const error = err.response.data;

				if (error.status === 409) {
					store.commit("setRequestErrorMessage", error);
				}
				throw err;
			}
		},
		async getProxyInfo(_, payload) {
			const	userId = payload.userId;
			const config = { headers: { "X-User-Id": userId } };
			const user = await window.request("GET", `/user-client/${userId}/ip-data`, {}, config);

			return user.data;
		},
		async refreshProxy(store, payload) {
			try {
				const { userId, countryCode } = payload;
				return await window.request("POST", `/user-client/${userId}/ip-data/new-ip?countryCode=${countryCode}`);
			} catch (err) {
				const error = err.response.data;

				store.commit(
					"setRequestErrorMessage", error.status === 429
						? "Refresh Proxy feature is limited by three refreshes per day."
						: error.message
				);
				throw err;
			}
		},
		async getUserDetailsById(_, payload) {
			let userId, isFull;
			if (isObject(payload)) {
				userId = payload.userId;
				isFull = payload.isFull;
			} else {
				userId = payload;
				isFull = false;
			}
			const config = { headers: { "X-User-Id": userId } };
			const user = await window.request("GET", `/userdetails/${userId}`, { isFull }, config);

			return user.data;
		},
		async changeUserDetails(_, { id, payload }) {
			const config = { headers: { "X-User-Id": id } };
			const res = await window.request("PUT", `/userdetails/${id}`, payload, config);

			return res.data;
		},
		async getEmailUsingInfo(store, email) {
			const res = await window.request("GET", `/userdetails/verify/${email}`);
			const emailAlreadyInUse = res.data;
			if (emailAlreadyInUse) {
				store.commit("setRequestErrorMessage", `Email <b>${email}</b> is already in use by another account`);
			}
			return emailAlreadyInUse;
		},
		async changeUserEmail(store, payload) {
			const user = await window.request("PATCH", "/userdetails/email", payload);
			if (user.data) store.commit("setCurrentUser", user.data);
		},
		async getReminders(store) {
			const res = await window.request("GET", "/v1/shared/versionsync/current");
			store.commit("setReminders", res.data);

			return res.data;
		},
		async changeReminderStatus(store, payload) {
			const res = await window.request("POST", "/v1/shared/versionsync", payload);
			store.commit("setUpdatedReminder", res.data);
			return res.data;
		},
		async getLinkedinIdentity() {
			await window.request("GET", "/v1/linkedin/identity/current");
		},
		async changeBillingEmail(store, {userId, billingEmail}) {
			try {
				const res = await window.request("PUT", `/v1/shared/admin/users/${userId}/emails/billing?billingEmail=${billingEmail}`);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		}
	}
};
