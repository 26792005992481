export default {
	methods: {
		sequenceActionIcon(actionType) {
			switch (actionType) {
			case "LEFT":
				return "sequence-icon-ready";
			case "CONNECT_SENT":
			case "CONNECT":
				return "sequence-icon-connect-sent";
			case "CONNECT_BY_EMAIL_SENT":
			case "CONNECT_BY_EMAIL":
				return "sequence-icon-email-sent";
			case "INVITE_ACCEPTED":
				return "sequence-icon-accepted";
			case "INVITE_PENDING":
				return "sequence-icon-pending";
			case "MESSAGE_RESPONDED":
				return "sequence-icon-replied";
			case "MANUALLY_REPLIED":
			case "MESSAGE_SENT":
			case "MESSAGE":
				return "sequence-icon-messaged";
			case "MESSAGE_VIEWED":
				return "sequence-icon-msg-viewed";
			case "INMAIL_SENT":
			case "INMAIL":
				return "sequence-icon-inmail";
			case "SKILLS_ENDORSED":
			case "ENDORSE":
				return "sequence-icon-endorsed";
			case "PROFILE_VIEWED":
			case "VISIT":
				return "sequence-icon-viewed";
			case "FOLLOWED":
			case "FOLLOW":
				return "sequence-icon-followed";
			case "LIKED":
			case "LIKE":
				return "sequence-icon-liked";
			case "FIND_EMAIL":
			case "EMAIL_FOUND":
			case "EMAIL_ALREADY_FOUND":
			case "FIND_EMAIL_ERROR":
				return "envelop-loup";
			case "BLACKLISTED":
				return "sequence-icon-blacklisted";
			case "PAUSED":
				return "pause";
			case "WITHDRAWN":
			case "WITHDRAW":
				return "sequence-icon-withdrawn";
			case "MOVED_BACK_TO_SEQUENCES":
				return "sequence-icon-resume";
			case "EMAIL_SENT":
			case "EMAIL":
				return "sequence-icon-send-email";
			case "END":
				return "minus-in-circle";
				// CONDITIONS
			case "IF_CONNECTED":
				return "sequence-icon-accepted";
			case "IF_MESSAGE_VIEWED":
				return "sequence-icon-if-message-viewed";
			case "IF_EMAIL_AVAILABLE":
			case "EMAIL_AVAILABLE":
				return "sequence-icon-if-email-available";
			case "IF_OPEN_PROFILE":
				return "sequence-icon-if-open-profile";
			default:
				return actionType.includes("ERROR") ? "exclamation" : "";
			}
		}
	}
};
