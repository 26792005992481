import axios from "axios";
import store from "@/js/store/index";
import router from "@/js/router";
import { failedUserStatus } from "@/js/helpers/failedUserStatus";

export default function setup() {
	axios.interceptors.request.use(requestIntersept, (err) => Promise.reject(err));
	axios.interceptors.response.use((response) => response, responseErrorIntersept);
}

async function requestIntersept(config) {
	const sideAccId = store.getters.sideAccountId;

	let tokenResult;
	tokenResult = await store.dispatch("getTokenResult");
	if (!tokenResult.claims.linkedin_id) {
		tokenResult = await store.dispatch("getTokenResult", true);
	}

	config.headers.common.Authorization = `Bearer ${tokenResult.token}`;

	if (sideAccId) config.headers["X-SIGNED-USER-ID"] = sideAccId;

	return config;
}

function responseErrorIntersept(error) {
	const resp = error.response;
	if (resp) {
		const data = resp.data;
		checkIfBadStatusResponse(data);
	}
	return Promise.reject(error);
}

function checkIfBadStatusResponse(data) {
	if (data && data.status === 401 && failedUserStatus(data.message)) {
		store.commit("changeCurrentUserStatus", data.message);

		if (router.app.$route.meta.layout === "default") {
			router.push("/dashboard")
		}
	}
}
