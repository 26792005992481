<template>
	<div
		id="app"
		role="application"
		aria-label="Dripify — LinkedIn automation tool"
	>
		<component
			:is="layout"
			v-if="layout"
			ref="layout"
			@layoutLoaded="thirdPartLibsLazyLoad"
		>
			<transition
				name="fade"
				mode="out-in"
			>
				<router-view />
			</transition>
		</component>



		<Toasts />
		<VueAnnouncer />
		<PseudoSupportChat />
	</div>
</template>

<script>
import adminAccountController from "@/js/helpers/adminAccountController";
import PseudoSupportChat from "@/js/components/PseudoSupportChat";
import thirdPartLibsLazyLoad from "@/js/helpers/api/thirdPartLibsLazyLoad";
import logout from "@/js/mixins/logout.mixin";

export default {
	name: "Dripify",
	metaInfo: {
		title: "Dripify",
		link: []
	},
	components: {
		AuthLayout: () => window.compLoader(import("@/js/layouts/Auth")),
		DefaultLayout: () => window.compLoader(import("@/js/layouts/Default")),
		EmptyLayout: () => window.compLoader(import("@/js/layouts/Empty")),
		Toasts: () => window.compLoader(import("@/js/components/Toasts/Toasts")),
		PseudoSupportChat
	},
	mixins: [logout],
	computed: {
		layout() {
			const layout = this.$route.meta.layout;
			return layout ? `${layout}-layout` : null;
		},
		cu() {
			return this.$store.getters.currentUser;
		},
		requestErrorMessage() {
			return this.$store.getters.errMessage;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		}
	},
	watch: {
		requestErrorMessage(newErr) {
			if (newErr) {
				const message = newErr.status === 500 ? "Oops... Something went wrong" : newErr.message;
				this.$toast(message || newErr, "danger");
				this.$store.commit("setRequestErrorMessage", "");
			}
		},

	},
	mounted() {
		window.multitabChannel.addEventListener("message", (event) => {
			if (event.data === "refresh") document.location.reload(true);
			else if (event.data === "logout") this.logout();
		});

		adminAccountController.writeTestAcc();

		window.VueApp.prototype.$cu = () => this.cu;
	},
	methods: {
		thirdPartLibsLazyLoad
	}
};
</script>

<style lang="sass">
@import "@/styles/app"
@import "@/styles/transitions"
</style>
