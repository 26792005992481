<template>
	<div
		class="checkbox"
		:class="{
			'checkbox--inline': haveLabel,
			'checkbox--disabled': disabled
		}"
	>
		<input
			:id="checkName"
			class="checkbox__input"
			type="checkbox"
			:name="checkName"
			:checked="checked"
			:disabled="disabled"
			v-bind="$attrs"
			@change="$emit('change', $event.target.checked)"
		>
		<label
			class="checkbox__label"
			:class="{ 'checkbox__label--disabled': disabled }"
			:for="checkName"
		>
			<span
				v-if="haveLabel"
				class="checkbox__text"
				:class="{
					'checkbox__text--disabled': disabled,
					'checkbox__text--required': required
				}"
			>
				<slot />
			</span>
		</label>
	</div>
</template>

<script>
export default {
	name: "Checkbox",
	model: {
		prop: "checked",
		event: "change"
	},
	props: {
		value: { type: String, default: `checkbox${Date.now()}` },
		checkName: { type: String, required: true },
		disabled: { type: Boolean, default: false },
		checked: { type: Boolean, default: false },
		required: { type: Boolean, default: false },
	},
	computed: {
		haveLabel() {
			return this.$slots.default;
		}
	}
};
</script>

<style lang="sass">
.checkbox
	$this: &
	--size: 24px
	position: relative
	width: var(--size)
	height: var(--size)
	&--
		&inline
			margin-right: 10px
			width: auto
			height: auto
			display: inline-block
		&disabled
			#{$this}__label
				&::before
					border-color: var(--neutral-200) !important
					background-color: var(--neutral-100) !important
	&__
		&input
			opacity: 0
			position: absolute
			z-index: -999
			width: 0
			height: 0
			overflow: hidden
			left: 12px
			top: 12px
			&:checked
				& + #{$this}__label
					&::before
						background-color: var(--main-primary)
						border-color: var(--main-primary)
					&::after
						opacity: 1
						transform: rotate(0deg)
				&:disabled + #{$this}__label
					cursor: not-allowed
					&:before
						background-color: var(--neutral-300) !important
						border-color: var(--neutral-300) !important
			&:disabled + #{$this}__label
				cursor: not-allowed

			&:not(:disabled):focus-visible + #{$this}__label::before
				+focus-visible()

		&label
			display: flex
			cursor: pointer
			line-height: 18px
			margin: 0
			&--

			&::before
				content: ''
				border: 1.5px solid var(--neutral-400)
				border-radius: 6px
				width: var(--size)
				height: var(--size)
				transition: background-color var(--transition), border-color var(--transition)
			&::after
				content: ''
				position: absolute
				width: 20px
				height: 20px
				background: no-repeat url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M16.6667 5.41663L7.50004 14.5833L3.33337 10.4166' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
				left: 2px
				top: 2px
				transform: rotate(-20deg) scale(.5)
				transition: transform var(--transition), opacity var(--transition)
				opacity: 0
			&:hover
				&::before
					border-color: var(--main-primary)
					background-color: var(--neutral-50)
		&text
			line-height: 23px
			padding-left: 16px
			user-select: none
			width: calc(100% - var(--size))
			transition: color var(--transition)
			&--
				&disabled
					color: var(--neutral-400)
				&required
					&::after
						content: ' *'
			a
				text-decoration: underline
				&:hover,
				&:focus
					text-decoration: none
</style>
