<template>
	<div
		ref="popup"
		class="modal"
		:class="{
			'modal--can-close': closeble,
		}"
		role="dialog"
		@mousedown="clickForClose"
		@keyup.esc="closeOnEsc"
	>
		<div
			class="modal__wrap"
		>
			<div
				class="modal__grid"
				:class="{'modal__grid--side-position': authLayout}"
			>
				<div class="modal__inner">
					<slot />

					<button
						v-if="closeble"
						class="modal__cross"
						title="Close"
						aria-label="Close modal"
						@click.prevent="closePopup"
					>
						<Icon
							icon="cross"
						/>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "Popup",
	props: {
		closeble: { type: Boolean, default: true },
		focusPopup: { type: Boolean, default: true },
	},
	interactiveElements: '[tabindex="0"], input, textarea, button, a',
	notInertElementsSelector: ".modal",
	computed: {
		authLayout() {
			return this.$route.meta.layout === "auth";
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.disableUnusedElements();
			if (this.focusPopup) {
				const { popup } = this.$refs;
				if (popup) {
					const interactiveElement = popup.querySelector(this.$options.interactiveElements);

					if (interactiveElement) interactiveElement.focus();
				}
			}
		});
		document.body.classList.add("overflow");
	},
	beforeDestroy() {
		document.body.classList.remove("overflow");
		this.changeInertOfAll(false);
	},
	methods: {
		clickForClose(event) {
			if (this.closeble &&!event.target.closest(".modal__inner") && event.type === "mousedown")
				this.closePopup();
		},
		closeOnEsc() {
			if (this.closeble) this.closePopup();
		},
		closePopup() {
			this.$emit("close");
		},
		disableUnusedElements() {
			const noInertElementsParents = document.querySelectorAll(
				this.$options.notInertElementsSelector
			);

			this.changeInertOfAll(true);
			noInertElementsParents.forEach((el) => {
				el.inert = false;
				this.changeInertOfAll(false, el);
			});
		},
		changeInertOfAll(val, scope = document) {
			const els = scope.querySelectorAll(this.$options.interactiveElements);

			els.forEach((el) => (el.inert = val));
		}
	}
};
</script>

<style lang="sass">
.modal
	$this: &
	--popup-max-w: var(--auth-w)
	position: fixed
	inset: 0
	z-index: 1002
	overflow: hidden auto
	background: rgba(var(--neutral-900--rgb), .15)
	display: flex
	flex-direction: column
	justify-content: space-between
	align-items: center
	@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)
		-webkit-backdrop-filter: blur(5px)
		backdrop-filter: blur(5px)
	&::before,
	&::after
		content: ''

	&--
		&can-close
			cursor: pointer
	&__
		&wrap
			max-width: var(--popup-max-w)
			width: 100%
		&grid
			--inner-offset-x: 15px
			position: relative
			padding: 10px var(--inner-offset-x) 20px
			max-width: 100vw
			display: flex
			justify-content: center
			margin: 0 auto
			&--
				&side-position
					display: grid
					grid-template-columns: 3fr 4fr
					&::before
						content: ""
					#{$this}__inner
						max-width: 640px
						margin: 0 auto
					@media screen and (max-width: $netbook)
						display: block

		&inner
			--modal-padding-y: 40px
			--modal-padding-x: 40px
			background: var(--neutral-0)
			border-radius: var(--bdrs-L)
			cursor: auto
			padding: var(--modal-padding-y) var(--modal-padding-x)
			max-width: calc(100vw - var(--inner-offset-x) * 2)
			position: relative
			@media screen and (max-width: $fablet)
				--modal-padding-y: 30px
				--modal-padding-x: 15px
		&cross
			position: absolute
			right: 0
			top: 0
			+flex(center, center)
			padding: 24px 24px 0 0
			border-radius: 0 var(--bdrs-L) 0 0
			&:hover
				opacity: 0.6
			@media screen and (max-width: $fablet)
				padding: 20px 15px 0 0
		&head
			display: flex
			align-items: center
			gap: 15px
			margin-bottom: 24px
		&footer
			margin-top: 24px
			display: flex
			align-items: center
			justify-content: space-between
			&--
				&end
					justify-content: flex-end
				&center
					justify-content: center
				&full
					flex-wrap: wrap
					gap: 14px
					& > *
						flex: 1
						min-width: 160px
						margin: 0 !important
</style>
