export default {
	computed: {
		roleInTeam() {
			const myId = this.$cu().id;
			if (this.$store.getters.userInTeam) {
				const team = this.$store.getters.userInTeams.find((team) => team.userId === myId);
				if (team) return team.role;
			}
			return "OWNER";
		}
	}
};
