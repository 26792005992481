export default {
	LI_SEARCH_TEXT: "LinkedIn Search",
	PROFILES_URLS_TEXT: "Profile URLs",
	methods: {
		isLISearch(searchOption) {
			return searchOption === "LINKEDIN_SEARCH";
		},
		searchLeadsPackName(pack) {
			if (pack) {
				if (pack.name) return pack.name;
				else if (!pack.searchType)
					return this.isLISearch(pack.searchOption) ? this.$options.LI_SEARCH_TEXT : this.$options.PROFILES_URLS_TEXT;
				else if (pack.searchType.startsWith("DEFAULT"))
					return "Basic LinkedIn Search";
				else if (pack.searchType.startsWith("SALES"))
					return "Sales Navigator Search";
				else if (pack.searchType.startsWith("RECRUITER"))
					return "Recruiter Search";
				else if (pack.searchType.startsWith("EVENT_MEMBERS"))
					return "Event Members";
				else if (pack.searchType.startsWith("MYNETWORK"))
					return "My Network";
				else if (pack.searchType.startsWith("GROUP"))
					return "Group Members";
				else if (pack.searchType === "LEAD_IDS_LIST")
					return "Profile URLs";
				else if (pack.searchType === "LEAD_IDS_CSV")
					return "CSV file";
			} else {
				return "Leads";
			}
		},
		searchTypeIcon(type, searchOption) {
			if (!type && searchOption)
				return this.isLISearch(searchOption) ? "li" : "link-in-square";
			else if (type.startsWith("DEFAULT"))
				return "li";
			else if (type.startsWith("SALES"))
				return "compass";
			else if (type.startsWith("RECRUITER"))
				return "search-user";
			else if (type.startsWith("EVENT_MEMBERS"))
				return "star-calendar";
			else if (type.startsWith("MYNETWORK"))
				return "profile";
			else if (type.startsWith("GROUP"))
				return "all-leads";
			else if (type === "LEAD_IDS_LIST")
				return "link-in-square";
			else if (type === "LEAD_IDS_CSV")
				return "upload-cloud";
			else
				return "li";
		}
	}
};
