import { useStore } from "@/js/store";

const store = useStore();
const ROUTES_REGISTER_PHONE_CHALLENGE = ['/inbox'];

export function isItemRestrictedByStatus(item) {
	return ROUTES_REGISTER_PHONE_CHALLENGE.includes(item.route)
		&& store.getters?.userStatus === "REGISTER_PHONE_CHALLENGED";
}

export function isCreationDisabledByUserState() {
	return store.getters?.userStatus === "REGISTER_PHONE_CHALLENGED";
}
