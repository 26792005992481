<template>
	<Icon
		v-tooltip="tooltipOptions"
		:w="size ? size : null"
		:h="size ? size : null"
		:stroke="stroke"
		icon="info"
		class="round"
		:tabindex="tip ? '0' : '-1'"
		:aria-label="tipContent"
		role="tooltip"
		style="vertical-align: sub;"
	/>
</template>

<script>
import { isObject } from "@/js/helpers/utils";

export default {
	name: "InfoTip",
	props: {
		size: { type: [String, Number], required: false },
		tip: { type: [String, Object], required: false },
		stroke: { type: String, required: false }
	},
	computed: {
		tooltipOptions() {
			const options = {
				trigger: "hover click focus",
				content: this.tipContent
			};
			if (isObject(this.tip)) {
				Object.assign(options, this.tip);
			}
			return options;
		},
		tipContent() {
			return isObject(this.tip) ? this.tip.content : this.tip;
		}
	}
};
</script>
