import countriesList from "@/js/helpers/constants/countriesList";

export function capitalizeFirstLetter(string) {
	return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export function moveArrayElement(arr, oldIndex, newIndex) {
	if (newIndex >= arr.length) {
		let k = newIndex - arr.length + 1;
		while (k--) arr.push(undefined);
	}
	arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

export function arrayUnique(array) {
	var a = array.concat();
	for (var i = 0; i < a.length; ++i) {
		for (var j = i + 1; j < a.length; ++j) {
			if (a[i] === a[j]) a.splice(j--, 1);
		}
	}
	return a;
}

export function arraysEqualWithFrequency(a, b) {
	if (!Array.isArray(a) || !Array.isArray(b)) {
		return false;
	}

	if (a.length !== b.length) {
		return false;
	}

	const count = (arr) => arr.reduce((acc, val) => {
		acc[val] = (acc[val] || 0) + 1;
		return acc;
	}, {});

	const aCount = count(a);
	const bCount = count(b);

	return Object.keys(aCount).every(key => aCount[key] === bCount[key]);
}

export function isObject(obj) {
	return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}

export function numberWithSpaces(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function diffYears(dayOne, dayTwo) {
	const yearOne = dayOne.getFullYear();
	const yearTwo = dayTwo.getFullYear();
	const monthOne = dayOne.getMonth();
	const monthTwo = dayTwo.getMonth();

	let yearDiff = yearTwo - yearOne;

	if (monthTwo < monthOne || (monthTwo === monthOne && dayTwo.getDate() < dayOne.getDate())) {
		yearDiff--;
	}

	return yearDiff;
}

export function diffMonthes(dayOne, dayTwo) {
	const yearOne = dayOne.getFullYear();
	const yearTwo = dayTwo.getFullYear();
	const monthOne = dayOne.getMonth();
	const monthTwo = dayTwo.getMonth();

	return (yearTwo - yearOne) * 12 + (monthTwo - monthOne);
}

export function diffDates(dayOne, dayTwo) {
	return Math.round(Math.abs(dayOne - dayTwo) / (60 * 60 * 24 * 1000));
}

export function diffHours(dayOne, dayTwo) {
	return Math.round(Math.abs(dayOne - dayTwo) / (60 * 60 * 1000));
}

export function diffMinutes(dayOne, dayTwo) {
	return Math.round(Math.abs(dayOne - dayTwo) / (60 * 1000));
}

export function prettifyText(text) {
	if (!text || typeof text !== "string") return "";

	const escaped = escapeHTML(text); // важливо!
	const urlified = URLify(escaped);
	const withBreaks = lineBreakText(urlified);

	return withBreaks;
}

export function escapeHTML(str) {
	return str
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;");
}

export function lineBreakText(text) {
	if (!text || typeof text !== "string") return "";
	// First convert the list separation, then everything else
	const withListBreaks = text.replace(/(\r\n|\r|\n)(?=\d+\.\s)/g, "<br>");
	return withListBreaks.replace(/(?:\r\n|\r|\n)/g, "<br>");
}

export function URLify(text) {
	if (!text || typeof text !== "string") return "";
	const urlRegex = /(((https?:\/\/)|(www\.))[^\s<]+)/g;

	return text.replace(urlRegex, (url) => {
		const cleanUrl = url.replace(/<br>/g, ""); // remove potential breaks within the URL
		const link = cleanUrl.startsWith("www") ? `http://${cleanUrl}` : cleanUrl;
		return `<a target="_blank" href="${link}">${cleanUrl}</a>`;
	});
}

export function cleanFromHTML(val) {
	if (!val) return "";
	const str = val.toString();
	// Only remove valid HTML tags, not standalone < or > characters
	return str.replace(/<\/?[a-zA-Z][^>]*>/g, "").trim();
}

export function getConnectionType() {
	return "connection" in navigator ? navigator.connection.effectiveType : "4g";
}

export function delayDependsOnConnection(worst, bad, good = 0, best = 0) {
	const connectionType = getConnectionType();
	let delayInSeconds = 0;

	if (connectionType === "slow-2g") delayInSeconds = worst;
	else if (connectionType === "2g") delayInSeconds = bad;
	else if (connectionType === "3g") delayInSeconds = good;
	else delayInSeconds = best;

	return delayInSeconds * 1000;
}

export function appendElement(elName, attrs, parentElement = document.body, frame = document) {
	const el = frame.createElement(elName);
	for (const attrName in attrs) {
		const attrVal = attrs[attrName];

		if (typeof attrVal === "boolean") {
			el[attrName] = attrVal;
		} else {
			el.setAttribute(attrName, attrVal);
		}
	}
	parentElement.appendChild(el);
	return el;
}

export function appendScript(attrs, callback) {
	const script = appendElement("script", attrs);
	if (callback) script.onload = callback;
}

export function downloadFileHandler(attrs) {
	const link = appendElement("a", attrs);
	link.click();
	link.remove();
}

export function containsUppercase(value, count) {
	let uppercaseCount = 0;
	for (let i = 0; i < value.length; i++) {
		if (value[i] === value[i].toUpperCase() && value[i] !== value[i].toLowerCase()) {
			uppercaseCount++;
			if (uppercaseCount >= count) {
				return true;
			}
		}
	}
	return false;
}

export function containsLowercase(value, count) {
	let uppercaseCount = 0;
	for (let i = 0; i < value.length; i++) {
		if (value[i] === value[i].toLowerCase() && value[i] !== value[i].toUpperCase()) {
			uppercaseCount++;
			if (uppercaseCount >= count) {
				return true;
			}
		}
	}
	return false;
}

export function containsNumbers(value, count) {
	let numberCount = 0;
	for (let i = 0; i < value.length; i++) {
		if (!isNaN(Number(value[i]))) {
			numberCount++;
			if (numberCount >= count) {
				return true;
			}
		}
	}
	return false;
}

export function mergeArraysFromObject(obj) {
	const mergedArray = [];
	for (const key in obj) {
		if (Array.isArray(obj[key])) {
			mergedArray.push(...obj[key]);
		}
	}
	return mergedArray;
}

export function isToday(someDate) {
	const today = new Date();
	const correctSomeDate = new Date(someDate);

	return (
		correctSomeDate.getFullYear() === today.getFullYear() &&
		correctSomeDate.getMonth() === today.getMonth() &&
		correctSomeDate.getDate() === today.getDate()
	);
}
export function isYesterday(someDate) {
	const today = new Date();
	const correctSomeDate = new Date(someDate);

	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	return (
		correctSomeDate.getFullYear() === yesterday.getFullYear() &&
		correctSomeDate.getMonth() === yesterday.getMonth() &&
		correctSomeDate.getDate() === yesterday.getDate()
	);
}

export function timeAgo(dateString, strict = true) {
	const date = new Date(dateString);
	const now = new Date();
	const diffInMinutes = diffMinutes(date, now);
	const diffInHours = diffHours(date, now);
	const diffInDays = diffDates(date, now);
	const diffInMonths = diffMonthes(date, now);
	const diffInYears = diffYears(date, now);

	if (diffInMinutes < 60) {
		if (strict) {
			return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
		} else {
			return 'Less than an hour ago';
		}
	} else if (diffInHours < 24) {
		return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
	} else if (diffInMonths <= 1) {
		return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
	} else if (diffInMonths < 12) {
		return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
	} else {
		return `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
	}
}

export function copyText(text) {
	function createFakeElement(value) {
		var fakeElement = document.createElement("textarea");

		fakeElement.style.fontSize = "12pt";

		fakeElement.style.border = "0";
		fakeElement.style.padding = "0";
		fakeElement.style.margin = "0";

		fakeElement.style.position = "absolute";
		fakeElement.style.left = "-9999px";

		var yPosition = window.pageYOffset || document.documentElement.scrollTop;
		fakeElement.style.top = "".concat(yPosition, "px");
		fakeElement.setAttribute("readonly", "");
		fakeElement.value = value;
		return fakeElement;
	}
	const fakeElement = createFakeElement(text);
	document.body.appendChild(fakeElement);
	fakeElement.select();
	const res = document.execCommand("copy");
	fakeElement.remove();

	return res
}

export function getRegionName(isoCode, lang='en') {
	return (new Intl.DisplayNames([lang], {type: 'region'})).of(isoCode.toUpperCase());
}

export function getCountriesAsOptionsList(sortAbc) {
	const options = countriesList.map(code => ({
		value: code,
		text: getRegionName(code)
	}));
	const sortedOptions = options.sort((a, b) => a.text.localeCompare(b.text));

	return sortAbc ? sortedOptions : options;
}
