export default {
	methods: {
		// Do next step in guiding tour
		guidingNextStep(tourName = "guidingTour") {
			if (this.$tours[tourName]) {
				this.$tours[tourName].nextStep();
			}
		}
	}
};
