import store from "@/js/store";

const getUserApiVersionValue = () => {
	const CURRENT_USER = store.getters.currentUser;

	return CURRENT_USER ? CURRENT_USER.apiVersion : Number.MAX_SAFE_INTEGER;			// insure that the user exist; means the user is super new, so he can see newest app version without bunch of popups
};

export const doesUserHaveFullActionLimits = () => getUserApiVersionValue() === 1;
export const leadExportFlowIsDefault = () => getUserApiVersionValue() > 2;
export const findEmailIsNewFeatureForUser = () => getUserApiVersionValue() < 3;
export const locationSplittingIsNewFeatureForUser = () => getUserApiVersionValue() < 4;
export const isOldUserForNewUI = () => getUserApiVersionValue() < 6;
