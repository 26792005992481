import store from "@/js/store";
import { sendGoogleAnalytics } from "@/js/services/GoogleAnalyticsService";

export async function verifyEmail(actionCode) {
	try {
		await store.dispatch("verifyEmail", actionCode);
		sendGoogleAnalytics("sign_up_email_verification")
	} finally {
		setTimeout(() => {
			window.location.href = `${location.protocol}//${location.host}${location.pathname}`;
		}, 1000);
	}
}
