import store from "@/js/store";

const getCurrentUser = () => {
	return store.getters.currentUser;
};

const getUserDripifyEmail = () => {
	const CURRENT_USER = getCurrentUser() || store.getters.userInfo;

	return CURRENT_USER ? CURRENT_USER.email : "";
};

const getUserLinkedinEmail = () => {
	const CURRENT_USER = getCurrentUser();

	return CURRENT_USER?.linkedinCredentialsResponse?.userName;
};

export const hasAccessToFeature = (featureName) => {
	const USER_EMAIL = getUserDripifyEmail();

	return FEATURES_ACCESS[featureName]?.includes(USER_EMAIL) || false;
};

export const isBannedToFeature = (featureName) => {
	const USER_DRIPIFY_EMAIL = getUserDripifyEmail();
	const USER_LINKEDIN_EMAIL = getUserLinkedinEmail();

	return [USER_DRIPIFY_EMAIL, USER_LINKEDIN_EMAIL].some(email => {
		return BANNED_ACCESS[featureName]?.includes(email) || false
	});
};

export const hasAccessToFeatureByEmailDomain = (featureName) => {
	const USER_EMAIL = getUserDripifyEmail();
	const EMAIL_DOMAIN = USER_EMAIL.split("@")[1];

	return EMAIL_DOMAINS_FEATURES_ACCESS[featureName]?.includes(EMAIL_DOMAIN);
};

const EMAIL_DOMAINS_FEATURES_ACCESS = {
	"ONLY_LEADS_URLS_TYPE": ["sap.com"],
	"EMAIL_VERIFICATION_IGNORING": ["ukr.net"]
};

const FEATURES_ACCESS = {
	TEMP_UNIQUE_ACCESS: [
		"osuflak22@gmail.com",
		"uaspoken@gmail.com"
	],
	EMAIL_RATE_FEATURE: [
		"kozachok.dima83@outlook.com",
		"rockstaroffrontend@gmail.com",
		"nancyhun@ukr.net",
		"kogut.ira@gmail.com",
		"mburdoev@gmail.com",
		"alexserdiuchenko@gmail.com",
		"alex.kamniev+admin1@dripify.io",
		"illiarudenko52@ukr.net",
		"alex.p@dripify.io",
		"kristinayuriivna2020@gmail.com",
		"avvakumova1989@outlook.com",
		"igorstupka123@gmail.com"
	],
	OUTLOOK_INTEGRATION_FEATURE: [
		"rockstaroffrontend@gmail.com",
		"nancyhun@ukr.net",
		"kogut.ira@gmail.com",
		"mburdoev@gmail.com",
		"alexserdiuchenko@gmail.com",
		"osuflak22@gmail.com",
		"alex.kamniev+admin1@dripify.io",
		"illiarudenko52@ukr.net",
		"alex.p@dripify.io",
		"kristinayuriivna2020@gmail.com",
		"avvakumova1989@outlook.com",
		"kozachok.dima83@outlook.com",
		"igorstupka123@gmail.com"
	],
	DELETING_USER_ADMIN: [
		// https://dripify.slack.com/archives/D02N3J9JH4G/p1732811609793789
		"kallebloomckwist@gmail.com",
		"kogut.ira@gmail.com",
		"illiarudenko52@gmail.com",
		"rockstaroffrontend@gmail.com",
		"nadyadem.88@gmail.com",
		"maryna.fedyk@gmail.com",
		"evkram87@gmail.com",
		"anchizho@gmail.com",
		"yanabril01@gmail.com",
		"anjmirafuentes@gmail.com",
		"tanduyan.johncarlo@dnsc.edu.ph",
		"plukreybolo@gmail.com",
		"i.yurchyna@gmail.com",
		"max.p@dripify.io",
		"amecasidsid@gmail.com",
		"jhane.escamos11@gmail.com",
		"kushnirpetro92@gmail.com",
		"annapershutkina@gmail.com",
		"marie.f@dripify.io",
		"kate.k@dripify.io",
		"joanna.b@dripify.io",
		"sasha@dripify.io",
		"john@dripify.io",
		"rey@dripify.io",
		"elliott.y@dripify.io",
		"nadine@dripify.io",
		"erin.c@dripify.io",
		"hannah@dripify.io",
		"diana.s@dripify.io",
		"irene.k@dripify.io"
	]
};

const BANNED_ACCESS = {
	ADMIN_SUBSCRIPTION_FEATURES: [
		"jamal_sheikh@outlook.com",
		"jamal.sheikh@dripify.io",
		"tolik.clevan@gmail.com",
		"anatolie.clevan@gmail.com"
	]
};

