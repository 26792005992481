import { appendScript } from "@/js/helpers/utils";
import { delayDependsOnConnection } from "@/js/helpers/utils";
import adminAccountController from "@/js/helpers/adminAccountController";

window.metricsLoaded = false;
const loadMetrics = !adminAccountController.devMode() || window.location.host.includes("staging");

export default function thirdPartLibsLazyLoad() {
	if (!window.metricsLoaded && loadMetrics) {
		setTimeout(() => {
			connectPostAffTracker();
			connectFacebookPixel();
		}, delayDependsOnConnection(10, 6, 4, 1));
		window.metricsLoaded = true;
	}

	function connectPostAffTracker() {
		const ATTRS = {
			type: "text/javascript",
			id: "pap_x2s6df8d",
			src: "https://dripify.postaffiliatepro.com/scripts/93jxx2adz2"
		};
		appendScript(ATTRS, () => {
			window.PostAffTracker.setAccountId("default1");
			try {
				window.PostAffTracker.track();
			} catch (err) {
				console.error(err);
			}
			window.PostAffTracker.getAffInfo().call(window.PostAffTracker.getAffInfo().getAffiliateId());
		});
	}

	function connectFacebookPixel() {
		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = "2.0";
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
		window.fbq("init", "281472513306882");
		window.fbq("track", "PageView");
	}
}
