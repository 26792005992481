import firebase from "firebase/app";
import adminAccountController from "@/js/helpers/adminAccountController";
import { hasAccessToFeatureByEmailDomain } from "@/js/helpers/featureAccess";

export default {
	state: {
		userInfo: null
	},
	getters: {
		userInfo: (s) => s.userInfo,
		emailVerified: (s) => {
			if (!s.userInfo) return true;

			const UNVERIFIED_EMAIL_IGNORING = hasAccessToFeatureByEmailDomain("EMAIL_VERIFICATION_IGNORING");

			return s.userInfo.emailVerified || UNVERIFIED_EMAIL_IGNORING;
		}
	},
	mutations: {
		clearUserInfo(state) {
			state.userInfo = null;
		},
		setUser(state) {
			state.userInfo = firebase.auth().currentUser;
		}
	},
	actions: {
		async signUp(store, { email, pass }) {
			try {
				await firebase.auth().createUserWithEmailAndPassword(email, pass);
			} catch (err) {
				if (err.code === "auth/email-already-in-use") {
					store.commit("setRequestErrorMessage", "Oops! Looks like this email address is already in use. Try logging in or resetting your password");
				} else {
					store.commit("setRequestErrorMessage", err);
				}
				throw err;
			}
		},
		async login(store, { email, password }) {
			try {
				await firebase.auth().signInWithEmailAndPassword(email, password);
				store.commit("setUser");
			} catch (err) {
				if (err.code === "auth/user-not-found") {
					store.commit(
						"setRequestErrorMessage",
						"Sorry, that email or password is incorrect. Please try again"
					);
				} else if (err.code === "auth/wrong-password") {
					store.commit(
						"setRequestErrorMessage",
						`Sorry, that password isn't right. Try to double-check your password or recover it by pressing "Forgot password"`
					);
				} else {
					store.commit("setRequestErrorMessage", err);
				}
				throw err;
			}
		},
		async signInWithGoogle(store) {
			try {
				const provider = new firebase.auth.GoogleAuthProvider();
				let res = await firebase.auth().signInWithPopup(provider);
				return res;
			} catch (err) {
				if (showGooglePopupMessage(err.code)) store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async updateProfile(store, payload) {
			try {
				await firebase.auth().currentUser.updateProfile(payload);
			} catch (err) {
				store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async writeUserInfoIntoFirebaseDB(store, payload) {
			try {
				if (!store.getters.userInfo) store.commit("setUser");
				await firebase.database().ref(`/users/${store.getters.userInfo.uid}/info`).set(payload);
			} catch (err) {
				store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async readUserInfoFromFirebaseDB(store) {
			try {
				const { uid } = store.getters.userInfo;
				const res = await firebase.database().ref(`/users/${uid}/info`).once("value");
				return res.val();
			} catch (err) {
				store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async sendEmailVerification(store) {
			try {
				const url = process.env.VUE_APP_PATH;
				await firebase.auth().currentUser.sendEmailVerification({ url });
			} catch (err) {
				store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async verifyEmail(store, actionCode) {
			try {
				await firebase.auth().applyActionCode(actionCode);
				store.commit("setRequestErrorMessage", "Your email address is successfully verified now");
			} catch (err) {
				if (err.code === "auth/invalid-action-code") {
					store.commit("setRequestErrorMessage", "Your email has been already verified");
				} else if (err.code === "auth/expired-action-code") {
					await store.dispatch("sendEmailVerification");
					store.commit(
						"setRequestErrorMessage",
						"The email confirmation link has expired. But, no worries! <br/> We've just resent the new confirmation link to your inbox"
					);
				} else {
					store.commit("setRequestErrorMessage", err);
				}
				throw err;
			}
		},
		async sendPasswordResetEmail(store, email) {
			try {
				await firebase.auth().sendPasswordResetEmail(email);
			} catch (err) {
				if (err.code === "auth/user-not-found") {
					store.commit("setRequestErrorMessage", "This email does not appear in our records. The account may have been deleted or never existed");
				} else {
					store.commit("setRequestErrorMessage", err);
				}
				throw err;
			}
		},
		async resetPassword(store, { actionCode, newPassword }) {
			try {
				await firebase.auth().confirmPasswordReset(actionCode, newPassword);
			} catch (err) {
				store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async updateEmail(_, email) {
			await firebase.auth().currentUser.updateEmail(email);
		},
		async updatePassword(_, newPassword) {
			await firebase.auth().currentUser.updatePassword(newPassword);
		},
		async reauthenticateWithGoogle(store) {
			try {
				const provider = new firebase.auth.GoogleAuthProvider();
				provider.addScope("email");
				provider.addScope("profile");
				await firebase.auth().currentUser.reauthenticateWithPopup(provider);
			} catch (err) {
				if (showGooglePopupMessage(err.code)) store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async reauthenticateWithCredential(store, creds) {
			try {
				const user = firebase.auth().currentUser;
				const credential = firebase.auth.EmailAuthProvider.credential(creds.email, creds.password);
				await user.reauthenticateWithCredential(credential);
			} catch (err) {
				if (err.code === "auth/wrong-password") {
					store.commit(
						"setRequestErrorMessage",
						"The password is invalid, please make sure to enter the current password to your Dripify account"
					);
				} else {
					store.commit("setRequestErrorMessage", err);
				}
				throw err;
			}
		},
		async linkWithGooglePopup(store) {
			try {
				const provider = new firebase.auth.GoogleAuthProvider();
				await firebase.auth().currentUser.linkWithPopup(provider);
			} catch (err) {
				if (showGooglePopupMessage(err.code)) store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async emailRecovery(store, actionCode) {
			try {
				const info = await firebase.auth().checkActionCode(actionCode);
				const restoredEmail = info["data"]["email"];
				await firebase.auth().applyActionCode(actionCode);
				return restoredEmail;
			} catch (err) {
				store.commit("setRequestErrorMessage", err);
				throw err;
			}
		},
		async getTokenResult(_, forceRefresh = false) {
			return await firebase.auth().currentUser.getIdTokenResult(forceRefresh);
		},
		async getIdTokenResult(store) {
			const idTokenResult = await store.dispatch("getTokenResult");
			store.commit("changeAdminStatus", idTokenResult.claims.scope);
		},
		async logout(store) {
			try {
				await firebase.auth().signOut();
				store.commit("clearUserInfo");
				store.commit("clearUserInTeams");
				store.commit("clearCurrentUser");
				store.commit("changeCurrentUserStatus", "");
				store.commit("setSideAccountId", "");
				store.commit("setUnreadMessagesCount", 0);
				store.commit("setReminders", []);
				adminAccountController.deleteTestAcc();
			} catch (err) {
				store.commit("setRequestErrorMessage", err);
				throw err;
			}
		}
	}
};

function showGooglePopupMessage(code) {
	const errorsCodesToSilenceList = ["auth/popup-closed-by-user", "auth/cancelled-popup-request"];
	return !errorsCodesToSilenceList.find((errCode) => errCode === code);
}
