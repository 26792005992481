<template>
	<article
		class="leads-pack"
		:class="{'leads-pack--row': displayRow}"
		aria-label="Pack of leads"
	>
		<div
			class="leads-pack__head"
		>
			<Icon
				:icon="searchTypeIcon(pack.searchType, pack.searchOption)"
				style="grid-area: icon"
			/>

			<component
				:is="nameIsLink ? 'router-link' : 'div'"
				:to="nameIsLink ? leadsURL : null"
				:title="packName"
				class="leads-pack__name body_M"
				style="grid-area: name"
			>
				{{ packName }}
				<Icon
					v-if="nameIsLink"
					icon="small-arrow--right"
				/>
			</component>

			<time
				class="body_XS nowrap"
				style="grid-area: time"
			>{{ $mmm_dd_yyyy(pack.createdAt) }}</time>

			<hr
				class="hr hr--v"
				style="grid-area: hr"
			>

			<button
				aria-haspopup="true"
				class="leads-pack__del"
				style="grid-area: del"
				@click="openRemovingPopup"
			>
				<Icon
					icon="trash"
					stroke="var(--neutral-400)"
				/>
			</button>
		</div>

		<div
			v-if="!campaignCreating && !isDraft"
			class="leads-pack__grid"
		>
			<div class="leads-pack__block">
				<div class="leads-pack__block-title caption_S">
					<span class="between">
						All leads

						<CampaignStatisticsDisplaying
							:campaign="campaign"
							:pack="pack"
							:statistics-loading="statisticsLoading"
							field="totalLeadsCount"
							:clickable="!!nameIsLink"
						/>
					</span>
				</div>
				<br>
				<LeadsIndicators
					:statistics="pack.statistics"
					:pack="pack"
					:statistics-loading="statisticsLoading"
					:campaign="campaign"
					for-leads-packs
				/>
			</div>


			<div class="leads-pack__block">
				<div
					class="leads-pack__status"
				>
					<CircleProgress
						v-tooltip="searchPausedTooltip"
						:current-value="pack.leadsNumberCollected"
						:max-value="pack.leadsNumberToCollect"
						:disabled="!!searchPausedTooltip"
						digits-indicate
					/>
					<div>
						<span
							v-tooltip="searchPausedTooltip"
							class="body_XS leads-pack__status-text"
							:class="{ ph: searchPausedTooltip }"
						>
							{{ searchComplete ? "Retrieved" : "Retrieving" }} from
							<SearchSource
								:pack="pack"
								:download-file-function="downloadFile"
								:disabled-text="!!searchPausedTooltip"
							/>
						</span>

						<button
							class="tertiary-btn"
							aria-haspopup="true"
							@click="showReport"
						>
							View report
						</button>
					</div>
				</div>
			</div>

			<div class="leads-pack__block">
				<div
					v-if="isEmailReplyFeatureVisible"
					class="leads-pack__block-title caption_S"
				>
					Linkedin
				</div>
				<CampaignStatisticsDisplaying
					:campaign="campaign"
					:pack="pack"
					:statistics-loading="statisticsLoading"
					field="acceptanceRate"
					count-field="acceptanceCount"
					filter="INVITE_ACCEPTED"
				>
					Acceptance rate
				</CampaignStatisticsDisplaying>

				<CampaignStatisticsDisplaying
					v-if="isEmailReplyFeatureVisible"
					:campaign="campaign"
					:pack="pack"
					:statistics-loading="statisticsLoading"
					field="replyRate"
					count-field="replyCount"
					filter="MESSAGE_RESPONDED"
				>
					Response rate
				</CampaignStatisticsDisplaying>
			</div>

			<div class="leads-pack__block">
				<CampaignStatisticsDisplaying
					v-if="!isEmailReplyFeatureVisible"
					:campaign="campaign"
					:pack="pack"
					:statistics-loading="statisticsLoading"
					field="replyRate"
					count-field="replyCount"
					filter="MESSAGE_RESPONDED"
				>
					Response rate
				</CampaignStatisticsDisplaying>

				<template v-else>
					<div class="leads-pack__block-title caption_S">Email</div>
					<CampaignStatisticsDisplaying
						:campaign="campaign"
						:pack="pack"
						:statistics-loading="statisticsLoading"
						field="emailReplyRate"
						count-field="emailReplyCount"
						:clickable="false"
					>
						Reply rate
					</CampaignStatisticsDisplaying>

					<CampaignStatisticsDisplaying
						:campaign="campaign"
						:pack="pack"
						:statistics-loading="statisticsLoading"
						field="emailBounceRate"
						count-field="emailBounceCount"
						:clickable="false"
					>
						Bounce rate
					</CampaignStatisticsDisplaying>

					<CampaignStatisticsDisplaying
						:campaign="campaign"
						:pack="pack"
						:statistics-loading="statisticsLoading"
						field="emailOpenRate"
						count-field="emailOpenCount"
						:clickable="false"
					>
						Open rate
					</CampaignStatisticsDisplaying>
				</template>
			</div>
		</div>
		<div
			v-else
			class="leads-pack__grid"
		>
			<div class="leads-pack__block leads-pack__block--auto-h">
				<div class="body_S">Added lead{{ pack.leadsNumberToCollect === 1 ? "" : "s" }}</div>
				{{ pack.leadsNumberToCollect }}
			</div>

			<div class="leads-pack__block leads-pack__block--auto-h">
				<div class="body_S">Added from</div>
				<SearchSource
					:pack="pack"
					:download-file-function="downloadFile"
				/>
			</div>
		</div>

		<transition name="fade">
			<LeadsReport
				v-if="reportPopup"
				:config="pack"
				:leads-url="leadsURL"
				@close="hideReport"
			/>
		</transition>

		<transition name="fade">
			<RemovingPopup
				v-if="removingPopup"
				button-text="Remove list"
				:remove-function="removeLeadPack"
				@close="closeRemovingPopup"
			>
				Do you really want to remove list of leads <b>{{ packName }}</b>?
			</RemovingPopup>
		</transition>
	</article>
</template>

<script>
import defaultRemoving from "@/js/mixins/defaultRemoving.mixin";
import CampaignStatisticsDisplaying from "@/js/components/Campaign/CampaignStatisticsDisplaying";
import { hasAccessToFeature } from "@/js/helpers/featureAccess";
import searchLeadsType from "@/js/mixins/searchLeadsType.mixin";
import { downloadFileHandler } from "@/js/helpers/utils";

export default {
	name: "LeadsPack",
	components: {
		LeadsIndicators: () =>
			window.compLoader(import("@/js/components/Campaign/LeadsIndicators/LeadsIndicators")),
		LeadsReport: () => window.compLoader(import("@/js/components/Campaign/Audience/LeadsReport")),
		SearchSource: () => window.compLoader(import("@/js/components/Campaign/Audience/SearchSource")),
		CircleProgress: () =>
			window.compLoader(import("@/js/components/CircleProgress")),
		CampaignStatisticsDisplaying
	},
	mixins: [defaultRemoving, searchLeadsType],

	props: {
		pack: { type: Object, required: true },
		campaignCreating: { type: Boolean, default: false },
		displayRow: { type: Boolean, default: false },
		isDraft: { type: Boolean, default: false },
		statisticsLoading: { type: Boolean, default: false },
		campaign: { type: Object, required: false }
	},
	data: () => ({
		reportPopup: false,
		isEmailReplyFeatureVisible: false
	}),
	computed: {
		campaignIsActive() {
			return this.campaign.status === "ACTIVE";
		},
		searchComplete() {
			return this.pack.searchStatus === "SEARCH_COMPLETE";
		},
		sleepModeEnabled() {
			return this.$store.getters.sleepModeEnabled;
		},
		searchPaused() {
			return this.pack.searchStatus === "SEARCH_PAUSED";
		},
		searchPausedTooltip() {
			if (!this.searchComplete) {
				if (this.searchPaused) return this.pauseErrorText;
				if (!this.campaignIsActive)
					return "Since your campaign is paused, Dripify stopped adding leads from LinkedIn to this list. Enable your campaign to resume the process";
				if (this.sleepModeEnabled)
					return "Since your campaigns are out of working hours at the moment, Dripify stopped adding leads from LinkedIn. Adjust your schedule to resume the process";
				else return null;
			} else return null;
		},
		leadsURL() {
			return `/campaigns/${this.campaign.id}/leads/?leadsList=${this.pack.id}`;
		},
		totalLeadsCount() {
			return this.pack.statistics ? this.pack.statistics.totalLeadsCount : 0;
		},
		nameIsLink() {
			return this.totalLeadsCount && !this.displayRow
		},
		packName() {
			return this.searchLeadsPackName(this.pack);
		},
		pauseErrorText() {
			switch (this.pack.error) {
			case "SEARCH_WRONG_CONTRACT":
				return "The lead transfer to the list is stopped due to the LinkedIn contract mismatch";
			case "SEARCH_GENERIC_ERROR":
				return "The lead transfer to the list is stopped since the LinkedIn search URL is invalid";
			case "SEARCH_SESSION_IS_TAKEN":
				return "The lead transfer to the list is stopped since you have another live session on LinkedIn";
			case "SEARCH_MULTIPLE_RECRUITER_SESSIONS":
				return "The lead transfer to the list has been stopped due to multiple sign-ins in Recruiter";
			case "SEARCH_2FA_IS_NOT_ENABLED":
				return "The lead transfer to the list is stopped since a LinkedIn contract has been disconnected";
			default:
				return "There has been an error while trying to retrieve profiles from LinkedIn, therefore Dripify stopped adding leads to this list";
			}
		}
	},
	created() {
		this.isEmailReplyFeatureVisible = hasAccessToFeature("EMAIL_RATE_FEATURE");
	},
	methods: {
		async downloadFile() {
			const res = await this.$store.dispatch("getLeadsIdsFile", {
				campaignId: this.campaign.id,
				searchLeadConfigItemId: this.pack.id
			});
			const assetName = this.pack?.name ? this.pack.name : "Leads"

			downloadFileHandler({
				href: window.URL.createObjectURL(new Blob([res.data])),
				download: `${assetName}.csv`
			});
		},
		async removeLeadPack() {
			await this.$store.dispatch("deleteSearchLeadsConfigItem", this.pack.id);
			this.$emit("removed");
			this.closeRemovingPopup();
		},
		showReport() {
			this.reportPopup = true;
		},
		hideReport() {
			this.reportPopup = false;
		}
	}
};
</script>

<style lang="sass">
.leads-pack
	$this: &
	--pack-offset-x: 18px
	--pack-offset-y: 18px
	border-radius: var(--bdrs)
	border: var(--bd)
	padding: var(--pack-offset-y) var(--pack-offset-x)
	@media screen and (max-width: $tablet)
		padding: 15px
	&--
		&row
			padding: 0
			border: none
			#{$this}__grid
				grid-template-columns: 1fr 1fr 1fr 1fr
				gap: 6px
				margin: 0
				&::before
					display: none
				@media screen and (max-width: $tablet)
					grid-template-columns: 1fr 1fr
				@media screen and (max-width: $mobile)
					grid-template-columns: 100%
			#{$this}__block
				border: var(--bd)
				border-radius: var(--bdrs)
				min-height: unset
				&::before
					display: none
			.camp-stat-display
				color: var(--neutral-900)

	&__
		&head
			display: grid
			grid-template-columns: var(--icon-size) auto min-content 1px var(--icon-size)
			grid-template-areas: "icon name time hr del"
			gap: 14px
			align-items: center
			margin-bottom: 20px
			@media screen and (max-width: $mobile)
				gap: 7px
				grid-template-columns: var(--icon-size) auto 1px var(--icon-size)
				grid-template-areas: "icon name  hr del" "time time time time"

		&name
			color: var(--neutral-900)
			display: inline-block
			overflow: hidden
			text-overflow: ellipsis
			white-space: nowrap
			max-width: 100%
			svg
				vertical-align: top
			&[href]
				&:hover,
				&:focus
					color: var(--main-primary)
					[stroke]
						stroke: var(--main-primary)
		&del
			height: var(--icon-size)
			border-radius: var(--bdrs)
			&:focus-visible,
			&:hover
				[stroke]
					stroke: var(--main-red)
		&grid
			margin-left: calc(var(--pack-offset-x) * -1)
			margin-right: calc(var(--pack-offset-x) * -1)
			display: grid
			grid-template-columns: 1fr 1fr
			margin-bottom: calc(var(--pack-offset-y) * -1)
			position: relative
			&::before
				content: ""
				position: absolute
				width: 1px
				height: calc(100% - var(--pack-offset-y) * 2)
				left: 50%
				top: var(--pack-offset-y)
				background-color: var(--neutral-200)
			@media screen and (max-width: $mobile)
				grid-template-columns: 100%
				&::before
					display: none


		&block
			padding: 18px var(--pack-offset-x)
			// min-height: 120px
			display: flex
			flex-direction: column
			gap: 3px
			position: relative
			&::before
				content: ""
				position: absolute
				width: calc(200% - var(--pack-offset-y) * 2)
				height: 1px
				left: var(--pack-offset-x)
				bottom: 0
			&:nth-child(2n - 1):not(:nth-last-child(2))
				&::before
					background-color: var(--neutral-200)
			@media screen and (max-width: $mobile)
				min-height: unset
				&::before
					background-color: var(--neutral-200)
					width: calc(100% - var(--pack-offset-y) * 2)
					bottom: auto
					top: 0
			&--
				&auto-h
					min-height: unset
		&block-title
			display: block
			margin-bottom: 6px
		&status
			display: grid
			gap: 18px
			grid-template-columns: 60px auto
		&status-text
			line-height: 18px !important
			margin-bottom: 14px
			display: block

</style>
