
export default {
	components: {
		SettingsLeavingPopup: () => window.compLoader(import("@/js/components/Settings/SettingsLeavingPopup"))
	},
	beforeRouteLeave(to, _, next) {
		const hasActiveTours = !!Object.keys(this.$tours).length;

		if (this.dataLoaded && this.dataChanged && !this.leavingPopup && !hasActiveTours) {
			this.openLeavingPopup();
			this.leavingPath = to.path;
			next(false);
			return;
		}
		next();
	},
	data: () => ({
		leavingPath: null,
		leavingPopup: false,
		dataLoaded: false,
	}),
	methods: {
		openLeavingPopup() {
			this.leavingPopup = true;
		},
		closeLeavingPopup() {
			this.leavingPopup = false;
		}
	}
};
