import { hasAccessToFeature } from "@/js/helpers/featureAccess";

export default {
	actions: {
		async getProviders(store) {
			try {
				const res = await window.request("GET", "/v1/auth/providers");

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async getAuthorizationUrl(store, payload) {
			try {
				if (hasAccessToFeature("EMAIL_RATE_FEATURE")) {
					payload.authorizationScopeType = "SEND_EMAIL_WITH_STATISTIC"
				}
				const res = await window.request("GET", "/v1/auth", payload);
				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async oAuthCallback(store, payload) {
			try {
				await window.request("GET", "/v1/auth/exchange", payload);
			} catch (err) {
				const PERMISSION_ERROR_TEXT = "token does not have the necessary permissions";

				if (err.response.data.status === 403 && err.response.data.message.includes(PERMISSION_ERROR_TEXT)) {
					store.commit("setRequestErrorMessage", "Your Gmail account has not been connected. Please grant Dripify permission to send emails on your behalf during the integration setup");
				} else {
					store.commit("setRequestErrorMessage", err.response.data);
				}
				throw err;
			}
		},
		async removeEmailProvider(store) {
			try {
				const { emailProvider } = store.getters.currentUser.oauthInfo;
				await window.request("DELETE", `/v1/auth?emailProvider=${emailProvider}`);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},

	}
};
