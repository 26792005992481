<template>
	<div
		v-if="statisticsLoading"
		class="leads-indicators"
	>
		<div class="leads-indicator-placeholder blinking-item" />
		<div class="leads-indicator-placeholder blinking-item" />
		<div class="leads-indicator-placeholder blinking-item" />
		<div class="leads-indicator-placeholder blinking-item" />
		<div class="leads-indicator-placeholder blinking-item" />
	</div>

	<div
		v-else-if="noLeads"
		class="no-leads-indicator"
	>
		<span class="caption_S">0</span>
	</div>

	<div
		v-else-if="statistics"
		class="leads-indicators"
		:style="indicatorsGrid"
		role="progressbar"
		aria-label="Lead's progress"
	>
		<LeadsStatusIndicator
			v-for="(indicator, idx) in indicatorsList"
			v-show="indicator.value"
			:key="idx"
			:indicator="indicator"
			:campaign="campaign"
			:for-leads-packs="forLeadsPacks"
			:pack="pack"
			:disabled="disabled"
		/>
	</div>
</template>

<script>
export default {
	name: "LeadsIndicators",
	components: {
		LeadsStatusIndicator: () =>
			window.compLoader(import("@/js/components/Campaign/LeadsIndicators/LeadsStatusIndicator"))
	},
	props: {
		statistics: { type: Object, required: false },
		statisticsLoading: { type: Boolean, default: false },
		campaign: { type: Object, required: true },
		pack: { type: Object, required: false },
		forLeadsPacks: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false }
	},
	computed: {
		indicatorsList() {
			return this.forLeadsPacks ? this.leadsPacksIndicators : this.campaignIndicators;
		},
		noLeads() {
			return this.statistics && this.indicatorsList.every(indicator => !indicator.value)
		},
		campaignIndicators() {
			return [
				{
					id: "ended",
					value:
						this.statistics.finishedLeads +
						this.statistics.failedLeads +
						this.statistics.blacklistedLeads
				},
				{
					id: "holded",
					value: this.statistics.pausedLeads + this.statistics.waitingLeads
				},
				{
					id: "progress",
					value: this.statistics.inProgressLeads
				},
				{
					id: "remaining",
					value: this.statistics.gettingReadyLeads
				},
				{
					id: "uploading",
					value: this.statistics.uploadingLeads
				}
			];
		},
		leadsPacksIndicators() {
			return [
				{
					id: "ended",
					value:
						this.statistics.finishedLeadsCount +
						this.statistics.failedLeadsCount +
						this.statistics.blackListedCount
				},
				{
					id: "holded",
					value: this.statistics.pausedCount + this.statistics.waitingUntilCount
				},
				{
					id: "progress",
					value: this.statistics.inProgressLeadsCount
				},
				{
					id: "remaining",
					value: this.statistics.gettingReadyLeads
				},
				{
					id: "uploading",
					value: this.statistics.uploadingCount
				}
			];
		},
		indicatorsGrid() {
			let gridVal = "";
			this.indicatorsList.forEach((item) => {
				if (item.value) gridVal += `${item.value}fr `;
			});
			return `grid-template-columns: ${gridVal}`;
		}
	}
};
</script>

<style lang="sass">
.leads-indicators
	display: grid
	grid-template-columns: repeat(5, 1fr)
	gap: 4px

.no-leads-indicator
	&::before
		content: ""
		display: block
		background-color: var(--neutral-300)
		height: 4px
		margin-bottom: 4px
		border-radius: 10px

.leads-indicator-placeholder
	height: 4px
	border-radius: 10px
	background-color: var(--neutral-100)

</style>
