export default {
	methods: {
		async checkUsersCampaigns() {
			const res = await this.$store.dispatch("getCampaigns", {
				page: 0,
				size: 2,
				empty: true,
				sort: ["locked", "status", "createdAt,asc"]
			});
			this.$store.commit("setCampaignsInfo", res);
		}
	}
};
