<template>
	<div
		v-tooltip="tooltip"
	>
		<Trigger
			v-model="isActive"
			:disabled="disabled"
			:check-name="`isActive${campaign.id}`"
			class="trigger--M"
			@change="changeCampaignStatus"
		/>
	</div>
</template>

<script>
import Trigger from "@/js/components/Form/Trigger";
import unavailableFunctionalityTooltip from "@/js/mixins/unavailableFunctionalityTooltip.mixin";

export default {
	name: "CampaignStatus",
	components: { Trigger },
	mixins: [unavailableFunctionalityTooltip],
	props: {
		campaign: { type: Object, required: true },
		disabled: { type: Boolean, default: false },
	},
	data: () => ({
		isActive: false
	}),
	computed: {
		status() {
			return this.campaign.status;
		},
		tooltip() {
			return this.campaign.locked
				? 'Upgrade to a higher plan to unlock this campaign'
				: this.textForCampaignCreation

		}
	},
	watch: {
		status(newVal) {
			this.isActive = newVal === "ACTIVE";
		}
	},
	mounted() {
		this.isActive = this.status === "ACTIVE" && !this.campaign.locked;
	},
	methods: {
		async changeCampaignStatus(val) {
			if (!this.disabled) {
				try {
					const res = await this.$store.dispatch("changeCampaignStatus", {
						id: this.campaign.id,
						status: val ? "ACTIVE" : "NOT_ACTIVE"
					});
					this.campaign.status = res.status;

					this.$toast({
						position: this.$route.params.campaignId ? "bottom" : "top",
						icon: val ? "play" : "pause",
						html: val
							? `Your campaign has been successfully activated`
							: `Your campaign is paused now`
					});
				} catch {
					this.isActive = !this.isActive
				}
			}
		},
	}
};
</script>
