<template>
	<component
		:is="isLink ? 'div' : 'router-link'"
		:to="isLink ? null : stat.url"
		class="campaign-value"
	>
		<div
			v-tooltip="tooltip"
			class="campaign-value__caption caption_S"
		>
			<Icon :icon="stat.icon" />
			{{ stat.text }}
		</div>

		<div
			class="campaign-value__digits h4"
			:class="{ 'campaign-value__digits--blink': statisticsLoading }"
		>
			{{ stat.value || 0 }}

			<transition name="to-top">
				<Icon
					v-if="showSearchArrows"
					v-tooltip="'There are leads that are currently being uploaded to this campaign'"
					class="animate"
					icon="uploading-arrows"
				/>
			</transition>
		</div>
	</component>
</template>

<script>
export default {
	name: "CampaignValue",
	props: {
		stat: { type: Object, required: true },
		tooltip: { type: String, required: true },
		statisticsLoading: { type: Boolean, default: false },
		campaign: { type: Object, required: true }
	},
	computed: {
		isLink() {
			return this.statisticsLoading || !this.stat.value;
		},
		showSearchArrows() {
			return (
				this.stat.icon === "all-leads" &&
				!this.statisticsLoading &&
				this.campaign.statistics.searchActive &&
				this.campaign.status === "ACTIVE" &&
				!this.campaign.locked &&
				!this.$store.getters.sleepModeEnabled
			);
		}
	}
};
</script>

<style lang="sass">
.campaign-value
	$this: &
	padding: 13px 14px
	border-radius: var(--bdrs)
	border: var(--bd)
	&[href]
		#{$this}__digits
			color: var(--main-primary)
	@media screen and (max-width: $desktop)
		font-size: 17px
	&__
		&digits
			display: flex
			align-items: center
			gap: 10px
			&--blink
				animation: blinking 1s infinite
		&caption
			display: flex
			align-items: center
			gap: 12px
			margin-bottom: 12px
			color: var(--neutral-500)
			[stroke]
				stroke: var(--neutral-500)
</style>
