import { formattingParams } from "@/js/helpers/paramsFormatter";

export default {
	state: {
		sideAccountId: null,
		adminStatus: false
	},
	getters: {
		sideAccountId: (s) => s.sideAccountId,
		adminStatus: (s) => s.adminStatus
	},
	mutations: {
		setSideAccountId(state, id) {
			state.sideAccountId = id;
		},
		changeAdminStatus(state, val) {
			state.adminStatus = val;
		}
	},
	actions: {
		async searchForUser(store, value) {
			try {
				const res = await window.request("GET", `/v1/shared/admin/users/search/${value}`);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async changeTrialDate(store, { userDetailsId, datetime }) {
			try {
				await window.request("PATCH", `/v1/shared/billings/admin/${userDetailsId}`, {
					trialEndsAt: datetime
				});
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async getBillingFor(store, userDetailsId) {
			try {
				const config = { headers: { "X-SIGNED-USER-ID": userDetailsId } };
				const res = await window.request("GET", "/v1/shared/billings/current", null, config);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async transferSubscription(store, params) {
			try {
				const url = `/v1/shared/billings/subscriptions/transfer/?${formattingParams(params)}`;
				await window.request("PUT", url);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async removeSubscription(store, { subscriptionId, params }) {
			try {
				const url = `/v1/shared/billings/subscriptions/${subscriptionId}/deactivate/?${formattingParams(
					params
				)}`;
				await window.request("DELETE", url);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async upsertSubscription(store, payload) {
			try {
				const res = await window.request("PUT", "/v1/shared/billings/subscriptions/test", payload);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async getProxy(store, params) {
			try {
				const res = await window.request("GET", "/v1/shared/proxiestoassign", params);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async createProxy(store, countryCode) {
			try {
				const res = await window.request(
					"POST",
					`/v1/shared/proxiestoassign?countryCode=${countryCode}`
				);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
		async deleteUser(_, payload) {
			await window.request("DELETE", "/campaigns", {
				headers: { "X-User-Id": payload.id },
				data: payload
			});
		},
		async getActivityLog(_, payload) {
			const res = await window.request("GET", "/v1/shared/admin-activity-log", payload);

			return res.data
		},
		async updateUserEmail(_, payload) {
			const config = { headers: { "X-User-Id": payload.id } };

			return await window.request("PATCH", "/userdetails/email", payload, config);
		},
		async resumeInvites(_, payload) {
			await window.request("PATCH", `v1/sequencerestrictions/${payload.id}`, {
				userId: payload.userId,
				sequenceItemType: 'CONNECT',
				restrictedTill: (new Date()).getTime()
			});
		}
	}
};
