<template>
	<Icon
		class="inline-spinner"
		:class="{ 'inline-spinner--center': center }"
		:w="size"
		:h="size"
		icon="loading"
	/>
</template>

<script>
export default {
	name: "InlineSpinner",
	props: {
		size: { type: [Number, String], default: 15 },
		center: { type: Boolean, default: false }
	}
};
</script>

<style lang="sass">
.inline-spinner
	display: inline-block
	animation: spin 1s infinite linear
	cursor: progress
	&--center
		display: block
		margin: 0 auto
</style>
