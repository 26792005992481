<template>
	<button
		v-if="showPseudoButton"
		role="button"
		aria-label="Open Intercom Messenger"
		class="pseudo-intercom"
		@click="onClick"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 28 32"
		>
			<path
				d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"
				fill="#fff"
			/>
		</svg>
	</button>
</template>

<script>
import { delayDependsOnConnection } from "@/js/helpers/utils";
import {
	registerSupportChat,
	openSupportChat,
	SUPPORT_CHAT_BUTTON_SELECTOR
} from "@/js/helpers/api/supportChat";

function detectNodeAvailability(selector, callback) {
	const observer = new MutationObserver(() => {
		if (document.querySelector(selector)) {
			callback();
			observer.disconnect();
		}
	});
	observer.observe(document.documentElement, { childList: true, subtree: true });
}

export default {
	name: "PseudoSupportChat",
	data: () => ({
		chatLoadingTimeout: null,
		showPseudoButton: true,
		isClicked: false
	}),
	mounted() {
		this.chatLoadingTimeout = setTimeout(
			this.registerCanonicalIntercom,
			delayDependsOnConnection(40, 30, 20, 10)
		);
	},
	methods: {
		registerCanonicalIntercom(show) {
			clearTimeout(this.chatLoadingTimeout);
			registerSupportChat(() => {
				detectNodeAvailability(SUPPORT_CHAT_BUTTON_SELECTOR, () => {
					this.showPseudoButton = false;
					if (show) openSupportChat();
				});
			});
		},
		onClick() {
			if (!this.isClicked) {
				this.isClicked = true;
				this.registerCanonicalIntercom(true);
			}
		}
	}
};
</script>

<style lang="sass">
.pseudo-intercom
	--chat-theme: var(--main-primary)
	position: fixed
	z-index: 2147483
	bottom: 20px
	right: 20px
	height: 48px
	width: 48px
	background: var(--chat-theme)
	box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px
	border-radius: 50%
	display: flex
	align-items: center
	justify-content: center
	transition: transform 167ms cubic-bezier(0.33, 0.00, 0.00, 1.00)
	&:hover
		transform: scale(1.1)
	svg
		height: 24px
		width: 24px
</style>
