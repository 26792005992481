<template>
	<div class="chips">
		<div class="chips__field">
			<label
				:for="name"
				class="chips__undercover"
			/>

			<Chip
				v-for="(chip, idx) in chipsArr"
				:key="chip.text + idx"
				:chip="chip"
				@updateChip="(val) => updateChip(idx, val)"
				@removeChip="removeChipFromArr(idx)"
			/>

			<input
				:id="name"
				v-model.trim="newChipText"
				class="chips__input"
				placeholder="Click and type here"
				type="text"
				@keydown.enter="addChip"
				@focus="onFocus"
				@blur="addChip"
			>
		</div>
	</div>
</template>

<script>
import Chip from "@/js/components/Form/Chip";
export default {
	name: "ChipsField",
	components: {
		Chip
	},
	model: {
		prop: "chipsArr",
		event: "input"
	},
	props: {
		chipsArr: { type: Array, required: true },
		name: { type: String, default: "" },
		regexpValidation: { type: Function, required: false },
		symbolsLimit: { type: [Number, null], default: null }
	},
	data: () => ({
		newChipText: ""
	}),
	computed: {
		totalSymbolsAmount() {
			const enabledChips = this.chipsArr.filter(chip => !chip.disabled);
			return enabledChips.map(chip => chip.text).join(",").length;
		}
	},
	methods: {
		addChip() {
			this.validateChip(this.newChipText, () => {
				const symbolsSumWithComma = this.totalSymbolsAmount + this.newChipText.length + 1;
				if (this.symbolsLimit !== null && symbolsSumWithComma > this.symbolsLimit) {
					this.limitToastMessage();
				} else if (!this.chipAlreadyAdded(this.newChipText)) {
					this.chipsArr.push({
						text: this.newChipText
					});
				} else {
					this.wordDuplicateToastMessage();
				}
			})
			this.newChipText = "";
		},
		updateChip(idx, val) {
			this.validateChip(val, () => {
				const symbolsSum = this.totalSymbolsAmount - this.chipsArr[idx].text.length + val.length;
				if (this.symbolsLimit !== null && symbolsSum > this.symbolsLimit) {
					this.limitToastMessage();
				} else if (!this.chipAlreadyAdded(val)) {
					this.chipsArr[idx].text = val;
				} else if (this.chipsArr[idx].text !== val) {
					this.wordDuplicateToastMessage();
				}
			}, () => {
				this.removeChipFromArr(idx);
			});
		},
		validateChip(val, callback, onError) {
			if (val) {
				if (!this.regexpValidation || this.regexpValidation(val)) {
					callback();
				}
			} else if (onError) {
				onError();
			}
		},
		chipAlreadyAdded(val) {
			return this.chipsArr.find(chip => chip.text.toLowerCase() === val.toLowerCase());
		},
		removeChipFromArr(idx) {
			this.chipsArr.splice(idx, 1);
		},
		limitToastMessage() {
			this.$toast(`The limit of ${this.symbolsLimit} characters has been reached`, "danger");
		},
		wordDuplicateToastMessage() {
			this.$toast("This word is already added", "danger");
		},
		onFocus() {
			if (this.totalSymbolsAmount >= this.symbolsLimit) {
				document.activeElement.blur()
				this.limitToastMessage();
			}
		}
	}
};
</script>

<style lang="sass">
.chips
	$this: &
	&__
		&label
			margin-bottom: 8px
			display: inline-block
			font-size: 15px
			&[for]
				cursor: pointer
		&req
			color: var(--main-accent)
		&field
			position: relative
			overflow: hidden
			display: flex
			border-radius: var(--bdrs)
			border: var(--bd)
			padding: 6px
			display: flex
			align-items: center
			gap: 8px 4px
			flex-wrap: wrap
			&:hover
				border-color: var(--neutral-300)
			&:focus-within
				border-color: var(--main-primary)
		&undercover
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			cursor: text !important
			z-index: 0
			color: var(--neutral-400)
			padding: inherit
		&input
			position: relative
			z-index: 1
			padding: 6px 0
</style>
